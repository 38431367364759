import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled, { css } from "styled-components"

export default function MenuItemGroup({children, title, icon}: { children: React.ReactNode, title: string, icon: React.ReactNode }) {
    const [open, setOpen] = useState(false);

    return <MenuItemGroupWrapper>
        <ButtonWrapper onClick={() => setOpen(!open)}>
            <IconWrapper>{icon}</IconWrapper>
            <Label open={open}>{title}
                <FontAwesomeIcon icon={faAngleDown} />
            </Label>
        </ButtonWrapper>

        {open && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </MenuItemGroupWrapper>
}

const MenuItemGroupWrapper = styled.div`
    background: #006b98;
    color: #EDEDED;
    border-bottom: solid 1px #0087c0;
    overflow: hidden;
    cursor: pointer;
`;

const ChildrenWrapper = styled.div`
    
`

const ButtonWrapper = styled.div`
    background: #0079AD;
    display: flex;
    position: relative;
    width: 100%;
    padding: 24px;
    gap: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover{
		text-decoration: none;
		color: var(--nte-graa-lys);
		background-color: #036b98;
	}

	&.active,
	&:focus {
		text-decoration: none;
		color: var(--nte-graa-lys);
		background-color: #00557a;
	}

    svg{
        color: #EDEDED;
    }
`

const IconWrapper = styled.span`
	color: var(--nte-blaa);
	font-size: 1rem;
	line-height: 1;
	align-self: center;
`;

const Label: any = styled.span`
	font-size: 1.1rem;
	line-height: 1.15;
	font-weight: 400;
    display: block;
    svg{
        cursor: pointer;
        position: absolute;
        right: 24px;
        ${(p: any) => p.open && css`
            transform: rotate(180deg);
        `}
    }
`;
