import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DangerButton } from "components/atoms/Button";
import { Direction, Flex } from "components/atoms/Flex";
import ServiceMessageHeader from "components/atoms/ServiceMessageHeader";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import MultiSelectField from "components/organisms/form/MultiSelectField";
import TextField from "components/organisms/form/TextField";
import TextareaField from "components/organisms/form/TextareaField";
import ToggleField from "components/organisms/form/ToggleField";
import { ContentSegmentsEditor } from "pages/Content/CreateContentPage";

import { ServiceMessageLocation } from "generated/graphql-types";

import { ServiceMessageSchema } from "./CreateServiceMessagePage";
import { useUpsertServiceMessageMutation } from "./CreateServiceMessagePage.graphql-gen";
import { SingleServiceMessageQuery, useDeleteServiceMessageMutation, useSingleServiceMessageQuery } from "./EditServiceMessagePage.graphql-gen";

export default function EditServiceMessagePage() {
	const { serviceMessageId } = useParams<{ serviceMessageId: string }>();
	const navigate = useNavigate();
	const [upsertServiceMessage, { loading, error }] = useUpsertServiceMessageMutation();
	const { data } = useSingleServiceMessageQuery({
		variables: {
			serviceMessageId: serviceMessageId || "",
		},
		onCompleted: (data) => {
			setPreviewState(data.singleServiceMessage);
		},
	});
	const [deleteServiceMessage, {}] = useDeleteServiceMessageMutation();
	const [previewState, setPreviewState] = useState<Partial<SingleServiceMessageQuery["singleServiceMessage"]>>({});

	return (
		<div>
			<MainLayoutPageHeader title="Rediger driftsmelding" />
			<Flex gap="16px">
				<Form
					key={data?.singleServiceMessage?.id}
					loading={loading}
					value={data?.singleServiceMessage}
					onSubmit={async (data) => {
						const { errors } = await upsertServiceMessage({
							variables: {
								serviceMessage: {
									id: serviceMessageId,
									...data,
								},
							},
						});

						!errors && navigate(`/service-messages`);
					}}
					schema={ServiceMessageSchema}
				>
					<Flex direction={Direction.column} gap="16px">
						<TextField
							name="title"
							label="Tittel"
							onChange={(e) => {
								const target = e.target as any;
								setPreviewState((oldState) => {
									return {
										...oldState,
										title: target.value || "",
									};
								});
							}}
						/>
						<TextareaField
							name="content"
							label="Innhold"
							onChange={(e) => {
								const target = e.target as any;
								setPreviewState((oldState) => {
									return {
										...oldState,
										content: target.value || "",
									};
								});
							}}
						/>
						<ToggleField name="published" label="Publisert?" />
						<TextField type="datetime-local" name="publishDate" label="Publiseringsdato" />

						<MultiSelectField
							style={{ height: "150px" }}
							name="location"
							label="Plassering i appen"
							options={Object.keys(ServiceMessageLocation).map((l) => {
								return { label: l, value: l };
							})}
						/>

						<ContentSegmentsEditor />

						{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
						<Button>Oppdater driftsmelding</Button>

						<DangerButton
							type="button"
							onClick={async () => {
								if (!window.confirm(`Sikker på at du ønsker å slette driftsmeldingen?`)) {
									return;
								}

								const { errors } = await deleteServiceMessage({
									variables: { serviceMessageId: serviceMessageId || "" },
								});

								if (errors) {
									return console.error(errors);
								}

								navigate("/service-messages");
							}}
						>
							Slett innhold
						</DangerButton>
					</Flex>
				</Form>
				<Flex direction={Direction.column} gap="16px" style={{ width: "390px" }}>
					<ServiceMessageHeader serviceMessage={previewState} />
					<div
						style={{
							display: "inline-block",
							width: "390px",
						}}
					/>
				</Flex>
			</Flex>
		</div>
	);
}
