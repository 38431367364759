import { useEffect, useState } from "react";
import styled from "styled-components";

import { faEye, faLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Description } from "components/organisms/form/TextField";
import { TextareaFieldClean, TextareaFieldProps } from "components/organisms/form/TextareaField";
import useStromApiPut from "hooks/useStromApiPut";

export default function TextPreviewField({ label, inputRef, description, ...props }: TextareaFieldProps) {
	const [isPreviewing, setIsPreviewing] = useState(false);

	return (
		<Wrapper>
			<Buttons>
				<button
					title="Legg til lenke"
					onClick={() => {
						if (inputRef?.current) {
							const newValue = inputRef?.current?.value + "{link:<url>|tittel:<tittel>|tekst:<tekst>}";
							inputRef.current.value = newValue;
						}
					}}
					type="button"
					className="noStyle"
				>
					<FontAwesomeIcon icon={faLink} />
				</button>
				<button
					title="Forhåndsvis"
					onClick={() => {
						setIsPreviewing(!isPreviewing);
					}}
					type="button"
					className="noStyle"
				>
					<FontAwesomeIcon icon={faEye} />
				</button>
			</Buttons>
			<HideableTextareaWrapper isPreviewing={isPreviewing}>
				<TextareaFieldClean label={label} inputRef={inputRef} description={description} style={{ resize: "none" }} {...props} />
			</HideableTextareaWrapper>
			{isPreviewing && <Preview label={label} value={inputRef?.current?.value} description={description} />}
		</Wrapper>
	);
}

interface PreviewProps {
	label?: string;
	value: string;
	description?: string;
}
function Preview({ label, value, description }: PreviewProps) {
	const { updateData } = useStromApiPut("/produkter/fotnote");
	const [preview, setPreview] = useState<string>("");

	useEffect(() => {
		async function getPreview() {
			updateData(value, "POST").then(async (response) => {
				if (response.result && !response.error) {
					const previewData = await response.result.text();
					setPreview(previewData.charAt(0) === '"' ? previewData.substring(1, previewData.length - 1) : previewData);
				} else {
					setPreview("Noe gikk galt");
				}
			});
		}

		if (!preview) {
			getPreview();
		}
	}, []);

	if (!preview) {
		<div>
			<PreviewLabel>
				{label} (forhåndsvisning)
				<PreviewInput>Laster...</PreviewInput>
			</PreviewLabel>
			<Description>{description}</Description>
		</div>;
	}
	return (
		<div>
			<PreviewLabel>
				{label} (forhåndsvisning)
				<PreviewInput dangerouslySetInnerHTML={{ __html: preview }} />
			</PreviewLabel>
			<Description>{description}</Description>
		</div>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;
const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: end;
	gap: 0.5rem;

	position: absolute;
	width: 100%;
	top: -1rem;

	button {
		font-size: 1rem;
		padding: 0.5rem;
	}
`;
// To allow submitting the field even when preview is active
const HideableTextareaWrapper = styled.div<{ isPreviewing?: boolean }>`
	display: ${(props) => (props.isPreviewing ? "none" : "block")};
`;
const PreviewLabel = styled.span`
	display: block;
	margin-bottom: 5px;
	font-weight: 700;
	line-height: 1.1875rem;
	color: var(--nte-graa-mork);
`;
const PreviewInput = styled.div`
	min-height: 100px;
	width: 100%;
	padding: 15px;
	font-size: 17px;
	font-family: inherit;
	font-weight: 400;
	border-radius: 5px;
	border: 1px solid ${(props) => props.theme.colors.formWrapperBorder};
	background: ${(props) => props.theme.colors.lightestGray};
	resize: vertical;
`;
