// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveFjuttFromUserMutationVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['String']>;
  fjuttId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RemoveFjuttFromUserMutation = { removeFjuttFromUser: boolean };


export const RemoveFjuttFromUserDocument = gql`
    mutation RemoveFjuttFromUser($userId: String, $fjuttId: String) {
  removeFjuttFromUser(userId: $userId, fjuttId: $fjuttId)
}
    `;
export type RemoveFjuttFromUserMutationFn = Apollo.MutationFunction<RemoveFjuttFromUserMutation, RemoveFjuttFromUserMutationVariables>;

/**
 * __useRemoveFjuttFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveFjuttFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFjuttFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFjuttFromUserMutation, { data, loading, error }] = useRemoveFjuttFromUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      fjuttId: // value for 'fjuttId'
 *   },
 * });
 */
export function useRemoveFjuttFromUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFjuttFromUserMutation, RemoveFjuttFromUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFjuttFromUserMutation, RemoveFjuttFromUserMutationVariables>(RemoveFjuttFromUserDocument, options);
      }
export type RemoveFjuttFromUserMutationHookResult = ReturnType<typeof useRemoveFjuttFromUserMutation>;
export type RemoveFjuttFromUserMutationResult = Apollo.MutationResult<RemoveFjuttFromUserMutation>;
export type RemoveFjuttFromUserMutationOptions = Apollo.BaseMutationOptions<RemoveFjuttFromUserMutation, RemoveFjuttFromUserMutationVariables>;