import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Direction, Flex, Justify } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import useStromApi, { OrderConfirmation, OrderConfirmationResponse, Product } from "hooks/useStromApi";
import { Table } from "pages/Products/ProductsPage";
import LoadingAnimation from "pages/generic/LoadingAnimation";

interface EditOrderConfirmationPageProps {
	products: Product[];
	orderConfirmations: OrderConfirmation[];
}
export default function EditOrderConfirmationPage({ products, orderConfirmations }: EditOrderConfirmationPageProps) {
	const { orderConfirmationId } = useParams<{ orderConfirmationId: string }>();
	const { tariffId } = Object.fromEntries(new URLSearchParams(window?.location?.search));

	const [selectedTariffId, setSelectedTariffId] = useState<string | null>(tariffId || null);

	const orderConfirmation = useMemo(() => {
		return orderConfirmations.find((c) => c.id === orderConfirmationId);
	}, [orderConfirmations, orderConfirmationId]);

	const filteredProducts = useMemo(() => {
		return products.filter((p) => p?.ordrebekreftelse === orderConfirmationId);
	}, [products, orderConfirmationId]);

	const { data: email, isLoading: emailIsLoading } = useStromApi<OrderConfirmationResponse>(
		orderConfirmation?.epostUrl?.split(process.env.REACT_APP_STROEMBESTILLING_API_URL as string)[1] || ""
	);

	return (
		<Flex justifyContent={Justify.spaceBetween} direction={Direction.column} gap="2rem">
			<TitleLabel>Ordrebekreftelse for gruppe:</TitleLabel>
			<MainLayoutPageHeader title={orderConfirmation?.navn} />
			{filteredProducts?.length && (
				<label>
					Vis med produkt
					<br />
					<StyledSelect
						aria-label="produkt"
						title="Produkt"
						name="product"
						defaultValue={filteredProducts.find((p) => p.tariffId === selectedTariffId)?.id || ""}
						onChange={(e) => {
							const selectedOption = Array.from(e.target.options).find((option) => option.selected);
							setSelectedTariffId(products.find((p) => p.id === selectedOption?.value)?.tariffId || null);
						}}
					>
						{filteredProducts?.map((p) => (
							<option key={p.id} value={p.id}>
								{p.navn || "Ukjent produkt"}
							</option>
						))}
						<option value="">Eksempelprodukt</option>
					</StyledSelect>
				</label>
			)}
			<AttachementsContainer>
				<h2>Vedlegg</h2>
				{(orderConfirmation?.vedlegg?.length && (
					<Table>
						<thead>
							<tr>
								<th>Filnavn</th>
								<th>Type vedlegg</th>
								<th>Produkt</th>
							</tr>
						</thead>
						<tbody>
							{orderConfirmation?.vedlegg?.map((v, i) => {
								return (
									<tr key={i}>
										<td>
											<a
												target="_blank"
												rel="noreferrer"
												href={`${process.env.REACT_APP_STROEMBESTILLING_API_URL}/templates?document=${orderConfirmation.id}&attachment=${
													v.id
												}${selectedTariffId ? `&tariffID=${selectedTariffId}` : ""}`}
											>
												{v.filnavn}.pdf
											</a>
										</td>
										<td>{v.type}</td>
										<td>{!selectedTariffId ? "Eksempelprodukt" : filteredProducts.find((p) => p?.tariffId === selectedTariffId)?.navn}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				)) || <></>}
			</AttachementsContainer>
			<h2>Produkter</h2>
			<ProductsWrapper>
				{filteredProducts.map((product) => (
					<ProductLabel key={product.id}>{product.navn}</ProductLabel>
				))}
			</ProductsWrapper>
			{emailIsLoading && <LoadingAnimation />}
			{!emailIsLoading && email && (
				<EmailWrapper>
					<EmailTitle>
						{email.tittel}
						<img src="assets/icons/emailheader-title.svg" alt="Epost header avsender" />
					</EmailTitle>
					<EmailHeader>
						<img src="assets/icons/emailheader-sender.svg" alt="Epost avsender" />
						<img src="assets/icons/emailheader-buttons.svg" alt="Epost knapper" />
					</EmailHeader>
					<div dangerouslySetInnerHTML={{ __html: email.innhold }} />
				</EmailWrapper>
			)}
		</Flex>
	);
}

const TitleLabel = styled.span`
	font-size: 20px;
	font-weight: 700;
	color: var(--nte-graa-mork);
`;
const StyledSelect = styled.select`
	width: max-content;
	margin: 0.5rem 0;
`;
const AttachementsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const ProductsWrapper = styled.div`
	display: flexbox;
	gap: 1rem;
	margin-bottom: 2rem;
	flex-wrap: wrap;
`;
const ProductLabel = styled.label`
	padding: 1rem 1.5rem;
	border-radius: 9px;
	background: var(--nte-blaa-himmel);
	color: var(--nte-blaa-uu);
	font-weight: 700;
	display: flex;
	gap: 0.5rem;
`;
const EmailTitle = styled.h2`
	margin: 0;
	padding: 1rem;
	border: 1px solid #f0f0f0;
	font-family: Segoe UI;
	font-weight: 600;
	display: flex;
	gap: 1rem;
`;
const EmailWrapper = styled.div`
	border: 1px solid #f0f0f0;
`;
const EmailHeader = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
