import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const sharedBoxStyle = css`
	background: white;
	padding: 50px 20px !important;
	border-radius: 3px;
	border: 1px solid ${(props) => props.theme.colors.lightGrey} !important;
	font-size: 20px;
	text-decoration: none;
	transition: all 350ms ease;
	text-align:center;
	flex-grow: 1;
	color: ${(props) => props.theme.colors.linkDark};

	${(props) =>
		props.theme.media.mobile(css`
			padding: 30px 15px !important;
		`)}
	&:hover {
		color: ${(props) => props.theme.colors.primary};
		background-color: ${(props) => props.theme.colors.lightestGray};
	}

	&:focus {
		outline: 0;
		border: 1px solid ${(props) => props.theme.colors.darkGrey} !important;
	}

`;

const StyledButton = styled.a`
	${sharedBoxStyle}
`;

const StyledLink = styled(Link)`
	${sharedBoxStyle}
`;

interface BoxListItemProps {
	to?: string;
	onClick?: any;
	children?: ReactNode;
	col?: string;
	newLine?: boolean;
}

export default function BoxListItem({ to, onClick, children, col, newLine }: BoxListItemProps) {
	if (!col) col = "2";

	if (to) {
		return (
			<StyledLink to={to} tabIndex={0} role="button" className={"columns-" + col}>
				{children}
			</StyledLink>
		);
	}

	if(newLine){
		return (
			<StyledButton onClick={onClick} tabIndex={0} role="button" className={"columns-" + col} style={{flexBasis: "100%"}}>
				{children}
			</StyledButton>
		);
	}

	return (
		<StyledButton onClick={onClick} tabIndex={0} role="button" className={"columns-" + col}>
			{children}
		</StyledButton>
	);
}
