import { useNavigate, useParams } from "react-router-dom";

import { DangerButton } from "components/atoms/Button";
import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import MultiSelectField from "components/organisms/form/MultiSelectField";
import TextField from "components/organisms/form/TextField";
import ToggleField from "components/organisms/form/ToggleField";
import { useGetFeaturesFlagsQuery } from "pages/Features/FeatureFlagsPage.graphql-gen";

import { featureFlagLabelToGroup, GroupSchema } from "./CreateGroupPage";
import { useUpsertGroupMutation } from "./CreateGroupPage.graphql-gen";
import { useDeleteGroupMutation } from "./EditGroupPage.graphql-gen";
import { useGetGroupQuery } from "./SingleGroupPage.graphql-gen";

export default function EditGroupPage() {
	const { data: featureFlagsData } = useGetFeaturesFlagsQuery();
	const { groupId } = useParams<{ groupId: string }>();
	const navigate = useNavigate();
	const [upsertGroup, { loading: upsertLoading, error }] = useUpsertGroupMutation();
	const { data, loading } = useGetGroupQuery({ variables: { groupId: groupId || "" } });
	const [deleteGroup, { loading: deleteLoading }] = useDeleteGroupMutation();

	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<MainLayoutPageHeader
				loading={loading || deleteLoading}
				title={`Rediger ${data?.group?.displayName || "Gruppe"}`}
				intro={`${data?.group?.usersCount ?? 0} brukere i gruppen`}
			/>
			<Form
				key={data?.group?.id}
				loading={loading || upsertLoading}
				value={data?.group}
				onSubmit={async (group) => {
					console.log(group);
					const { data, errors } = await upsertGroup({
						variables: {
							group: {
								id: groupId,
								...group,
							},
						},
					});

					!errors && navigate("/groups/" + groupId);
				}}
				schema={GroupSchema}
			>
				<Flex direction={Direction.column} gap="var(--grid-2)">
					<TextField label="Navn" name="displayName" placeholder="Ny gruppe" />
					<TextField label="Key" name="key" placeholder="nyGruppe" />
					<ToggleField label="Users can opt in" name="usersCanOptIn" />

					<MultiSelectField
						label="Feature flags"
						name="featureFlagKeys"
						options={featureFlagsData?.featureFlags
							?.filter((featureFlag) => !featureFlag.global)
							?.map((featureFlag) => ({
								label: featureFlag.displayName,
								value: featureFlag.key,
								group: featureFlagLabelToGroup(featureFlag.displayName),
							}))}
					/>
					{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
					<Button>Oppdater gruppe</Button>
					<DangerButton
						type="button"
						onClick={async () => {
							if (
								!window.confirm(
									`Sikker på at du ønsker å slette gruppen?
Den har ${data?.group?.usersCount} medlemmer`
								)
							) {
								return;
							}

							const { errors } = await deleteGroup({
								variables: { groupId: groupId || "" },
							});

							if (errors) {
								return console.error(errors);
							}

							navigate("/groups/");
						}}
					>
						Slett gruppe
					</DangerButton>
				</Flex>
			</Form>
		</Flex>
	);
}
