import { useState } from "react";
import styled from "styled-components";

import { faMagnifyingGlassChart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex, Justify } from "../../components/atoms/Flex";
import Modal from "../../components/molecules/Modal";

export default function FraktalerPage() {
	//const [fraktalCustomerByCustomerId] = useGetFraktalCustomerByCustomerIdLazyQuery();
	//const [fraktalCustomerByMobile] = useGetFraktalCustomerByMobileLazyQuery();
	const [fraktalModalData, setFraktalModalData] = useState<null | { body?: string; status?: number }>(null);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Flex direction={Direction.column}>
			{fraktalModalData && (
				<Modal onClose={() => setFraktalModalData(null)}>
					<div style={{ padding: "16px" }}>
						<h2 style={{ textAlign: "center" }}>Status: {fraktalModalData.status}</h2>
						<pre>{fraktalModalData.body}</pre>
					</div>
				</Modal>
			)}

			<h1>Fraktaler</h1>

			<form
				onSubmit={async (e) => {
					e.preventDefault();

					if (isLoading) return;

					setIsLoading(true);

					/*
					setFraktalModalData(
						(
							await fraktalCustomerByMobile({
								variables: {
									mobileNumber: (e.target as any).mobileNumber.value || "",
								},
								fetchPolicy: "network-only",
							})
						).data?.fraktalCustomerByMobile || null
					);
					*/

					setIsLoading(false);
				}}
			>
				<h2>Kundedata på mobilnr</h2>
				<Flex alignItems={Justify.flexEnd}>
					<Label>
						<Flex direction={Direction.column}>
							<span>Mobil</span>
							<input disabled={true} placeholder="99 99 99 99" name="mobileNumber" />
						</Flex>
					</Label>
					<FraktalSearchButton disabled={true}>
						<FontAwesomeIcon icon={faMagnifyingGlassChart} />
					</FraktalSearchButton>
				</Flex>
			</form>

			<form
				onSubmit={async (e) => {
					e.preventDefault();

					if (isLoading) return;

					setIsLoading(true);

					/*
					setFraktalModalData(
						(
							await fraktalCustomerByCustomerId({
								variables: {
									customerId: parseInt((e.target as any).customerId.value || "0"),
								},
								fetchPolicy: "network-only",
							})
						).data?.fraktalCustomerByCustomerId || null
					);
					*/

					setIsLoading(false);
				}}
			>
				<h2>Kundedata på kundenr</h2>
				<Flex alignItems={Justify.flexEnd}>
					<Label>
						<Flex direction={Direction.column}>
							<span>Kundenr</span>
							<input disabled={true} placeholder="111111" name="customerId" />
						</Flex>
					</Label>
					<FraktalSearchButton disabled={true}>
						<FontAwesomeIcon icon={faMagnifyingGlassChart} />
					</FraktalSearchButton>
				</Flex>
			</form>
		</Flex>
	);
}

const FraktalSearchButton = styled.button`
	height: 50px;
	width: 50px;
	padding: 0px;
`;

const Label = styled.label`
	margin: 0;
`;
