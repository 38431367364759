import { ContentState, EditorState } from "draft-js";
import { useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from "styled-components";

interface ContentEditorProps {
	defaultValue?: ContentState;
	onChange?: (value: ContentState) => void;
}
export function ContentEditor(props: ContentEditorProps) {
	const [editorState, setEditorState] = useState((props.defaultValue && EditorState.createWithContent(props.defaultValue)) || EditorState.createEmpty());
	const editor = useRef<any>(null);

	return (
		<ContentEnditorWrapper>
			<Editor
				ref={editor}
				editorState={editorState}
				onEditorStateChange={(editorState) => {
					setEditorState(editorState);
					if (props.onChange) {
						props.onChange(editorState.getCurrentContent());
					}
				}}
				wrapperClassName="content-editor-wrapper"
				editorClassName="content-editor"
			/>
		</ContentEnditorWrapper>
	);
}

const ContentEnditorWrapper = styled.div`
	.content-editor-wrapper {
		border: 1px solid ${(props) => props.theme.colors.formWrapperBorder};
	}
	.content-editor {
		min-height: 300px;
		padding: 0 16px;
	}
`;
