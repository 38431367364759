import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DangerButton } from "components/atoms/Button";
import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import RangeField from "components/organisms/form/RangeField";
import SelectField from "components/organisms/form/SelectField";
import TextField from "components/organisms/form/TextField";
import ToggleField from "components/organisms/form/ToggleField";

import { useGetGroupsQuery } from "../Groups/GroupsPage.graphql-gen";
import { ABTestSchema } from "./CreateABTest";
import { useUpsertAbTestMutation } from "./CreateABTest.graphql-gen";
import { useDeleteAbTestMutation, useGetAbTestQuery } from "./EditABTest.graphql-gen";

export default function EditABTestPage() {
	const navigate = useNavigate();
	const { abTestId } = useParams<{ abTestId: string }>();
	const [upsertABTest, { loading: upsertLoading, error }] = useUpsertAbTestMutation();
	const [deleteABTest, { loading: deleteLoading }] = useDeleteAbTestMutation();
	const { data, loading } = useGetAbTestQuery({
		variables: { abTestId: abTestId || "" },
	});

	const { data: groupsData } = useGetGroupsQuery();

	const [percentage, setPercentage] = useState("50");

	useEffect(() => {
		setPercentage(data?.aBTest?.groupConnections?.[0]?.percentage?.toString() || "0");
	}, [data?.aBTest?.id || ""]);

	const percentageNumber = parseFloat(percentage);
	const aPercentage = percentageNumber;
	const bPercentage = 100 - percentageNumber;

	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<MainLayoutPageHeader loading={loading || upsertLoading || deleteLoading} title={`Rediger ${data?.aBTest?.name || "AB Test"}`} />
			<Form
				loading={loading}
				key={data?.aBTest?.id}
				value={{
					...data?.aBTest,
					percentage,
					groupA: data?.aBTest?.groupConnections?.[0]?.groupId,
					groupB: data?.aBTest?.groupConnections?.[1]?.groupId,
				}}
				onSubmit={async (abTest) => {
					const percentageNumber = parseFloat(percentage);

					const aPercentage = percentageNumber;
					const bPercentage = 100 - percentageNumber;

					const { data, errors } = await upsertABTest({
						variables: {
							abTest: {
								id: abTestId,
								name: abTest.name,
								enabled: abTest.enabled,
								groupConnections: [
									{
										groupId: abTest.groupA,
										percentage: aPercentage,
									},
									{
										groupId: abTest.groupB,
										percentage: bPercentage,
									},
								],
							},
						},
					});

					!errors && navigate("/ab-tests/" + abTestId);
				}}
				schema={ABTestSchema}
			>
				<Flex direction={Direction.column} gap="var(--grid-2)">
					<TextField label="Navn" name="name" placeholder="Ny AB Test" />

					<ToggleField label="Aktivert?" name="enabled" />

					<Flex direction={Direction.column}>
						<RangeField
							name="percentage"
							min="1"
							max="99"
							value={percentage}
							onChange={(e) => {
								const t = e.target as any;

								setPercentage(t.value);
							}}
						/>
						<Flex gap="var(--grid-2)">
							<Flex direction={Direction.column} style={{ width: "50%" }}>
								<span style={{ width: "100%", textAlign: "center" }}>{aPercentage}%</span>
								<SelectField
									label="A Gruppe"
									name="groupA"
									key={groupsData?.groups?.length}
									options={
										groupsData?.groups?.map((g) => ({
											value: g.id || "",
											label: `${g.displayName} (${g.usersCount})`,
										})) || []
									}
								/>
							</Flex>
							<Flex direction={Direction.column} style={{ width: "50%" }}>
								<span style={{ width: "100%", textAlign: "center" }}>{bPercentage}%</span>
								<SelectField
									label="B Gruppe"
									name="groupB"
									key={groupsData?.groups?.length}
									options={
										groupsData?.groups?.map((g) => ({
											value: g.id || "",
											label: `${g.displayName} (${g.usersCount})`,
										})) || []
									}
								/>
							</Flex>
						</Flex>
					</Flex>

					{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
					<Button>Lagre AB Test</Button>
					<DangerButton
						type="button"
						onClick={async () => {
							if (!window.confirm(`Sikker på at du ønsker å slette ab testen?`)) {
								return;
							}

							const { errors } = await deleteABTest({
								variables: { abTestId: abTestId || "" },
							});

							if (errors) {
								return console.error(errors);
							}

							navigate("/ab-tests");
						}}
					>
						Slett ab test
					</DangerButton>
				</Flex>
			</Form>
		</Flex>
	);
}
