import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import THEME from "theme";

import GlobalStyling from "components/atoms/GlobalStyling";
import MainLayout from "components/organisms/MainLayout";
import { useQuery } from "hooks/useParamsState";
import ContentPage from "pages/Content/ContentPage";
import CreateContentPage from "pages/Content/CreateContentPage";
import EditContentPage from "pages/Content/EditContentPage";
import CreateFeatureFlagPage from "pages/Features/CreateFeatureFlags";
import EditFeatureFlagPage from "pages/Features/EditFeatureFlag";
import FeatureFlagsPage from "pages/Features/FeatureFlagsPage";
import SingleFeatureFlagPage from "pages/Features/SingleFeatureFlagsPage";
import CreateGridOwnerMappingPage from "pages/GridOwnerMapping/CreateGridOwnerMappingPage";
import GridOwnerMappingsPage from "pages/GridOwnerMapping/GridOwnerMappingsPage";
import SingleGridOwnerMappingPage from "pages/GridOwnerMapping/SingleGridOwnerMappingPage";
import CreateGroupPage from "pages/Groups/CreateGroupPage";
import EditGroupPage from "pages/Groups/EditGroupPage";
import GroupsPage from "pages/Groups/GroupsPage";
import SingleGroupPage from "pages/Groups/SingleGroupPage";
import LoginPage from "pages/LoginPage";
import NotFound from "pages/NotFound";
import NotificationTemplatesPage from "pages/NotificationTemplates/NotificationTemplatesPage";
import ProductsTopPage from "pages/Products/ProductsTopPage";
import CreateServiceMessagePage from "pages/ServiceMessages/CreateServiceMessagePage";
import EditServiceMessagePage from "pages/ServiceMessages/EditServiceMessagePage";
import ServiceMessagesPage from "pages/ServiceMessages/ServiceMessagesPage";
import SingleSpotpriceAreaPage from "pages/SpotpriceAreas/SingleSpotpriceAreaPage";
import SpotpriceAreasPage from "pages/SpotpriceAreas/SpotpriceAreasPage";
import StatisticsPage from "pages/Statistics/StatisticsPage";
import ToolsPage from "pages/Tools/ToolsPage";
import EditUserPage from "pages/Users/EditUserPage";
import UsersPage from "pages/Users/UsersPage";

import ABTestsPage from "./pages/ABTests/ABTestsPage";
import CreateABTestPage from "./pages/ABTests/CreateABTest";
import EditABTestPage from "./pages/ABTests/EditABTest";
import { CreateContentTextPage } from "./pages/Content/CreateContentTextPage";
import { EditContentTextPage } from "./pages/Content/EditContentTextPage";
import FraktalerPage from "./pages/Fraktaler/FraktalerPage";
import InfoScreenPage from "./pages/InfoScreen/InfoScreenPage";
import { TransformationPlayground } from "./pages/InfoScreen/TransformationPlayground";
import ContentBlocksPage from "pages/Content/ContentBlocksPage";
import IntercomSegmentsPage from "pages/IntercomSegments/IntercomSegmentsPage";
import EditIntercomSegment from "pages/IntercomSegments/EditIntercomSegment";

function App() {
	const auth = useAuth();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const query = useQuery();

	useEffect(() => {
		if (auth.isLoading) return;

		if (!window.location.href.indexOf("?")) {
			return;
		}

		// Ensure that the get variables from keycloak wont persist.
		if (query.has("state")) query.delete("state");
		if (query.has("code")) query.delete("code");
		if (query.has("session_state")) query.delete("session_state");

		navigate(
			{
				pathname,
				search: query.toString(),
			},
			{ replace: true }
		);
	}, [auth.isLoading]);

	return (
		<ThemeProvider theme={THEME}>
			<GlobalStyling />
			<Routes>
				<Route>
					<Route path={"/public/*"} element={<InfoScreenPage />} />
					<Route path={"/infoscreen/*"} element={<TransformationPlayground />} />
					{auth.isAuthenticated ? (
						<Route path="/" element={<MainLayout />}>
							<Route index element={<UsersPage />} />

							{/* Brukere */}
							<Route path="/users" element={<UsersPage />} />
							<Route path="/users/:userId" element={<EditUserPage />} />

							{/* Grupper */}
							<Route path="/groups" element={<GroupsPage />} />
							<Route path="/groups/create" element={<CreateGroupPage />} />
							<Route path="/groups/:groupId" element={<SingleGroupPage />} />
							<Route path="/groups/:groupId/edit" element={<EditGroupPage />} />

							{/* Features */}
							<Route path="/features" element={<FeatureFlagsPage />} />
							<Route path="/features/create" element={<CreateFeatureFlagPage />} />
							<Route path="/features/:featureId" element={<SingleFeatureFlagPage />} />
							<Route path="/features/:featureId/edit" element={<EditFeatureFlagPage />} />

							{/* Varsler */}
							<Route path="/notification-templates" element={<NotificationTemplatesPage />} />

							{/* Innhold */}
							<Route path="/content" element={<ContentPage />} />
							<Route path="/content/create" element={<CreateContentPage />} />
							<Route path="/content/:contentId" element={<EditContentPage />} />

							{/* Innhold Tekst */}
							<Route path="/contentblocks" element={<ContentBlocksPage />} />
							<Route path="/contentblocks/create" element={<CreateContentTextPage />} />
							<Route path="/contentblocks/:contentId" element={<EditContentTextPage />} />

							{/* Service Messages */}
							<Route path="/service-messages" element={<ServiceMessagesPage />} />
							<Route path="/service-messages/create" element={<CreateServiceMessagePage />} />
							<Route path="/service-messages/:serviceMessageId" element={<EditServiceMessagePage />} />

							{/* Ab Tests */}
							<Route path="/ab-tests" element={<ABTestsPage />} />
							<Route path="/ab-tests/create" element={<CreateABTestPage />} />
							<Route path="/ab-tests/:abTestId" element={<EditABTestPage />} />

							{/* Spotprisområder */}
							<Route path="/spotprice-areas" element={<SpotpriceAreasPage />} />
							<Route path="/spotprice-areas/:spotpriceAreaId" element={<SingleSpotpriceAreaPage />} />

							{/* Statistikk */}
							<Route path="stats" element={<StatisticsPage />} />

							{/* Netteiere */}
							<Route path="/grid-templates" element={<GridOwnerMappingsPage />} />
							<Route path="/grid-templates/create" element={<CreateGridOwnerMappingPage />} />
							<Route path="/grid-templates/create/:fraktalname" element={<CreateGridOwnerMappingPage />} />
							<Route path="/grid-templates/:templateId" element={<SingleGridOwnerMappingPage />} />

							{/* Intercom segmenter */}
							<Route path="/intercom-segments" element={<IntercomSegmentsPage />} />
							<Route path="/intercom-segments/:intercomSegmentId" element={<EditIntercomSegment />} />

							{/* Produkter */}
							<Route path="/products/*" element={<ProductsTopPage />} />

							<Route path="/fraktaler" element={<FraktalerPage />} />
							<Route path="/tools" element={<ToolsPage />} />

							<Route path="/*" element={<NotFound />} />
						</Route>
					) : (
						<Route path="/*" element={<LoginPage />} />
					)}
				</Route>
			</Routes>
		</ThemeProvider>
	);
}

export default App;
