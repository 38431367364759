import { format, isWithinInterval } from "date-fns";
import { nb } from "date-fns/locale";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { ZodFormattedError, z } from "zod";

import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faFloppyDisk, faPlusCircle, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AddNewButton } from "components/atoms/Button";
import { Direction, Flex, Justify } from "components/atoms/Flex";
import Card from "components/molecules/Card";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper, SuccessWrapper } from "components/organisms/form/BaseField";
import { FormElementError } from "components/organisms/form/Form";
import SearchSelectField from "components/organisms/form/SearchSelectField";
import { SelectFieldClean } from "components/organisms/form/SelectField";
import { TextFieldClean } from "components/organisms/form/TextField";
import TextPreviewField from "components/organisms/form/TextPreviewField";
import { ToggleFieldClean } from "components/organisms/form/ToggleField";
import useStromApi, {
	Kundetype,
	OrderConfirmation,
	Price,
	Product,
	ProductBody,
	ProductDetails,
	ProductPrice,
	ProductWithDetails,
	Term,
	UcPrice,
	noneOrderConfirmation,
} from "hooks/useStromApi";
import useStromApiPut from "hooks/useStromApiPut";
import { FullPageLoading } from "pages/Products/ProductsTopPage";
import { getDataFromSubmit } from "utils/FormValidator";
import { getUcBaseLink } from "utils/Utils";

function formatValue(value: string, dateFrom: Date, dateTo: Date, isBedrift: boolean) {
	// Verdi is received as string, in kr, without mums
	// Consider moving this to backend

	if (!value) {
		return "";
	}
	let formattedVerdi: string;

	let isNegative: boolean = false;
	let isOere: boolean = false;

	if (value.startsWith("−") || value.startsWith("-")) {
		isNegative = true;
	}

	let numeric = Number(value.replace("−", "").replace("-", "").replace(",", "."));

	if (isNaN(numeric)) {
		return value;
	}
	if (numeric === 0) {
		return "0 kr";
	}

	if (numeric < 1) {
		isOere = true;
		numeric = numeric * 100;
	}

	const withMums = isBedrift ? numeric : numeric * 1.25;
	formattedVerdi = withMums.toFixed(isOere ? 3 : 2).replace(".", ",");

	if (isNegative) {
		// Add "- "
		formattedVerdi = "-" + formattedVerdi;
	}
	if (isOere) {
		formattedVerdi += " øre";
	} else {
		formattedVerdi += " kr";
	}

	// Add "inactive"
	if (dateFrom && dateTo && !isActive(dateFrom, dateTo)) {
		formattedVerdi = formattedVerdi + " (inaktiv)";
	}
	return formattedVerdi;
}

function isActive(dateFrom: Date, dateTo: Date) {
	return isWithinInterval(new Date(), { start: new Date(dateFrom), end: new Date(dateTo) });
}

const productSchema = z.object({
	id: z.string().min(1, "Produktet må ha en tariffId"),
	agreementType: z.string().min(1, "Produktet må ha en avtaletype"),
	isCompany: z.boolean().optional(),
	orderConfirmation: z.string({ required_error: "Ordrebekreftelse må være satt" }).min(1, "Ordrebekreftelse må være satt"),
	prices: z.array(z.string().min(1, "Pris må være satt")).optional(),
	name: z.string().min(1),
	description: z.string().min(1, "Produktet må ha beskrivelse"),
	footnote: z.string().optional(),
	priceDescription: z.string().min(1, "Produktet må ha prissammendrag"),
	terms: z.array(z.string().min(1, "Vilkår kan ikke være tomt")).optional(),
});

/*
Minifix: 
- Gjør produkt-lista i bunnen UU-vennlig, kun iu/ul inni ul
- Fiks array lint warning
*/

type IndexTerm = Term & {
	index: number;
};
interface EditProductPageProps {
	products: Product[];
	orderConfirmations: OrderConfirmation[];
	updateProducts: () => void;
}
export default function EditProductPage({ products, orderConfirmations, updateProducts }: EditProductPageProps) {
	const { ucProductId } = useParams<{ ucProductId: string }>();

	const tariffIdSearchRef = useRef(null);
	const tariffAgreementTypeRef = useRef(null);
	const footNoteRef = useRef(null);
	const descriptionRef = useRef(null);

	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [selectedOrderConfirmation, setSelectedOrderConfirmation] = useState<OrderConfirmation | null>(null);
	const [selectedTariffId, _setSelectedTariffId] = useState<string | null>(null);
	function setSelectedTariffId(newTariffId: string | null) {
		_setSelectedTariffId(newTariffId);

		const newProduct = unLinkedProducts?.find((p) => p.tariffId === newTariffId) || null;
		if (tariffAgreementTypeRef.current && newProduct?.avtaletype) {
			const agreementTypeInput = tariffAgreementTypeRef.current as HTMLInputElement;
			agreementTypeInput.value = newProduct?.avtaletype;
		}
	}

	const [terms, _setTerms] = useState<IndexTerm[]>([]);
	const setTerms = (terms: IndexTerm[], isFormChanged?: boolean) => {
		if (isFormChanged) {
			// To prevent form change to trigger on initial load
			setIsFormChanged(true);
		}
		_setTerms(terms);
	};

	const [formErrors, setFormErrors] = useState<ZodFormattedError<z.infer<typeof productSchema>>>();
	const [mainError, _setMainError] = useState<string | null>(null);
	const [lastSave, _setLastSave] = useState<Date | null>(null);
	const setMainError = (error: string) => {
		_setLastSave(null);
		_setMainError(error);
	};
	const setLastSave = (save: Date) => {
		_setMainError(null);
		_setLastSave(save);
	};

	const productFromList: Product | null = useMemo(() => {
		return products.find((p) => p.id === ucProductId) || null;
	}, [products, ucProductId]);

	const { data: unLinkedProducts } = useStromApi<Product[]>("/produkter/spesifikasjoner"); // Technically not Product, but Productspecification
	const { data: storedProduct, isLoading: storedProductLoading } = useStromApi<ProductWithDetails>(`/produkter/detaljer/${ucProductId}`);

	const { updateData: updateProduct, isLoading: isUpdateLoading } = useStromApiPut(`/produkter/${productFromList?.tariffId}`);
	const { updateData: addProduct, isLoading: isAddLoading } = useStromApiPut(`/produkter/ny/${ucProductId}`);

	const product: Product | null = useMemo(() => {
		const currentProduct = storedProduct?.produkt;
		if (currentProduct) {
			return currentProduct;
		} else {
			// Product from list will be missing uc prices due to implementation details in strom api
			return productFromList;
		}
	}, [storedProduct, productFromList]);

	const productDetails: ProductDetails | null = useMemo(() => {
		return storedProduct?.produktSpesifikasjon || null;
	}, [storedProduct]);

	useEffect(() => {
		if (product && product.vilkår) {
			setTerms(
				product.vilkår.map((v: Term, i: number) => {
					return { ...v, index: i };
				}),
				false
			);
		}
	}, [product]);

	useEffect(() => {
		if (product && orderConfirmations?.length) {
			if (product.ordrebekreftelse === noneOrderConfirmation.id) {
				setSelectedOrderConfirmation(noneOrderConfirmation);
				return;
			}
			const defaultOrderConfirmation = orderConfirmations.find((c) => c.id === product.ordrebekreftelse);
			setSelectedOrderConfirmation(defaultOrderConfirmation || null);
		}
	}, [product, orderConfirmations]);

	useEffect(() => {
		function handlePageUnload(e: BeforeUnloadEvent) {
			e.preventDefault();
		}
		function handleBackNavigation(e: PopStateEvent) {
			e.preventDefault();
			const doGoBack = window.confirm("Er du sikker på at du ønsker å gå tilbake? Det kan hende endringene dine ikke er lagret.");
			if (!doGoBack) {
				window.history.go(1); // This still wipes the page state, but unsure how else to handle it
			}
		}
		if (isFormChanged) {
			window.addEventListener("beforeunload", handlePageUnload);
			window.addEventListener("popstate", handleBackNavigation);
		}
		return () => {
			window.removeEventListener("beforeunload", handlePageUnload);
			window.removeEventListener("popstate", handleBackNavigation);
		};
	}, [isFormChanged]);

	function changeTermsPosition(newIndex: number, currentIndex: number) {
		var termsCopy = [...terms];
		var tempElement = termsCopy[newIndex];
		termsCopy[newIndex] = termsCopy[currentIndex];
		termsCopy[currentIndex] = tempElement;
		setTerms(termsCopy);
	}

	if (storedProductLoading) {
		return <FullPageLoading />;
	} else if (!product) {
		return <>Kunne ikke finne produkt med UC produkt id {ucProductId}</>;
	}
	return (
		<Flex justifyContent={Justify.start} gap="4rem">
			<div style={{ width: "43.75rem" }}>
				<MainLayoutPageHeader title={product.navn} />
				{(product.oppdatert?.oppdatertAv && product.oppdatert?.sistOppdatert && (
					<p>{`Sist redigert av: ${product.oppdatert?.oppdatertAv}, den ${format(new Date(product.oppdatert?.sistOppdatert), "d. MMMM yyyy, HH:mm", {
						locale: nb,
					})}`}</p>
				)) || <p>Produktet er ikke redigert tidligere</p>}

				<StyledForm
					onSubmit={async (e) => {
						e.preventDefault();
						if (isUpdateLoading || isAddLoading) {
							return;
						}
						if (product.harOrdrebekreftelse_ingen_IUC && selectedOrderConfirmation?.id !== noneOrderConfirmation.id) {
							const doSaveProduct = window.confirm(
								'Er du sikker på at du ønsker å lagre produktet? Produktet er blokkert for generering av ordrebekreftelser i UC. Fjern ekstrafeltet "ordrebekreftelse" fra produktet og oppdater før du går videre.'
							);
							if (!doSaveProduct) {
								return;
							}
						}
						const validated = getDataFromSubmit(e.target, productSchema);
						if (!validated.success) {
							const parsedErrors = validated.error.format();
							setFormErrors(parsedErrors);
							setMainError("Det er feil i skjemaet, vennligst se over.");
							return;
						}
						setFormErrors(undefined);

						const data = validated.data;
						if (data.orderConfirmation === noneOrderConfirmation.id) {
							if (!window.confirm("Sikker på at produktet ikke skal ha ordrebekreftelse?")) {
								setMainError("Produktet er ikke lagret.");
								return;
							}
						}

						const submitData: ProductBody = {
							navn: data.name,
							tariffId: data.id,
							avtaleType: data.agreementType,
							kundeType: data.isCompany ? Kundetype.BEDRIFT : Kundetype.FORBRUKER,
							beskrivelse: data.description,
							fotnotetekst: data.footnote,
							oppsummering: data.priceDescription,
							vilkår: data.terms || [],
							ordrebekreftelsesmal: data.orderConfirmation,
							priser: data.prices?.length
								? data.prices.map<any>((p: string, i: number) => {
										if (!selectedOrderConfirmation?.prising) {
											return;
										}
										return {
											prisTypeId: selectedOrderConfirmation?.prising[i].prisTypeId,
											gjeldendePris: p,
										};
								  })
								: undefined,
						};
						let response;

						if (!!product.tariffId) {
							// Product already exists in strom db
							response = await updateProduct(submitData);
						} else {
							// Product is new from UC, needs to be added to db
							response = await addProduct(submitData, "POST");
						}

						if (response.error) {
							setMainError(response.error);
						} else {
							updateProducts();
							setIsFormChanged(false);
							setLastSave(new Date());
						}
					}}
					onChange={() => {
						setIsFormChanged(true);
					}}
				>
					<Flex direction={Direction.column} gap="1rem">
						<h2>Avtaleinfo</h2>
						<h3>Generell info som må fylles for nye produkter</h3>
						<SearchSelectField
							name="id"
							label="TariffId"
							inputRef={tariffIdSearchRef}
							selectedValue={product?.tariffId || selectedTariffId || undefined}
							setSelectedValue={setSelectedTariffId}
							options={unLinkedProducts?.map((p) => ({
								value: p.tariffId,
								label: p.tariffId,
							}))}
							required
							readOnly={!!product.tariffId} // Existing products should not change tariffId
							formErrors={formErrors as FormElementError<unknown>}
						/>
						<TextFieldClean
							name="agreementType"
							label="Avtaletype"
							inputRef={tariffAgreementTypeRef}
							defaultValue={product.avtaletype}
							required
							description='For eksempel "Spotpris time for time", "Spotpris time for time med bindingstid" eller "Tilleggstjeneste med månedsabonnement"'
							formErrors={formErrors as FormElementError<unknown>}
						/>
						<ToggleFieldClean name="isCompany" label="Avtalen er en bedriftsavtale" defaultChecked={product.kundetype === Kundetype.BEDRIFT} />
					</Flex>

					<Flex direction={Direction.column} gap="1rem">
						<h2>Ordrebekreftelse</h2>
						<h3>Informasjonen her blir brukt av ordrebekreftelse</h3>
						{orderConfirmations?.length && (
							<>
								<div>
									<SelectFieldClean
										value={selectedOrderConfirmation?.id || ""}
										label="Ordrebekreftelse"
										required={true}
										name="orderConfirmation"
										onChange={(value) => {
											if (value === noneOrderConfirmation.id) {
												setSelectedOrderConfirmation(noneOrderConfirmation);
											} else {
												const selected = orderConfirmations.find((c) => c.id === value);
												setSelectedOrderConfirmation(selected || null);
											}
										}}
										options={
											orderConfirmations
												?.map((g) => ({
													value: g.id,
													label: g.navn,
													disabled: false,
													hidden: false,
												}))
												.concat([
													{ value: noneOrderConfirmation.id, label: noneOrderConfirmation.navn, disabled: false, hidden: false },
													{
														value: "",
														label: "Velg ordrebekreftelse",
														disabled: true,
														hidden: true,
													},
												]) || []
										}
										formErrors={formErrors as FormElementError<unknown>}
									/>
									{selectedOrderConfirmation?.id && selectedOrderConfirmation?.id !== noneOrderConfirmation.id && (
										<Link
											to={`/products/order-confirmations/${selectedOrderConfirmation.id}${
												product.tariffId ? `?tariffId=${product.tariffId}` : ""
											}`}
											target="_blank"
										>
											Se ordrebekreftelse
										</Link>
									)}
								</div>

								{(selectedOrderConfirmation?.prising &&
									selectedOrderConfirmation.prising.map((pris, i) => (
										<TextFieldClean
											key={i}
											index={i}
											required={true}
											name="prices[]"
											label={pris.tittel}
											defaultValue={
												product.priselementer?.find((prisElement: Price) => prisElement.prisTypeId === pris.prisTypeId)?.gjeldendePris
											}
											placeholder="00 kr/øre"
											formErrors={formErrors as FormElementError<unknown>}
										/>
									))) || <></>}
							</>
						)}
					</Flex>

					<Flex direction={Direction.column} gap="1rem">
						<h2>NTE.no</h2>
						<h3>Informasjonen her blir sendt videre og brukt på nte.no</h3>
						<TextFieldClean name="name" label="Produktnavn" value={product.navn} readOnly={true} />
						<TextPreviewField
							name="description"
							label="Beskrivelse"
							inputRef={descriptionRef}
							description="Lenker må ha dette formatet: {link:<url>|tittel:<tittel>|tekst:<tekst>}"
							defaultValue={product.beskrivelse}
							formErrors={formErrors as FormElementError<unknown>}
						/>
						<TextPreviewField
							name="footnote"
							label="Fotnote"
							inputRef={footNoteRef}
							defaultValue={product.fotnote?.skript}
							description="Lenker må ha dette formatet: {link:<url>|tittel:<tittel>|tekst:<tekst>}"
							formErrors={formErrors as FormElementError<unknown>}
						/>
						<TextFieldClean
							name="priceDescription"
							label="Prissammendrag"
							defaultValue={product.prisbeskrivelse}
							formErrors={formErrors as FormElementError<unknown>}
						/>
						{(terms?.length && (
							<label>
								Vilkår
								<TermsWrapper>
									{terms.map((t: IndexTerm, i: number) => {
										return (
											<TermDragAndSortingWrapper key={`${t.index}-${t.tekst.slice(0, 29)}`}>
												<SortingButtonsContainer>
													<button
														style={{ gridRow: "1" }}
														type="button"
														className="noStyle"
														onClick={() => changeTermsPosition(i - 1, i)}
														disabled={i === 0}
													>
														{i === 0 ? "" : <FontAwesomeIcon icon={faAngleUp} />}
													</button>

													<button
														style={{ gridRow: "2" }}
														type="button"
														className="noStyle"
														onClick={() => changeTermsPosition(i + 1, i)}
														disabled={i === terms.length - 1}
													>
														{i === terms.length - 1 ? "" : <FontAwesomeIcon icon={faAngleDown} />}
													</button>
												</SortingButtonsContainer>
												<TextFieldClean
													key={t.index}
													index={i}
													name="terms[]"
													defaultValue={t?.tekst}
													onDelete={() => {
														let newTerms = [...terms];
														newTerms.splice(i, 1);
														setTerms(newTerms);
													}}
													formErrors={formErrors as FormElementError<unknown>}
												/>
											</TermDragAndSortingWrapper>
										);
									})}
								</TermsWrapper>
							</label>
						)) || <>Ingen vilkår</>}
						<AddNewButton
							type="button"
							onClick={() => {
								if (terms?.length) {
									setTerms([...terms, { tekst: "", index: terms[terms.length - 1]?.index + 1 }]);
								} else {
									setTerms([{ tekst: "", index: 0 }]);
								}
							}}
						>
							<FontAwesomeIcon icon={faPlusCircle} />
							Legg til nytt vilkår
						</AddNewButton>
					</Flex>

					<Flex direction={Direction.column} gap="1rem">
						{(mainError || lastSave) && !(isUpdateLoading || isAddLoading) && (
							<MessageWrapper>
								{mainError && <ErrorWrapper>Produktet ble ikke lagret: {mainError}</ErrorWrapper>}
								{lastSave && (
									<SuccessWrapper>
										<FontAwesomeIcon icon={faCheck} style={{ marginRight: "0.5rem" }} />
										Produktet ble sist lagret {format(lastSave, "d. MMMM yyyy, HH:mm", { locale: nb })}
									</SuccessWrapper>
								)}
							</MessageWrapper>
						)}
						<StyledButton>
							{isUpdateLoading || isAddLoading ? (
								<>Laster...</>
							) : (
								<>
									<FontAwesomeIcon icon={faFloppyDisk} style={{ marginRight: "0.5rem" }} />
									Lagre produkt
								</>
							)}
						</StyledButton>
					</Flex>
				</StyledForm>
			</div>

			<Flex gap="2rem" direction={Direction.column}>
				{product.utilityCloudPriser && product.utilityCloudPriser?.length > 0 && (
					<Card
						title={
							<>
								{product.navn} fra{" "}
								<a target="_blank" rel="noreferrer" href={`${getUcBaseLink()}/#/products/edit/${ucProductId}`} title="Se produktet i UC">
									Utility Cloud
								</a>
							</>
						}
					>
						{product.harOrdrebekreftelse_ingen_IUC === true && (
							<div>
								<Flex direction={Direction.row} alignItems={Justify.center} gap="0.5rem">
									<FontAwesomeIcon icon={faWarning} style={{ marginRight: "0.5rem", color: "orange" }} />
									<div>
										Produktet er blokkert for generering av ordrebekreftelser i UC. Fjern ekstrafeltet "ordrebekreftelse" fra produktet og
										oppdater før du går videre.
									</div>
								</Flex>
								<img
									style={{ maxWidth: "100%", marginTop: "1rem" }}
									src={"/ingen_ordrebekreftelse.png"}
									alt="Ingen ordrebekreftelse feltet i bunnen av nettsiden til Utility Cloud"
								/>
							</div>
						)}
						<PriceInformation>
							Priser:
							<ul>
								{product.utilityCloudPriser?.map((p: UcPrice, i: number) => {
									return (
										<li key={i}>
											{p.navn ? p.navn + ":" : ""}
											<ul>
												{p?.priser.map((pris: ProductPrice, k: number) => {
													return (
														<div key={k}>
															<li>
																{formatValue(
																	pris.verdi,
																	pris.gyldigFra,
																	pris.gyldigTil,
																	product.kundetype === Kundetype.BEDRIFT
																)}
															</li>
															{pris.gyldigFra && (
																<ul>
																	<li>(Rådata er {pris.verdi}, vanligvis oppgitt i kr uten moms)</li>
																	{pris.gyldigFra && <li>Gjelder fra: {new Date(pris.gyldigFra).toLocaleDateString()}</li>}
																	{pris.gyldigTil && <li>Gjelder til: {new Date(pris.gyldigTil).toLocaleDateString()}</li>}
																</ul>
															)}
														</div>
													);
												})}
											</ul>
										</li>
									);
								})}
							</ul>
						</PriceInformation>
					</Card>
				)}

				{productDetails && (
					<Card title="Tariffinformasjon fra strømbestilling-api">
						<PriceInformation>
							<ul>
								<li>Alias: {productDetails.alias?.length > 0 ? productDetails.alias.join(", ") : "-"}</li>
								<li>Regler: {productDetails.regler?.length > 0 ? productDetails.regler.join(", ") : "-"}</li>
								<li>Hovedprodukt: {productDetails.hovedprodukt !== null ? (productDetails.hovedprodukt ? "Ja" : "Nei") : "-"}</li>
								<li>Kredittsjekk: {productDetails.kredittsjekk !== null ? (productDetails.kredittsjekk ? "Ja" : "Nei") : "-"}</li>
								<li>Manuell: {productDetails.manuell !== null ? (productDetails.manuell ? "Ja" : "Nei") : "-"}</li>
							</ul>
						</PriceInformation>
					</Card>
				)}
			</Flex>
		</Flex>
	);
}

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 4rem;
`;
const TermsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
const MessageWrapper = styled.div`
	width: 0;
	min-width: 100%;
`;
const TermDragAndSortingWrapper = styled.div`
	display: flex;
`;
const SortingButtonsContainer = styled.div`
	display: grid;
	grid-template-rows: 1fr 1fr;
	button {
		font-size: 1rem;
		padding: 0 0.5rem;
		color: var(--nte-blaa-uu);
	}
`;
const StyledButton = styled.button`
	width: fit-content;
`;

const PriceInformation = styled.div`
	display: flex;
	flex-direction: column;
`;
