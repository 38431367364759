import App from "App";
import { ApolloClientProvider } from "config/apollo";
import { WebStorageStateStore } from "oidc-client-ts";
import { render } from "react-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter as Router } from "react-router-dom";

const oidcStateStore = new WebStorageStateStore({
	store: window.localStorage,
});

render(
	<Router>
		<AuthProvider
			authority={process.env.REACT_APP_OIDC_AUTHORITY as string}
			client_id={process.env.REACT_APP_KEYCLOAK_CLIENTID as string}
			redirect_uri={window.location.href}
			userStore={oidcStateStore}
		>
			<ApolloClientProvider>
				<App />
			</ApolloClientProvider>
		</AuthProvider>
	</Router>,
	document.getElementById("root")
);
