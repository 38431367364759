import styled, { keyframes } from "styled-components";

export default function Loading() {
    return (<>
        <LoadingWrapper />
        <LoadingWrapper delay={0.2} />
        <LoadingWrapper delay={0.4} />
        <LoadingWrapper delay={0.6} />
        <LoadingWrapper delay={0.8} />
        <LoadingWrapper delay={1} />
        <LoadingWrapper delay={1.2} />
        <LoadingWrapper delay={1.4} />
    </>
    );
}

const fadeAnimation = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
`;

const LoadingWrapper: any = styled.div`
    background-color: #f4f4f4;
	box-sizing: border-box;
	width: 100%;
    height: 50px;
    padding: 14px 20px;
    animation: ${fadeAnimation} 1s infinite;
    animation-delay: ${(p: any) => p.delay + "s" || 0};
`;
