import React, { useEffect, useMemo, useRef } from "react";
import { ReactNode } from "react";
import { useAuth } from "react-oidc-context";

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";

export function ApolloClientProvider({ children }: { children: ReactNode }) {
	const auth = useAuth();
	const tokenRef = useRef(auth.user?.access_token);

	useEffect(() => {
		tokenRef.current = auth.user?.access_token;
	}, [auth.user?.access_token]);

	const client = useMemo(() => {
		const httpLink = createHttpLink({
			uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || "/graphql",
		});

		const authLink = setContext(async (_, { headers }) => {
			let Authorization = "";

			if (tokenRef.current) {
				Authorization = "Bearer " + tokenRef.current;
				window.token = Authorization;
			}

			return {
				headers: {
					...headers,
					Authorization,
				},
			};
		});
		const retryLink = new RetryLink({
			delay: {
				initial: 300,
				max: Infinity,
				jitter: true,
			},
			attempts: {
				max: 5,
				retryIf: async (error, _operation) => {
					// eslint-disable-next-line eqeqeq
					if (error.statusCode == 401) {
						await auth.signinSilent();

						return true;
					}

					return false;
				},
			},
		});

		return new ApolloClient({
			link: from([retryLink, authLink, httpLink]),
			cache: new InMemoryCache({
				typePolicies: {
					User: {
						keyFields: ["userId"],
					},
				},
			}),
			defaultOptions: {
				watchQuery: {
					fetchPolicy: "cache-and-network",
				},
			},
		});
	}, [false]);

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
