// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { users?: Array<{ userId?: string, name?: string, mobileNumber?: string, emailAddress?: string, customerIds?: Array<number>, birthDate?: string, groupIds?: Array<string>, disableSync: boolean }> };

export type UserListFragment = { userId?: string, name?: string, mobileNumber?: string, emailAddress?: string, customerIds?: Array<number>, birthDate?: string, groupIds?: Array<string>, disableSync: boolean };

export type PaginateUsersQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']>;
  startIndex?: Types.InputMaybe<Types.Scalars['Int']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type PaginateUsersQuery = { paginatedUsers?: { total?: any, items?: Array<{ userId?: string, name?: string, mobileNumber?: string, emailAddress?: string, customerIds?: Array<number>, birthDate?: string, groupIds?: Array<string>, disableSync: boolean }> } };

export const UserListFragmentDoc = gql`
    fragment UserList on User {
  userId
  name
  mobileNumber
  emailAddress
  customerIds
  birthDate
  groupIds
  disableSync
}
    `;
export const GetUsersDocument = gql`
    query getUsers {
  users {
    ...UserList
  }
}
    ${UserListFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const PaginateUsersDocument = gql`
    query PaginateUsers($search: String, $startIndex: Int, $count: Int) {
  paginatedUsers(search: $search, startIndex: $startIndex, count: $count) {
    items {
      ...UserList
    }
    total
  }
}
    ${UserListFragmentDoc}`;

/**
 * __usePaginateUsersQuery__
 *
 * To run a query within a React component, call `usePaginateUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      startIndex: // value for 'startIndex'
 *      count: // value for 'count'
 *   },
 * });
 */
export function usePaginateUsersQuery(baseOptions?: Apollo.QueryHookOptions<PaginateUsersQuery, PaginateUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginateUsersQuery, PaginateUsersQueryVariables>(PaginateUsersDocument, options);
      }
export function usePaginateUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateUsersQuery, PaginateUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginateUsersQuery, PaginateUsersQueryVariables>(PaginateUsersDocument, options);
        }
export type PaginateUsersQueryHookResult = ReturnType<typeof usePaginateUsersQuery>;
export type PaginateUsersLazyQueryHookResult = ReturnType<typeof usePaginateUsersLazyQuery>;
export type PaginateUsersQueryResult = Apollo.QueryResult<PaginateUsersQuery, PaginateUsersQueryVariables>;