// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertSpotpriceAreaMutationVariables = Types.Exact<{
  spotpriceArea?: Types.InputMaybe<Types.SpotpriceZoneInput>;
}>;


export type UpsertSpotpriceAreaMutation = { upsertSpotpriceZone?: { id?: Types.NordpoolArea, notifyOver?: number, highPrice?: number, lowPrice?: number } };

export type GetSingleSpotpriceAreaQueryVariables = Types.Exact<{
  spotpriceAreaId: Types.Scalars['String'];
}>;


export type GetSingleSpotpriceAreaQuery = { singleSpotpriceZone?: { id?: Types.NordpoolArea, notifyOver?: number, highPrice?: number, lowPrice?: number } };


export const UpsertSpotpriceAreaDocument = gql`
    mutation UpsertSpotpriceArea($spotpriceArea: SpotpriceZoneInput) {
  upsertSpotpriceZone(spotpriceZone: $spotpriceArea) {
    id
    notifyOver
    highPrice
    lowPrice
  }
}
    `;
export type UpsertSpotpriceAreaMutationFn = Apollo.MutationFunction<UpsertSpotpriceAreaMutation, UpsertSpotpriceAreaMutationVariables>;

/**
 * __useUpsertSpotpriceAreaMutation__
 *
 * To run a mutation, you first call `useUpsertSpotpriceAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSpotpriceAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSpotpriceAreaMutation, { data, loading, error }] = useUpsertSpotpriceAreaMutation({
 *   variables: {
 *      spotpriceArea: // value for 'spotpriceArea'
 *   },
 * });
 */
export function useUpsertSpotpriceAreaMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSpotpriceAreaMutation, UpsertSpotpriceAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSpotpriceAreaMutation, UpsertSpotpriceAreaMutationVariables>(UpsertSpotpriceAreaDocument, options);
      }
export type UpsertSpotpriceAreaMutationHookResult = ReturnType<typeof useUpsertSpotpriceAreaMutation>;
export type UpsertSpotpriceAreaMutationResult = Apollo.MutationResult<UpsertSpotpriceAreaMutation>;
export type UpsertSpotpriceAreaMutationOptions = Apollo.BaseMutationOptions<UpsertSpotpriceAreaMutation, UpsertSpotpriceAreaMutationVariables>;
export const GetSingleSpotpriceAreaDocument = gql`
    query GetSingleSpotpriceArea($spotpriceAreaId: String!) {
  singleSpotpriceZone(spotpriceZoneId: $spotpriceAreaId) {
    id
    notifyOver
    highPrice
    lowPrice
  }
}
    `;

/**
 * __useGetSingleSpotpriceAreaQuery__
 *
 * To run a query within a React component, call `useGetSingleSpotpriceAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleSpotpriceAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleSpotpriceAreaQuery({
 *   variables: {
 *      spotpriceAreaId: // value for 'spotpriceAreaId'
 *   },
 * });
 */
export function useGetSingleSpotpriceAreaQuery(baseOptions: Apollo.QueryHookOptions<GetSingleSpotpriceAreaQuery, GetSingleSpotpriceAreaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleSpotpriceAreaQuery, GetSingleSpotpriceAreaQueryVariables>(GetSingleSpotpriceAreaDocument, options);
      }
export function useGetSingleSpotpriceAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleSpotpriceAreaQuery, GetSingleSpotpriceAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleSpotpriceAreaQuery, GetSingleSpotpriceAreaQueryVariables>(GetSingleSpotpriceAreaDocument, options);
        }
export type GetSingleSpotpriceAreaQueryHookResult = ReturnType<typeof useGetSingleSpotpriceAreaQuery>;
export type GetSingleSpotpriceAreaLazyQueryHookResult = ReturnType<typeof useGetSingleSpotpriceAreaLazyQuery>;
export type GetSingleSpotpriceAreaQueryResult = Apollo.QueryResult<GetSingleSpotpriceAreaQuery, GetSingleSpotpriceAreaQueryVariables>;