import { ContractStatus, Meter, Residence } from "generated/graphql-types";

/**
 * Checks if any meter has an active contract
 * @param r Residence associated with meters to check
 * @returns True if active, false if not
 */
export function residenceHasActiveContract(r: Residence | undefined) {
	return r?.meters?.some((m) => meterHasActiveContract(m)) || false;
}

/**
 * Checks if a meter has an active contract
 * @param m Meter to check
 * @returns True if active, false if not
 */
export function meterHasActiveContract(m: Meter | undefined) {
	return m?.electricityContracts?.some((c) => c.status === ContractStatus.ACTIVE) || false;
}

/**
 * Returns the display name of a contract status
 * @param s Contract status
 * @returns Display name
 */
export function contractStatusDisplayName(s: ContractStatus | undefined) {
	switch (s) {
		case ContractStatus.ACTIVE: {
			return "Aktiv";
		}
		case ContractStatus.CEASED: {
			return "Opphørt";
		}
		case ContractStatus.PROCESSING: {
			return "Prosesseres";
		}
		case ContractStatus.TO_BE_CEASED: {
			return "Opphører";
		}
		case ContractStatus.WAITING_TRANSFER: {
			return "Avventer overføring";
		}
		default: {
			return "Ukjent";
		}
	}
}
