// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPushNotificationErrorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPushNotificationErrorsQuery = { notifications?: { total?: any, items?: Array<{ notificationId?: string, title?: string, sentAt?: string, pushNotificationReferences?: Array<{ referende?: string, status?: Types.NotificationStatus }>, user?: { userId?: string, disableSync: boolean, devices?: Array<{ deviceId: string, model?: string, pushNotificationToken?: string }> } }> } };

export type FixPushNotificationTokenMutationVariables = Types.Exact<{
  pushNotificationToken: Types.Scalars['String'];
}>;


export type FixPushNotificationTokenMutation = { fixPushNotificationToken?: boolean };

export type BulkFixPushNotificationTokensMutationVariables = Types.Exact<{
  pushNotificationTokens: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type BulkFixPushNotificationTokensMutation = { bulkFixPushNotificationTokens?: Array<boolean> };


export const GetPushNotificationErrorsDocument = gql`
    query getPushNotificationErrors {
  notifications(pushStatus: SENDING_ERROR) {
    total
    items {
      notificationId
      title
      sentAt
      pushNotificationReferences {
        referende
        status
      }
      user {
        userId
        disableSync
        devices {
          deviceId
          model
          pushNotificationToken
        }
      }
    }
  }
}
    `;

/**
 * __useGetPushNotificationErrorsQuery__
 *
 * To run a query within a React component, call `useGetPushNotificationErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPushNotificationErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPushNotificationErrorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPushNotificationErrorsQuery(baseOptions?: Apollo.QueryHookOptions<GetPushNotificationErrorsQuery, GetPushNotificationErrorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPushNotificationErrorsQuery, GetPushNotificationErrorsQueryVariables>(GetPushNotificationErrorsDocument, options);
      }
export function useGetPushNotificationErrorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPushNotificationErrorsQuery, GetPushNotificationErrorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPushNotificationErrorsQuery, GetPushNotificationErrorsQueryVariables>(GetPushNotificationErrorsDocument, options);
        }
export type GetPushNotificationErrorsQueryHookResult = ReturnType<typeof useGetPushNotificationErrorsQuery>;
export type GetPushNotificationErrorsLazyQueryHookResult = ReturnType<typeof useGetPushNotificationErrorsLazyQuery>;
export type GetPushNotificationErrorsQueryResult = Apollo.QueryResult<GetPushNotificationErrorsQuery, GetPushNotificationErrorsQueryVariables>;
export const FixPushNotificationTokenDocument = gql`
    mutation fixPushNotificationToken($pushNotificationToken: String!) {
  fixPushNotificationToken(pushNotificationToken: $pushNotificationToken)
}
    `;
export type FixPushNotificationTokenMutationFn = Apollo.MutationFunction<FixPushNotificationTokenMutation, FixPushNotificationTokenMutationVariables>;

/**
 * __useFixPushNotificationTokenMutation__
 *
 * To run a mutation, you first call `useFixPushNotificationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFixPushNotificationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fixPushNotificationTokenMutation, { data, loading, error }] = useFixPushNotificationTokenMutation({
 *   variables: {
 *      pushNotificationToken: // value for 'pushNotificationToken'
 *   },
 * });
 */
export function useFixPushNotificationTokenMutation(baseOptions?: Apollo.MutationHookOptions<FixPushNotificationTokenMutation, FixPushNotificationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FixPushNotificationTokenMutation, FixPushNotificationTokenMutationVariables>(FixPushNotificationTokenDocument, options);
      }
export type FixPushNotificationTokenMutationHookResult = ReturnType<typeof useFixPushNotificationTokenMutation>;
export type FixPushNotificationTokenMutationResult = Apollo.MutationResult<FixPushNotificationTokenMutation>;
export type FixPushNotificationTokenMutationOptions = Apollo.BaseMutationOptions<FixPushNotificationTokenMutation, FixPushNotificationTokenMutationVariables>;
export const BulkFixPushNotificationTokensDocument = gql`
    mutation bulkFixPushNotificationTokens($pushNotificationTokens: [String!]!) {
  bulkFixPushNotificationTokens(pushNotificationTokens: $pushNotificationTokens)
}
    `;
export type BulkFixPushNotificationTokensMutationFn = Apollo.MutationFunction<BulkFixPushNotificationTokensMutation, BulkFixPushNotificationTokensMutationVariables>;

/**
 * __useBulkFixPushNotificationTokensMutation__
 *
 * To run a mutation, you first call `useBulkFixPushNotificationTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkFixPushNotificationTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkFixPushNotificationTokensMutation, { data, loading, error }] = useBulkFixPushNotificationTokensMutation({
 *   variables: {
 *      pushNotificationTokens: // value for 'pushNotificationTokens'
 *   },
 * });
 */
export function useBulkFixPushNotificationTokensMutation(baseOptions?: Apollo.MutationHookOptions<BulkFixPushNotificationTokensMutation, BulkFixPushNotificationTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkFixPushNotificationTokensMutation, BulkFixPushNotificationTokensMutationVariables>(BulkFixPushNotificationTokensDocument, options);
      }
export type BulkFixPushNotificationTokensMutationHookResult = ReturnType<typeof useBulkFixPushNotificationTokensMutation>;
export type BulkFixPushNotificationTokensMutationResult = Apollo.MutationResult<BulkFixPushNotificationTokensMutation>;
export type BulkFixPushNotificationTokensMutationOptions = Apollo.BaseMutationOptions<BulkFixPushNotificationTokensMutation, BulkFixPushNotificationTokensMutationVariables>;