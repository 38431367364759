import { debounce } from "lodash";
import { useState } from "react";
import { useAuth } from "react-oidc-context";

export default function useStromApiPut(endpoint: string) {
	const auth = useAuth();
	const token = auth?.user?.access_token;

	const [isLoading, _setIsLoading] = useState(false);
	const debouncedLoading = debounce((val) => _setIsLoading(val), 500);
	const setIsLoading = (val: boolean) => {
		if (val) {
			_setIsLoading(val);
		} else {
			debouncedLoading(val);
		}
	};

	type Response = {
		result?: globalThis.Response;
		error?: string;
	};
	async function updateData(body: any, method?: string): Promise<Response> {
		setIsLoading(true);
		return fetch(`${process.env.REACT_APP_STROEMBESTILLING_API_URL}${endpoint}`, {
			method: method || "PUT",
			headers: {
				"Authorization": `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then(async (response) => {
				setIsLoading(false);
				if (!response.ok) {
					const data = await response.json(); // Likely no body if only 200 OK
					const error = (data && data.errors?.[0]?.message) || response.status;
					return Promise.reject(error);
				}
				return { result: response };
			})
			.catch((error) => {
				setIsLoading(false);
				console.error(error);
				return { error: JSON.stringify(error).replaceAll('"', "") };
			});
	}

	return {
		isLoading,
		updateData,
	};
}
