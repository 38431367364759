import { format } from "date-fns";
import { nb } from "date-fns/locale";

export default function useDateTime() {
	return {
		format: (dateString?: string, formatString: string = "Pp") => {
			if (!dateString) return "";

			return format(new Date(dateString), formatString, { locale: nb });
		},
	};
}
