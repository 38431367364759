// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { FeatureFlagDetailsFragmentDoc } from './SingleFeatureFlagsPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertFeatureFlagMutationVariables = Types.Exact<{
  featureFlag: Types.FeatureFlagInput;
}>;


export type UpsertFeatureFlagMutation = { upsertFeatureFlag?: { id?: string, key: string, displayName: string, global?: boolean, usersCount?: any, disabledMessage?: string, enabled?: boolean, from?: string, propertiesJson?: string, to?: string, version?: number } };


export const UpsertFeatureFlagDocument = gql`
    mutation UpsertFeatureFlag($featureFlag: FeatureFlagInput!) {
  upsertFeatureFlag(featureFlag: $featureFlag) {
    ...FeatureFlagDetails
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;
export type UpsertFeatureFlagMutationFn = Apollo.MutationFunction<UpsertFeatureFlagMutation, UpsertFeatureFlagMutationVariables>;

/**
 * __useUpsertFeatureFlagMutation__
 *
 * To run a mutation, you first call `useUpsertFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFeatureFlagMutation, { data, loading, error }] = useUpsertFeatureFlagMutation({
 *   variables: {
 *      featureFlag: // value for 'featureFlag'
 *   },
 * });
 */
export function useUpsertFeatureFlagMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFeatureFlagMutation, UpsertFeatureFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFeatureFlagMutation, UpsertFeatureFlagMutationVariables>(UpsertFeatureFlagDocument, options);
      }
export type UpsertFeatureFlagMutationHookResult = ReturnType<typeof useUpsertFeatureFlagMutation>;
export type UpsertFeatureFlagMutationResult = Apollo.MutationResult<UpsertFeatureFlagMutation>;
export type UpsertFeatureFlagMutationOptions = Apollo.BaseMutationOptions<UpsertFeatureFlagMutation, UpsertFeatureFlagMutationVariables>;