import styled from "styled-components";

export const FlexRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

export const FlexColumn = styled.div<{ width?: string }>`
	flex: 0 0 ${(p) => p.width || "auto"};
	${(p) => (p.width ? "" : "margin-right: 30px;")}
	max-width: fit-content;
`;
