// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { ServiceMessageDetailsFragmentDoc } from './ServiceMessagesPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertServiceMessageMutationVariables = Types.Exact<{
  serviceMessage: Types.ServiceMessageInput;
}>;


export type UpsertServiceMessageMutation = { upsertServiceMessage?: { id?: string, title?: string, content?: string, published: boolean, publishDate?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, location?: Array<Types.ServiceMessageLocation> } };


export const UpsertServiceMessageDocument = gql`
    mutation UpsertServiceMessage($serviceMessage: ServiceMessageInput!) {
  upsertServiceMessage(serviceMessage: $serviceMessage) {
    ...ServiceMessageDetails
  }
}
    ${ServiceMessageDetailsFragmentDoc}`;
export type UpsertServiceMessageMutationFn = Apollo.MutationFunction<UpsertServiceMessageMutation, UpsertServiceMessageMutationVariables>;

/**
 * __useUpsertServiceMessageMutation__
 *
 * To run a mutation, you first call `useUpsertServiceMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertServiceMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertServiceMessageMutation, { data, loading, error }] = useUpsertServiceMessageMutation({
 *   variables: {
 *      serviceMessage: // value for 'serviceMessage'
 *   },
 * });
 */
export function useUpsertServiceMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpsertServiceMessageMutation, UpsertServiceMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertServiceMessageMutation, UpsertServiceMessageMutationVariables>(UpsertServiceMessageDocument, options);
      }
export type UpsertServiceMessageMutationHookResult = ReturnType<typeof useUpsertServiceMessageMutation>;
export type UpsertServiceMessageMutationResult = Apollo.MutationResult<UpsertServiceMessageMutation>;
export type UpsertServiceMessageMutationOptions = Apollo.BaseMutationOptions<UpsertServiceMessageMutation, UpsertServiceMessageMutationVariables>;