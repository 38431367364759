import styled, { css } from "styled-components";

export default function ServiceMessageHeader(props: { serviceMessage?: { title?: string; content?: string } }) {
	const { serviceMessage } = props;

	return (
		<Wrapper>
			<Title>{serviceMessage?.title}</Title>
			<Paragraph>{serviceMessage?.content}</Paragraph>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	background-color: #fff3ae;
	color: #28292c;
	width: 100%;
	padding: 17px 24px;
	box-sizing: border-box;
`;

export const headingXS = css`
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.3rem;
`;

export const paragraphSmall = css`
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.3;
	letter-spacing: 0.005em;
`;

const Title = styled.h2`
	${headingXS}
	margin: 0 0 4px;
	padding: 0;
`;

const Paragraph = styled.p`
	${paragraphSmall}
	margin: 0;
	padding: 0;
`;
