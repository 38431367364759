import styled from "styled-components";

import { Direction, Flex } from "../atoms/Flex";

const ContentPagePreviewWrapper = styled(Flex)`
	padding: 17px;
	box-sizing: border-box;
	border-radius: 16px;
	box-shadow: 0px 4px 10px rgba(5, 113, 168, 0.1);
	border: 1px solid #ddd;
	overflow: hidden;
	width: 390px;
	height: 844px;
	overflow: auto;
`;
const ContentPagePreviewTitle = styled.span`
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	color: #28292c;
`;
const ContentPagePreviewContent = styled.span`
	font-weight: normal;
	font-size: 0.875rem;
	line-height: 1.5;
`;
const ContentPagePreviewImage = styled.div<{ image: string }>`
	background-image: url(${(p: any) => p.image});
	width: calc(100% + 34px);
	height: 180px;
	flex-shrink: 0;
	margin: 0 -17px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

interface ContentPagePreviewProps {
	title: string;
	contentHtml: string;
	imageUrl?: string;
}
export default function ContentPagePreview(props: ContentPagePreviewProps) {
	return (
		<ContentPagePreviewWrapper direction={Direction.column} gap="17px">
			<ContentPagePreviewTitle>{props.title}</ContentPagePreviewTitle>
			{props.imageUrl && <ContentPagePreviewImage image={props.imageUrl} />}
			<ContentPagePreviewContent dangerouslySetInnerHTML={{ __html: props.contentHtml }} />
		</ContentPagePreviewWrapper>
	);
}
