import { debounce } from "lodash";
import { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoRow, InfoColumn } from "components/atoms/InfoRow";
import ModalButton from "components/organisms/ModalButton";
import Pagination from "components/organisms/Pagination";
import UserRow from "components/organisms/UserRow";
import useParamsState from "hooks/useParamsState";

import { User } from "generated/graphql-types";

import { usePaginateUsersQuery } from "../../Users/UsersPage.graphql-gen";
import { useGetUsersOnGroupQuery } from "../SingleGroupPage.graphql-gen";
import { useAddUserToGroupMutation, useRemoveUserFromGroupMutation } from "./SingleGroupsPageUsersTab.graphql-gen";

const PER_PAGE = 40;

export default function SingleGroupsPageUsersTab(props: { groupId: string }) {
	const navigate = useNavigate();
	const [search, setSearch] = useParamsState("search", "");

	const [currentPage, setCurrentPage] = useParamsState("page", 1);
	const { data, previousData, loading } = useGetUsersOnGroupQuery({
		variables: {
			groupId: props.groupId || "",
			search: (!!search && search) || undefined,
			startIndex: PER_PAGE * (currentPage - 1),
			count: PER_PAGE,
		},
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onSearchUpdate = useCallback(
		debounce((newSearch: string) => setSearch(newSearch), 200),
		[]
	);

	const totalPages = data?.group?.users?.total ?? previousData?.group?.users?.total ?? 1;

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<ModalButton label="Rediger brukere i gruppe">
				<h3 style={{ textAlign: "center" }}>Rediger brukere i gruppen</h3>
				<FindUserModalContent groupId={props.groupId} />
			</ModalButton>
			<input
				type="text"
				defaultValue={search}
				onChange={(e) => {
					onSearchUpdate(e.target.value);
				}}
				placeholder="Søk etter Navn, tlf, e-post, Id eller kundenr"
			/>
			<Flex direction={Direction.column} gap="var(--grid-2)">
				{loading &&
					Array.from(Array(PER_PAGE)).map((u, i) => (
						<InfoRow interactive key={i}>
							<InfoColumn width="100%">
								<Skeleton />
							</InfoColumn>
						</InfoRow>
					))}
				{!loading && data?.group?.users?.items?.map((user) => <UserRow key={user?.userId} user={user as User} />)}
				<Pagination
					total={totalPages}
					perPage={PER_PAGE}
					currentPage={currentPage - 1}
					onChange={(newCurrentPage) => {
						setCurrentPage(newCurrentPage + 1);
						window.scrollTo(0, 0);
					}}
				/>
			</Flex>
		</Flex>
	);
}

interface FindUserModalContentProps {
	groupId?: string;
}
function FindUserModalContent(props: FindUserModalContentProps) {
	const [search, setSearch] = useParamsState("search", "");

	const { data, loading } = usePaginateUsersQuery({
		variables: {
			search: (!!search && search) || undefined,
			count: 20,
		},
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onSearchUpdate = useCallback(
		debounce((newSearch: string) => setSearch(newSearch), 200),
		[]
	);

	const [addUserToGroup, { loading: addUserLoading }] = useAddUserToGroupMutation();
	const [removeUserFromGroup, { loading: removeUserLoading }] = useRemoveUserFromGroupMutation();

	return (
		<FindUserModalContentWrapper direction={Direction.column} gap="calc( var(--grid-2) * 2)" style={{ width: "calc(50vw)", height: "calc(50vh)" }}>
			<input
				type="text"
				defaultValue={search}
				onChange={(e) => {
					onSearchUpdate(e.target.value);
				}}
				placeholder="Søk etter Navn, tlf, e-post, Id eller kundenr"
			/>
			<Flex direction={Direction.column} gap="var(--grid-2)">
				{loading &&
					Array.from(Array(20)).map((u, i) => (
						<InfoRow interactive key={i}>
							<InfoColumn width="100%">
								<Skeleton />
							</InfoColumn>
						</InfoRow>
					))}
				{!loading &&
					data?.paginatedUsers?.items?.map((user) => (
						<InfoRow interactive key={user.userId}>
							<InfoColumn width="15%">
								{user.groupIds?.find((g) => g === props.groupId) ? (
									<RemoveButton
										disabled={removeUserLoading}
										onClick={async () =>
											await removeUserFromGroup({
												variables: {
													userId: user.userId || "",
													groupId: props.groupId || "",
												},
												refetchQueries: ["GetUsersOnGroup", "GetGroup"],
											})
										}
									>
										<FontAwesomeIcon icon={faMinus} />
									</RemoveButton>
								) : (
									<AddButton
										disabled={addUserLoading}
										onClick={async () =>
											await addUserToGroup({
												variables: {
													userId: user.userId || "",
													groupId: props.groupId || "",
												},
												refetchQueries: ["GetUsersOnGroup", "GetGroup"],
											})
										}
									>
										<FontAwesomeIcon icon={faPlus} />
									</AddButton>
								)}
							</InfoColumn>
							<InfoColumn width="85%">{user.name}</InfoColumn>
						</InfoRow>
					))}
			</Flex>
		</FindUserModalContentWrapper>
	);
}

const FindUserModalContentWrapper = styled(Flex)`
	width: 50vw;
	height: 50vh;
	min-width: 300px;
	min-height: 300px;
	max-height: 100%;
	padding: 16px;
`;

const AddButton = styled.button`
	padding: 10px;
	height: 50px;
	width: 50px;
`;
const RemoveButton = styled(AddButton)`
	background-color: #cc2c2c;
	border-color: #cf4040;
`;
