import React, { HTMLProps } from "react";

import { BaseField } from "components/organisms/form/BaseField";
import { FormElementError, useForm } from "components/organisms/form/Form";
import { useRefState } from "hooks/useRefState";

type TextFieldProps = Omit<HTMLProps<HTMLInputElement>, "type">;

export default function RangeField({ name, value, defaultValue, onChange, disabled, ...props }: TextFieldProps) {
	const {
		errors,
		disabled: disabledState,
		defaultValue: formDefaultValue,
	} = useForm(name, {
		getValue: () => value || valueRef.current,
	});
	const [valueRef, setValue] = useRefState<string>((defaultValue as string) || formDefaultValue || "");

	return (
		<BaseField errors={errors as FormElementError<unknown>}>
			<label>
				{props.label}
				<input
					{...props}
					disabled={disabled || disabledState}
					value={value}
					defaultValue={defaultValue || formDefaultValue}
					name={name}
					type="range"
					onChange={(e) => {
						setValue(e.target.value);
						onChange?.(e);
					}}
				/>
			</label>
		</BaseField>
	);
}
