import { useAuth } from "react-oidc-context";

function parseJwt(token: string) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);

	return JSON.parse(jsonPayload);
}

export default function useAccess(): string[] {
	const auth = useAuth();

	const accessToken = auth.user?.access_token || "";
	const data = parseJwt(accessToken);

	return data?.resource_access?.["mittnte-adminapi"]?.roles || [];
}
