import {LinePath} from "@visx/shape";
import {GroupedMeteringValuesDetailsFragment, useMeteringValuesQuery} from "./StatisticsPage.graphql-gen";
import {curveLinear} from "@visx/curve";
import styled from "styled-components";
import {scaleLinear, scaleUtc} from "@visx/scale";
import { extent, max } from '@visx/vendor/d3-array';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { format } from 'date-fns'
import { useTooltipInPortal, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import {useRef} from "react";


type LineChartProps = {
    width: number;
    height: number;
};

function percentageChange(v1: number, v2: number): number {
    return Math.round((((v1 - v2) / v2) * 100) * 10) / 10;
}

function convertPercentageToStyledHTML(percentage: string) {
    if (percentage.includes("-")) {
        return (<span style={{color: "red", fontSize: "70%"}}>{" "}({percentage})</span>);
    }
    return (<span style={{color: "green", fontSize: "70%"}}>{" "}({percentage})</span>);
}

export const UCMeteringValuesAndCostFileLineChart = ({width, height}: LineChartProps) => {

    const {data: queryResponse, loading} = useMeteringValuesQuery()

    let arrayCopy: TooltipData[] = [...queryResponse?.meteringValueFileStats || []];
    arrayCopy.sort((a, b) => new Date(a.time || "").getTime() - new Date(b.time || "").getTime());
    let meteringValueStats: TooltipData[] = [];
    for(let i = 0; i < arrayCopy.length; i++) {

        if (i === 0) {
            meteringValueStats.push(arrayCopy[i]);
            continue;
        }

        let previousElement = arrayCopy[i - 1];
        let currentElement = arrayCopy[i];

        let difference = currentElement.meteringValues! - previousElement.meteringValues!;


        meteringValueStats.push({...currentElement, percentageDifferenceFromYesterdayMeteringValues: percentageChange(currentElement.meteringValues!, previousElement.meteringValues!) + "%", percentageDifferenceFromYesterdayCashFlow: percentageChange(currentElement.cashFlowFiles!, previousElement.cashFlowFiles!) + "%"});
    }


    const leftPadding = 50;
    const bottomPadding = 30;

    const intlFormatter = Intl.NumberFormat();

    const getX = (d: GroupedMeteringValuesDetailsFragment) => new Date(d.time || Date.now());
    const getMaxY = (mv: GroupedMeteringValuesDetailsFragment) => {
        if(mv.cashFlowFiles! > mv.meteringValues!){
            return mv.cashFlowFiles;
        }
        return mv.meteringValues;
    }
    const getCostFiles = (mv: GroupedMeteringValuesDetailsFragment) => mv.cashFlowFiles || 0;
    const getMeteringValues = (mv: GroupedMeteringValuesDetailsFragment) => mv.meteringValues || 0;

    const xScale = scaleUtc<number>({
        domain: extent(meteringValueStats || [], getX) as [Date, Date],
        range: [leftPadding, width -30]
    });

    const yScale = scaleLinear<number>({
        domain: [0, max(meteringValueStats || [], getMaxY) as number],
        range: [height -bottomPadding, 0]
    });

    interface TooltipData extends GroupedMeteringValuesDetailsFragment {
        percentageDifferenceFromYesterdayMeteringValues?: string;
        percentageDifferenceFromYesterdayCashFlow?: string;
    }

    const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
        useTooltip<TooltipData>();

    const { TooltipInPortal } = useTooltipInPortal({
        // TooltipInPortal is rendered in a separate child of <body /> and positioned
        // with page coordinates which should be updated on scroll. consider using
        // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
        scroll: true,
    });

    const ref = useRef<SVGSVGElement | null>(null)


    return (
        <GraphSVG
            ref={ref}
            width={width}
            height={height}
            onMouseMove={e => {
                const position = localPoint(e) || {x: 0, y: 0};
                const xDomain = xScale.invert(position.x)

                if (!meteringValueStats) return;

                let index = 0;

                for (let i = 0; i < meteringValueStats.length; i++) {
                    const mv = meteringValueStats[i];

                    const time = new Date(mv.time || "").getTime();

                    if (Math.abs(xDomain.getTime() -time) < 43000000) {
                        index = i;
                        break;
                    }
                }

                const boundingClientRect = ref.current?.getBoundingClientRect?.();

                showTooltip({
                    tooltipData: meteringValueStats[index],
                    tooltipLeft: (boundingClientRect?.left ?? 0) + xScale(getX(meteringValueStats[index])),
                    tooltipTop: (boundingClientRect?.top ?? 0) + position.y
                })
            }}
            onMouseLeave={hideTooltip}
        >


            <LinePath<GroupedMeteringValuesDetailsFragment>
                data={meteringValueStats || []}
                x={(d) => xScale(getX(d)) ?? 0}
                y={(d) => yScale(getMeteringValues(d)) ?? 0}
                width={width}
                height={height}
                stroke="red"
                curve={curveLinear}
            />

            <LinePath<GroupedMeteringValuesDetailsFragment>
                data={meteringValueStats || []}
                x={(d) => xScale(getX(d)) ?? 0}
                y={(d) => yScale(getCostFiles(d)) ?? 0}
                width={width}
                height={height}
                stroke="blue"
                curve={curveLinear}
            />

            <AxisBottom
                scale={xScale}
                top={height - bottomPadding}
                tickFormat={(value: any) => format(new Date(value), "dd/MM")}
                numTicks={meteringValueStats?.length || 0}
            />
            <AxisLeft
                scale={yScale}
                left={leftPadding}

            />

            {tooltipOpen && tooltipData &&
                <>
                    <line x1={xScale(getX(tooltipData))} x2={xScale(getX(tooltipData))} y1={0} y2={height -bottomPadding} stroke={"black"}/>
                    <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
                        <div>
                            <b>Time: {format(new Date(tooltipData.time || ""), "dd/MM")}</b>
                        </div>
                        <div>
                            <b><b style={{color: "red"}}>Metering value: </b>{intlFormatter.format(tooltipData.meteringValues || 0)}</b>
                            {convertPercentageToStyledHTML(tooltipData.percentageDifferenceFromYesterdayMeteringValues || "")}
                        </div>
                        <div>
                            <b><b style={{color: "blue"}}>Cash flow files: </b>{intlFormatter.format(tooltipData.cashFlowFiles || 0)}</b>
                            {convertPercentageToStyledHTML(tooltipData.percentageDifferenceFromYesterdayCashFlow || "")}
                        </div>
                    </TooltipInPortal>
                </>
            }
            <rect
                x={0}
                y={0}
                height={height}
                width={width}
                fill={"transparent"}
            />
        </GraphSVG>
    );
};


const GraphSVG = styled.svg`
    width: 100%;
!important;
    height: auto;
!important;
`;
