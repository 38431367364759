import { MutableRefObject, useRef, useState } from "react";

export function useRefState<T>(defaultValue: T): [MutableRefObject<T>, (newValue: T) => void, T] {
	const ref = useRef<T>(defaultValue);
	const [state, setState] = useState<T>(defaultValue);

	return [
		ref,
		(newValue: T) => {
			ref.current = newValue;
			setState(newValue);
		},
		state,
	];
}
