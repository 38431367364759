import { debounce } from "lodash";
import { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoColumn, InfoRow } from "components/atoms/InfoRow";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import ModalButton from "components/organisms/ModalButton";
import Pagination from "components/organisms/Pagination";
import UserRow from "components/organisms/UserRow";
import useParamsState from "hooks/useParamsState";
import AddUserFromUcModal from "pages/Users/AddUserFromUcModal";
import { usePaginateUsersQuery } from "pages/Users/UsersPage.graphql-gen";

import { User } from "generated/graphql-types";
import Error from "components/molecules/Error";
import Loading from "components/molecules/Loading";

const PER_PAGE = 40;

export default function UsersPage() {
	const [search, setSearch] = useParamsState("search", "");

	const [currentPage, setCurrentPage] = useParamsState("page", 1);

	const { data: countData } = usePaginateUsersQuery({
		variables: {
			startIndex: 0,
			count: 1,
		},
	});

	const { data, previousData, loading, error } = usePaginateUsersQuery({
		variables: {
			search: (!!search && search) || undefined,
			startIndex: PER_PAGE * (currentPage - 1),
			count: PER_PAGE,
		},
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onSearchUpdate = useCallback(
		debounce((newSearch: string) => setSearch(newSearch), 200),
		[]
	);

	const totalPages = data?.paginatedUsers?.total ?? previousData?.paginatedUsers?.total ?? 1;

	let countText = undefined;

	if(countData?.paginatedUsers?.total){
		if (countData?.paginatedUsers?.total === data?.paginatedUsers?.total) {
			countText = data?.paginatedUsers?.total + " brukere";
		} else {
			countText = `${data?.paginatedUsers?.total || "..."} av ${countData?.paginatedUsers?.total || "..."} brukere`;
		}
	}

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<MainLayoutPageHeader
				title={
					<>
						Brukere
						<HeaderModalButton label={<FontAwesomeIcon icon={faPlus} />}>
							<AddUserFromUcModal />
						</HeaderModalButton>
					</>
				}
				intro={countText || ""}
				
			/>
			<input
				type="text"
				defaultValue={search}
				onChange={(e) => {
					onSearchUpdate(e.target.value);
				}}
				placeholder="Søk etter navn, tlf, e-post, id eller kundenr"
			/>
			<Flex direction={Direction.column} gap="0px" style={{borderRadius: "15px", overflow: "hidden"}}>
				{(error && !loading) && <Error errorMessage="Feil ved henting av brukere" />}
				{loading &&
					<Loading />
				}
				{!loading && data?.paginatedUsers?.items?.map((user) => <UserRow key={user?.userId} user={user as User} />)}
			</Flex>

				<Pagination
					total={totalPages}
					perPage={PER_PAGE}
					currentPage={currentPage - 1}
					onChange={(newCurrentPage) => {
						setCurrentPage(newCurrentPage + 1);
						window.scrollTo(0, 0);
					}}
				/>
		</Flex>
	);
}

const HeaderModalButton = styled(ModalButton)`
	height: 40px;
	width: 40px;
	margin-left: 16px;
	padding: 0;
	line-height: 0;
	border: none;
	border-radius: 50%;
`;
