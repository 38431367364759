// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { ServiceMessageDetailsFragmentDoc } from './ServiceMessagesPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleServiceMessageQueryVariables = Types.Exact<{
  serviceMessageId: Types.Scalars['String'];
}>;


export type SingleServiceMessageQuery = { singleServiceMessage?: { id?: string, title?: string, content?: string, published: boolean, publishDate?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, location?: Array<Types.ServiceMessageLocation> } };

export type DeleteServiceMessageMutationVariables = Types.Exact<{
  serviceMessageId: Types.Scalars['String'];
}>;


export type DeleteServiceMessageMutation = { deleteServiceMessage?: { id?: string, title?: string, content?: string, published: boolean, publishDate?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, location?: Array<Types.ServiceMessageLocation> } };


export const SingleServiceMessageDocument = gql`
    query SingleServiceMessage($serviceMessageId: String!) {
  singleServiceMessage(serviceMessageId: $serviceMessageId) {
    ...ServiceMessageDetails
  }
}
    ${ServiceMessageDetailsFragmentDoc}`;

/**
 * __useSingleServiceMessageQuery__
 *
 * To run a query within a React component, call `useSingleServiceMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleServiceMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleServiceMessageQuery({
 *   variables: {
 *      serviceMessageId: // value for 'serviceMessageId'
 *   },
 * });
 */
export function useSingleServiceMessageQuery(baseOptions: Apollo.QueryHookOptions<SingleServiceMessageQuery, SingleServiceMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleServiceMessageQuery, SingleServiceMessageQueryVariables>(SingleServiceMessageDocument, options);
      }
export function useSingleServiceMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleServiceMessageQuery, SingleServiceMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleServiceMessageQuery, SingleServiceMessageQueryVariables>(SingleServiceMessageDocument, options);
        }
export type SingleServiceMessageQueryHookResult = ReturnType<typeof useSingleServiceMessageQuery>;
export type SingleServiceMessageLazyQueryHookResult = ReturnType<typeof useSingleServiceMessageLazyQuery>;
export type SingleServiceMessageQueryResult = Apollo.QueryResult<SingleServiceMessageQuery, SingleServiceMessageQueryVariables>;
export const DeleteServiceMessageDocument = gql`
    mutation DeleteServiceMessage($serviceMessageId: String!) {
  deleteServiceMessage(serviceMessageId: $serviceMessageId) {
    ...ServiceMessageDetails
  }
}
    ${ServiceMessageDetailsFragmentDoc}`;
export type DeleteServiceMessageMutationFn = Apollo.MutationFunction<DeleteServiceMessageMutation, DeleteServiceMessageMutationVariables>;

/**
 * __useDeleteServiceMessageMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMessageMutation, { data, loading, error }] = useDeleteServiceMessageMutation({
 *   variables: {
 *      serviceMessageId: // value for 'serviceMessageId'
 *   },
 * });
 */
export function useDeleteServiceMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceMessageMutation, DeleteServiceMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceMessageMutation, DeleteServiceMessageMutationVariables>(DeleteServiceMessageDocument, options);
      }
export type DeleteServiceMessageMutationHookResult = ReturnType<typeof useDeleteServiceMessageMutation>;
export type DeleteServiceMessageMutationResult = Apollo.MutationResult<DeleteServiceMessageMutation>;
export type DeleteServiceMessageMutationOptions = Apollo.BaseMutationOptions<DeleteServiceMessageMutation, DeleteServiceMessageMutationVariables>;