import { HTMLProps } from "react";
import styled, { css } from "styled-components";

import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RequiredAsterisk } from "components/atoms/Required";
import { BaseField } from "components/organisms/form/BaseField";
import { FormElementError, useForm } from "components/organisms/form/Form";
import { useRefState } from "hooks/useRefState";
import { getErrorForField } from "utils/FormValidator";

export interface TextFieldProps extends HTMLProps<HTMLInputElement> {
	index?: number; // Is used to locate where to place error message for groups of "fieldname[]" fields
	description?: string; // Small text under input field, for example hints
	inputRef?: React.MutableRefObject<any>;
	setValue?: (value: string) => void;
	onDelete?: () => void;
	formattedErrors?: { _errors: string[] }; // formErrors is the future, formattedErrors is legacy from useForm
	formErrors?: FormElementError<unknown>;
}
export function TextFieldClean({
	name,
	value,
	defaultValue,
	index,
	description,
	inputRef,
	onChange,
	setValue,
	onDelete,
	disabled,
	required,
	formattedErrors,
	formErrors,
	...props
}: TextFieldProps) {
	const errorsForField = formErrors ? getErrorForField(formErrors, name, index) : null;

	return (
		<BaseField errors={(formattedErrors as FormElementError<unknown>) || (errorsForField as FormElementError<unknown>)}>
			<label>
				{props.label}
				{required && <RequiredAsterisk />}
				<TextInputContentWrapper hasButton={!!onDelete}>
					<input
						id={"text-field-input"}
						{...props}
						ref={inputRef}
						disabled={disabled}
						required={required}
						value={value}
						defaultValue={defaultValue}
						name={name}
						onChange={(e) => {
							setValue?.(e.target.value);
							onChange?.(e);
						}}
					/>
					{onDelete && (
						<button title="Slett" className="noStyle" onClick={onDelete}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					)}
				</TextInputContentWrapper>
			</label>
			<Description>{description}</Description>
		</BaseField>
	);
}

export default function TextField({ ...props }: TextFieldProps) {
	const {
		errors,
		disabled: disabledState,
		defaultValue: formDefaultValue,
	} = useForm(props.name, {
		getValue: () => props.value || valueRef.current,
	});
	const [valueRef, setValue] = useRefState<string>((props.defaultValue as string) || formDefaultValue || "");

	return (
		<TextFieldClean
			{...props}
			setValue={setValue}
			disabled={props.disabled || disabledState}
			defaultValue={props.defaultValue || formDefaultValue}
			formattedErrors={errors}
		/>
	);
}

export const TextInputContentWrapper = styled.div<{ hasButton?: boolean }>`
	width: 100%;
	${(props) =>
		props.hasButton &&
		css`
			display: flex;
			border-radius: 5px;
			border: 1px solid ${(props) => props.theme.colors.formWrapperBorder};
			justify-content: center;

			label:first-child {
				width: 100%;
				margin-bottom: 0;

				#text-field-input,
				#text-field-input:focus {
					border: none;
					border: none;
				}
			}

			button {
				background: transparent;
				color: var(--nte-korall-enda-moerkere);
				border: none;
				padding: 0 1rem;
				font-size: 1rem;
				transition: none;
				&:focus {
					outline: solid 1px ${(props) => props.theme.colors.inputBorderFocus};
					outline-offset: -1px;
					box-shadow: none;
				}
			}

			input {
				border: none !important;
			}
		`}
`;
export const Description = styled.span`
	font-size: 0.875rem;
	color: var(--nte-graa-mork);
	line-height: 1.5;
`;
