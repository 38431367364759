import React from "react";

import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { Link, useParams } from "react-router-dom";
import { useGetFeatureFlagQuery } from "./SingleFeatureFlagsPage.graphql-gen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { Flex } from "components/atoms/Flex";

export default function SingleFeatureFlagPage() {
	const { featureId } = useParams<{ featureId: string }>();
	const { data, loading } = useGetFeatureFlagQuery({
		variables: { featureFlagId: featureId || "" },
	});

	return (
		<div>
			<MainLayoutPageHeader
				loading={loading}
				title={
					<Flex gap="var(--grid-2)">
						{data?.featureFlag?.displayName || "Feature"}
						<Link
							to="edit"
							title={`Rediger ${data?.featureFlag?.displayName || "Feature"}`}
						>
							<FontAwesomeIcon icon={faPencil} />
						</Link>
					</Flex>
				}
				intro={`${data?.featureFlag?.usersCount ?? 0} brukere har denne featuren`}
			/>
			<div style={{ marginTop: "40px" }}>
				<p>featuredata kommer her</p>
			</div>
		</div>
	);
}
