import { useEffect, useState } from "react";
import { Flex, Justify } from "components/atoms/Flex";
import { useAuth } from "react-oidc-context";

export default function LoginPage() {
	const auth = useAuth();
	const [authError, setAuthError] = useState("");

	useEffect(() => {
		if (auth.isAuthenticated) return;

		if (auth.isLoading) return;

		if (auth.error) {
			setAuthError(auth.error.message);
			return;
		}

		auth.signinRedirect({ redirect_uri: window.location.href });
	}, [auth.isAuthenticated, auth.isLoading]);

	return (
		<div>
			<Flex justifyContent={Justify.center}>
				{!authError && <h2>Logger deg inn...</h2>}

				{authError && (
					<div>
						<h2>Det skjedde en feil:</h2>
						<p>{authError}</p>
					</div>
				)}
			</Flex>
		</div>
	);
}
