// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserNotificationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
}>;


export type GetUserNotificationsQuery = { notifications?: { total?: any, items?: Array<{ notificationId?: string, title?: string, content?: string, sentAt?: string }> } };

export type SyncUsersMutationVariables = Types.Exact<{
  userIds: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SyncUsersMutation = { syncUsers?: Array<{ success: boolean, userId?: string }> };


export const GetUserNotificationsDocument = gql`
    query GetUserNotifications($userId: String!) {
  notifications(userId: $userId) {
    total
    items {
      notificationId
      title
      content
      sentAt
    }
  }
}
    `;

/**
 * __useGetUserNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
      }
export function useGetUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
        }
export type GetUserNotificationsQueryHookResult = ReturnType<typeof useGetUserNotificationsQuery>;
export type GetUserNotificationsLazyQueryHookResult = ReturnType<typeof useGetUserNotificationsLazyQuery>;
export type GetUserNotificationsQueryResult = Apollo.QueryResult<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>;
export const SyncUsersDocument = gql`
    mutation SyncUsers($userIds: [String]!) {
  syncUsers(userIds: $userIds) {
    success
    userId
  }
}
    `;
export type SyncUsersMutationFn = Apollo.MutationFunction<SyncUsersMutation, SyncUsersMutationVariables>;

/**
 * __useSyncUsersMutation__
 *
 * To run a mutation, you first call `useSyncUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUsersMutation, { data, loading, error }] = useSyncUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useSyncUsersMutation(baseOptions?: Apollo.MutationHookOptions<SyncUsersMutation, SyncUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncUsersMutation, SyncUsersMutationVariables>(SyncUsersDocument, options);
      }
export type SyncUsersMutationHookResult = ReturnType<typeof useSyncUsersMutation>;
export type SyncUsersMutationResult = Apollo.MutationResult<SyncUsersMutation>;
export type SyncUsersMutationOptions = Apollo.BaseMutationOptions<SyncUsersMutation, SyncUsersMutationVariables>;