import React from "react";
import styled from "styled-components";

import { MainLayoutPageHeader } from "components/organisms/MainLayout";

const Return = styled.a`
	cursor: pointer;
	text-align: center;
`;

const NotFound = () => {
	return (
		<div>
			<MainLayoutPageHeader
				title="404 - Side ikke funnet"
				intro="Beklager, denne siden har kommet bort."
			/>
			<div>
				<p className="align-center">
					<Return href="/">Gå til forsiden</Return>
				</p>
			</div>
		</div>
	);
};

export default NotFound;
