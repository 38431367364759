import { useNavigate, useParams } from "react-router-dom";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";

import { z } from "zod";
import { useGetIntercomSegmentQuery, useUpsertIntercomSegmentMutation } from "./EditIntercomSegment.graphql-gen";
import MultiSelectField from "components/organisms/form/MultiSelectField";
import { useSegmentsQuery } from "pages/Content/CreateContentPage.graphql-gen";
import { featureFlagLabelToGroup } from "pages/Groups/CreateGroupPage";
import styled from "styled-components";

type SelectOption = {
	label?: string;
	value?: string;
	group?: string;
};

export default function EditIntercomSegment() {
	const { intercomSegmentId } = useParams<{ intercomSegmentId: string }>();

	const navigate = useNavigate();

    const [upsertIntercomSegment, { loading: upsertLoading, error: upsertError }] = useUpsertIntercomSegmentMutation();

    const {data, error} = useGetIntercomSegmentQuery({
        variables: {
            id: intercomSegmentId || ""
        }
    });

    const { data: segmentsData } = useSegmentsQuery();

    const options =
    segmentsData?.segments?.map((s) => {
        return {
            ...s,
            group: featureFlagLabelToGroup(s.label ?? ""),
        };
    }) ?? [];

    const map = new Map<string, SelectOption[]>();

	options?.forEach((o) => {
		const groupName = o.group || "Ukategorisert";
		if (!map.has(groupName)) {
			map.set(groupName, []);
		}

		map.get(groupName)?.push(o);
	});

    const capitalizeFirstLetter = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

    if(!data?.singleLabelSegment) return <>Loading...</>;


	return (
		<div>
			<MainLayoutPageHeader title={data?.singleLabelSegment?.label} />
			<Flex gap="16px" style={{marginTop: "24px"}}>
				<form
					onSubmit={async (e) => {
                        e.preventDefault();
                        const form: any = e.target;

                        const SegmentIn: any = Array.from(form.segmentIn.options)
							.filter((option: any) => option.selected)
							.map((option: any) => option.value);

                        const SegmentNotIn: any = Array.from(form.segmentNotIn.options)
							.filter((option: any) => option.selected)
							.map((option: any) => option.value);

                        const response = await upsertIntercomSegment({variables: {
                            labelSegment: {
                                id: data?.singleLabelSegment?.id,
                                label: data?.singleLabelSegment?.label,
                                segmentIn: SegmentIn,
                                segmentNotIn: SegmentNotIn,
                            }
                        }});

                        if(response.data?.upsertLabelSegment?.id) {
                            navigate("/intercom-segments");
                        }

					}}
				>
                    <Flex gap="16px" direction={Direction.column}>
                        <Flex gap="16px">
                            <SegmentSelect
                                name="segmentIn"
                                multiple
                            >
                                {
                                <>
                                    {Array.from(map.keys()).map((key, index) => {
                                        return (
                                            <optgroup key={index} label={capitalizeFirstLetter(key.replace("_", " "))}>
                                                {map.get(key)?.map((o, index) => 
                                                    <option selected={data?.singleLabelSegment?.segmentIn?.includes(o?.value || "")} key={index + "a"} label={o.label} value={o.value}>
                                                        {o.value}
                                                    </option>
                                                )}
                                            </optgroup>
                                        );
                                    })}
                                </>
                                }
                            </SegmentSelect>
                        </Flex>
                        <Flex gap="16px">
                            <SegmentSelect
                                name="segmentNotIn"
                                multiple
                            >
                                {
                                <>
                                {Array.from(map.keys()).map((key, index) => {
                                    return (
                                        <optgroup key={index} label={capitalizeFirstLetter(key.replace("_", " "))}>
                                            {map.get(key)?.map((o, index) => (
                                                <option selected={data?.singleLabelSegment?.segmentNotIn?.includes(o?.value || "")} key={index + "a"} label={o.label} value={o.value}>
                                                    {o.value}
                                                </option>
                                            ))}
                                        </optgroup>
                                    );
                                })}
                            </>
                                }
                            </SegmentSelect>
                        </Flex>
		            </Flex>

                    {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
                    <Button style={{marginTop: "24px"}}>Oppdater intercom segment</Button>
                    
				</form>
			</Flex>
		</div>
	);
}

const SegmentSelect = styled.select`

`
