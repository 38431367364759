import { useNavigate } from "react-router-dom";

import { Flex } from "components/atoms/Flex";
import { InfoColumn, InfoColumnValue, InfoRow } from "components/atoms/InfoRow";
import { FeatureFlagDetailsFragment } from "pages/Features/SingleFeatureFlagsPage.graphql-gen";

export default function SingleGroupsPageFeaturesTab(props: { features: FeatureFlagDetailsFragment[] }) {
	const navigate = useNavigate();

	return (
		<Flex>
			{props.features.map((feature) => (
				<InfoRow
					key={feature.id}
					interactive
					onClick={() => {
						navigate("/features/" + feature.id + "/edit");
					}}
				>
					<InfoColumn width="25%">
						<InfoColumnValue>
							<b>{feature?.displayName}</b> ({feature?.usersCount} brukere)
						</InfoColumnValue>
					</InfoColumn>
				</InfoRow>
			))}
		</Flex>
	);
}
