import styled from "styled-components";

export default function Error({errorMessage}: {errorMessage: string}) {
    return (
        <ErrorWrapper>
            {errorMessage || "En feil skjedde"}
        </ErrorWrapper>
    );
}

const ErrorWrapper = styled.div`
    padding: 12px 24px;
    box-sizing: border-box;
    background-color: #ff7575;
    color: #860d0d;
    font-weight: 500;
`