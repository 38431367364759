import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";
import { z } from "zod";

import { faCheckCircle, faLoader, faTimesCircle, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex, Justify } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import SelectField from "components/organisms/form/SelectField";
import TextField from "components/organisms/form/TextField";
import ToggleField from "components/organisms/form/ToggleField";

import {
	GetDistributionModelSupportQuery,
	useCreateGridOwnerMappingMutation,
	useGetAvailableModelsQuery,
	useGetDistributionModelSupportLazyQuery,
} from "./CreateGridOwnerMappingPage.graphql-gen";

export default function CreateGridOwnerMappingPage() {
	const navigate = useNavigate();
	const { fraktalname } = useParams();

	const [createGridOwnerMapping, { loading, error }] = useCreateGridOwnerMappingMutation();
	const [getDistributionModelSupport, { loading: distributionModelSupportLoading }] = useGetDistributionModelSupportLazyQuery();
	const { data } = useGetAvailableModelsQuery();

	const [ecomonItem, setEcomonItem] = useState<GetDistributionModelSupportQuery["distributionModelSupport"] | null | undefined>(null);
	const setEcomonId = useDebouncedCallback(async (ecomonId: string) => {
		setEcomonItem(null);
		if (!ecomonId) return;

		const { data } = await getDistributionModelSupport({
			variables: {
				ecomonId,
			},
		});
		setEcomonItem(data?.distributionModelSupport);
	}, 300);

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<MainLayoutPageHeader title={<Flex gap="var(--grid-2)">Ny netteier</Flex>} />
			{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
			<Form
				loading={loading}
				schema={z.object({
					name: z.string(),
					ecomonId: z.string(),
					websiteURL: z.string(),
					syncronizeNow: z.boolean(),
				})}
				onSubmit={async ({ syncronizeNow, ...data }) => {
					const response = await createGridOwnerMapping({
						variables: {
							...data,
							syncronizeNow,
						},
					});

					if (response.data?.createGridOwnerMapping) {
						navigate("/grid-templates/");
					}
				}}
			>
				<Flex>
					<SelectField
						name="ecomonId"
						label="Ecomon ID"
						options={[
							{ label: "Velg Ecomon ID", value: "" },
							...(data?.availableModels.map((m) => {
								return { label: m, value: m };
							}) || []),
						]}
						onChange={(id) => setEcomonId(id)}
					/>
					<Flex
						style={{
							width: "50px",
							height: "50px",
							marginTop: "28px",
						}}
						alignItems={Justify.center}
					>
						{!distributionModelSupportLoading && (
							<>
								{(ecomonItem &&
									(ecomonItem.supported ? (
										<FontAwesomeIcon color="green" height="50px" width="50px" icon={faCheckCircle} />
									) : (
										<FontAwesomeIcon color="orange" height="50px" width="50px" icon={faWarning} />
									))) || <FontAwesomeIcon color="red" height="50px" width="50px" icon={faTimesCircle} />}
							</>
						)}

						{distributionModelSupportLoading && <FontAwesomeIcon spin height="50px" width="50px" icon={faLoader} />}
					</Flex>
				</Flex>

				<TextField name="name" label="Navn" value={fraktalname || undefined} />

				<StyledInfo gap="12px" alignItems={Justify.center}>
					<StyledInfoColumn>
						gln <br />
						<StyledInfoValue>{ecomonItem?.gln || "..."}</StyledInfoValue>
					</StyledInfoColumn>
					<StyledInfoColumn>
						Kapasitetsmodell <br />
						<StyledInfoValue>{ecomonItem?.capacityModelType || "..."}</StyledInfoValue>
					</StyledInfoColumn>
					<StyledInfoColumn>
						Energimodell <br />
						<StyledInfoValue>{ecomonItem?.energyModelType || "..."}</StyledInfoValue>
					</StyledInfoColumn>
				</StyledInfo>

				<TextField name="websiteURL" label="Hjemmeside" />

				<ToggleField name="syncronizeNow" label="Synkroniser mot ecomon?" />

				<Button>Lagre</Button>
			</Form>
		</Flex>
	);
}

export const StyledInfo = styled(Flex)`
	height: 50px;
	border-radius: 5px;
	border: 1px solid ${(props) => props.theme.colors.formWrapperBorder};
	padding: 15px;
	margin-top: -6px;
`;

export const StyledInfoColumn = styled.div`
	font-size: 12px;
	line-height: 1;
`;
export const StyledInfoValue = styled.div`
	font-size: 14px;
	font-weight: 600;
`;
