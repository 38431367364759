import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { z } from "zod";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import MultiSelectField from "components/organisms/form/MultiSelectField";
import TextField from "components/organisms/form/TextField";
import { useGetFeaturesFlagsQuery } from "pages/Features/FeatureFlagsPage.graphql-gen";

import ToggleField from "../../components/organisms/form/ToggleField";
import { useUpsertGroupMutation } from "./CreateGroupPage.graphql-gen";

export const GroupSchema = z.object({
	displayName: z.string().min(1),
	key: z.string().min(1),
	featureFlagKeys: z.array(z.string()),
	usersCanOptIn: z.boolean(),
});

export function featureFlagLabelToGroup(label: string) {
	if (label.startsWith("NO")) {
		return "No";
	} else if (label.startsWith("Klassifisering:")) {
		return "Klassifisering";
	} else if (label.startsWith("Gruppe:")) {
		return "Gruppe";
	} else if (label.startsWith("Laget mitt:")) {
		return "Laget mitt";
	} else if (label.startsWith("Produkt:")) {
		return "Produkt";
	} else {
		return "Misc";
	}
}

export default function CreateGroupPage() {
	const { data } = useGetFeaturesFlagsQuery();
	const navigate = useNavigate();
	const [upsertGroup, { loading, error }] = useUpsertGroupMutation();

	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<MainLayoutPageHeader title="Ny gruppe" intro="" />
			<Form
				loading={loading}
				onSubmit={async (group) => {
					const { data, errors } = await upsertGroup({
						variables: {
							group: {
								id: uuid(),
								...group,
							},
						},
					});

					!errors && navigate("/groups");
				}}
				schema={GroupSchema}
			>
				<Flex direction={Direction.column} gap="var(--grid-2)">
					<TextField label="Navn" name="displayName" placeholder="Ny gruppe" />
					<TextField label="Key" name="key" placeholder="nyGruppe" />
					<ToggleField label="Users can opt in" name="usersCanOptIn" />

					<MultiSelectField
						label="Feature flags"
						name="featureFlagKeys"
						options={data?.featureFlags
							?.filter((featureFlag) => !featureFlag.global)
							?.map((featureFlag) => ({
								label: featureFlag.displayName,
								value: featureFlag.key,
								group: featureFlagLabelToGroup(featureFlag.displayName),
							}))}
					/>
					{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
					<Button>Legg til gruppe</Button>
				</Flex>
			</Form>
		</Flex>
	);
}
