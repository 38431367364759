// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInfoScreenDataQueryVariables = Types.Exact<{
  token?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetInfoScreenDataQuery = { infoScreenData?: { currentActiveUsers: any, activeUsersLast1Day: any, activeUsersLast7Days: any, activeUsersLast30Days: any, popularPages?: Array<{ key?: string, value: number }>, totalSales?: Array<{ key?: string, value: number }>, contentCards?: Array<{ id?: string, title?: string, publishDate?: string, content?: string, link?: { url?: string, view?: string, type?: Types.ContentLinkType }, image?: { url?: string } }> } };


export const GetInfoScreenDataDocument = gql`
    query getInfoScreenData($token: String) {
  infoScreenData(token: $token) {
    popularPages {
      key
      value
    }
    totalSales {
      key
      value
    }
    contentCards {
      link {
        url
        view
        type
      }
      image {
        url
      }
      id
      title
      publishDate
      content
    }
    currentActiveUsers
    activeUsersLast1Day
    activeUsersLast7Days
    activeUsersLast30Days
  }
}
    `;

/**
 * __useGetInfoScreenDataQuery__
 *
 * To run a query within a React component, call `useGetInfoScreenDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoScreenDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoScreenDataQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetInfoScreenDataQuery(baseOptions?: Apollo.QueryHookOptions<GetInfoScreenDataQuery, GetInfoScreenDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoScreenDataQuery, GetInfoScreenDataQueryVariables>(GetInfoScreenDataDocument, options);
      }
export function useGetInfoScreenDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoScreenDataQuery, GetInfoScreenDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoScreenDataQuery, GetInfoScreenDataQueryVariables>(GetInfoScreenDataDocument, options);
        }
export type GetInfoScreenDataQueryHookResult = ReturnType<typeof useGetInfoScreenDataQuery>;
export type GetInfoScreenDataLazyQueryHookResult = ReturnType<typeof useGetInfoScreenDataLazyQuery>;
export type GetInfoScreenDataQueryResult = Apollo.QueryResult<GetInfoScreenDataQuery, GetInfoScreenDataQueryVariables>;