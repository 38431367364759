// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInfoScreenDataWithNteNoQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type GetInfoScreenDataWithNteNoQuery = { infoScreenData?: { usersTodayNTENo: any, conversionsLast30DaysNTENo: any, activeUsersLast30DaysNTENo: any, activeUsersLast1Day: any, activeUsersLast7Days: any, activeUsersLast30Days: any, currentActiveUsers: any, popularPagesNTENo?: Array<{ key?: string, value: number }>, conversionsNTENo?: Array<{ key?: string, value: number }>, popularPages?: Array<{ key?: string, value: number }>, totalSales?: Array<{ key?: string, value: number }>, contentCards?: Array<{ id?: string, title?: string, publishDate?: string, content?: string, link?: { url?: string, view?: string, type?: Types.ContentLinkType }, image?: { url?: string } }> } };


export const GetInfoScreenDataWithNteNoDocument = gql`
    query getInfoScreenDataWithNTENo($token: String!) {
  infoScreenData(token: $token) {
    usersTodayNTENo
    popularPagesNTENo {
      key
      value
    }
    conversionsLast30DaysNTENo
    conversionsNTENo {
      key
      value
    }
    activeUsersLast30DaysNTENo
    popularPages {
      key
      value
    }
    totalSales {
      key
      value
    }
    activeUsersLast1Day
    activeUsersLast7Days
    activeUsersLast30Days
    currentActiveUsers
    contentCards {
      link {
        url
        view
        type
      }
      image {
        url
      }
      id
      title
      publishDate
      content
    }
  }
}
    `;

/**
 * __useGetInfoScreenDataWithNteNoQuery__
 *
 * To run a query within a React component, call `useGetInfoScreenDataWithNteNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoScreenDataWithNteNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoScreenDataWithNteNoQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetInfoScreenDataWithNteNoQuery(baseOptions: Apollo.QueryHookOptions<GetInfoScreenDataWithNteNoQuery, GetInfoScreenDataWithNteNoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoScreenDataWithNteNoQuery, GetInfoScreenDataWithNteNoQueryVariables>(GetInfoScreenDataWithNteNoDocument, options);
      }
export function useGetInfoScreenDataWithNteNoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoScreenDataWithNteNoQuery, GetInfoScreenDataWithNteNoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoScreenDataWithNteNoQuery, GetInfoScreenDataWithNteNoQueryVariables>(GetInfoScreenDataWithNteNoDocument, options);
        }
export type GetInfoScreenDataWithNteNoQueryHookResult = ReturnType<typeof useGetInfoScreenDataWithNteNoQuery>;
export type GetInfoScreenDataWithNteNoLazyQueryHookResult = ReturnType<typeof useGetInfoScreenDataWithNteNoLazyQuery>;
export type GetInfoScreenDataWithNteNoQueryResult = Apollo.QueryResult<GetInfoScreenDataWithNteNoQuery, GetInfoScreenDataWithNteNoQueryVariables>;