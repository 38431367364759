import { useNavigate } from "react-router-dom";

import { faCakeCandles, faHashtag, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { InfoRow, InfoColumn } from "components/atoms/InfoRow";
import useDateTime from "hooks/useDateTime";
import { InfoColumnLabel, InfoColumnValue } from "pages/Users/EditUserPage";

import { User } from "generated/graphql-types";

export default function UserRow({ user }: { user: User }) {
	const date = useDateTime();
	const navigate = useNavigate();

	return (
		<InfoRow
			interactive
			onClick={() => {
				if (user?.userId) navigate("/users/" + user?.userId);
			}}
		>
			<InfoColumn width="60%">
				<InfoColumnLabel>{user?.name || <u>Åpen bruker</u>}</InfoColumnLabel>
			</InfoColumn>
			<InfoColumn title="Kundenummer" width="15%">
				<InfoColumnValue>
					<FontAwesomeIcon icon={faHashtag} /> {(!!user?.customerIds?.length && user.customerIds[0]) || "―"}
				</InfoColumnValue>
			</InfoColumn>
			<InfoColumn title="Telefonnummer" width="15%">
				<InfoColumnValue>
					<FontAwesomeIcon icon={faPhone} /> {user?.mobileNumber || "―"}
				</InfoColumnValue>
			</InfoColumn>
			<InfoColumn title="Bursdag" width="10%">
				<InfoColumnValue>
					<FontAwesomeIcon icon={faCakeCandles} /> {date.format(user?.birthDate, "P")}
				</InfoColumnValue>
			</InfoColumn>
		</InfoRow>
	);
}
