import { ReactNode } from "react";
import styled from "styled-components";

import { FormElementError } from "components/organisms/form/Form";

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;

export const ErrorWrapper = styled.div`
	color: var(--nte-korall-enda-moerkere);
`;

export const SuccessWrapper = styled.div`
	color: var(--nte-groenn-moerk);
`;

const StyledError = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

const ErrorLine = styled.li``;

export function FormError<T>({ errors }: { errors: FormElementError<T> }) {
	return (
		<ErrorWrapper>
			<StyledError>
				{errors?._errors.map((error, i) => (
					<ErrorLine key={i}>{error}</ErrorLine>
				))}
			</StyledError>
		</ErrorWrapper>
	);
}

interface BaseFieldProps {
	children: ReactNode;
	errors?: FormElementError<unknown>;
}

export function BaseField({ children, errors }: BaseFieldProps) {
	return (
		<Wrapper>
			{children}
			{errors && <FormError errors={errors} />}
		</Wrapper>
	);
}
