import styled from "styled-components";

export enum Justify {
	start = "start",
	end = "end",
	flexStart = "flex-start",
	flexEnd = "flex-end",
	center = "center",
	left = "left",
	right = "right",
	normal = "normal",
	baseline = "baseline",
	spaceBetween = "space-between",
	spaceAround = "space-around",
	spaceEvenly = "space-evenly",
	stretch = "stretch",
}

export enum Direction {
	row = "row",
	rowReverse = "row-reverse",
	column = "column",
	columnReverse = "column-reverse",
}

export enum Wrap {
	wrap = "wrap",
	nowrap = "nowrap",
	wrapReverse = "wrap-reverse",
}

type FlexBaseProps = {
	justifyContent?: Justify;
	alignContent?: Justify;
	alignItems?: Justify;
	direction?: Direction;
	gap?: string;
	wrap?: Wrap;
	grow?: any;
	shrink?: any;
};

type FlexProps = FlexBaseProps & {
	mobile?: FlexBaseProps;
	tablet?: FlexBaseProps;
	desktop?: FlexBaseProps;
};

const generateStyles = (props: FlexBaseProps) => {
	if (!props) {
		return;
	}
	return `
		${props.direction ? `flex-direction: ${props.direction};` : ``};
		${props.justifyContent ? `justify-content: ${props.justifyContent};` : ``};
		${props.alignContent ? `align-content: ${props.alignContent};` : ``};
		${props.alignItems ? `align-items: ${props.alignItems};` : ``};
		${props.gap ? `gap: ${props.gap};` : ``};
		${props.wrap ? `flex-wrap: ${props.wrap};` : ``};
		${props.grow ? `flex-grow: ${props.grow};` : ``};
		${props.shrink ? `flex-shrink: ${props.shrink};` : ``};
	`;
};
export const Flex = styled.div<FlexProps>`
	display: flex;
	${(props) => generateStyles(props)}
`;

export const FlexFullWidth = styled.div<FlexProps>`
	display: flex;
	width: 100%;
	min-height: 100vh;
	${(props) => generateStyles(props)}
`;
