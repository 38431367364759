import { motion } from "framer-motion";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { faChevronUp, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccordionWrapper = styled.section`
	box-sizing: border-box;
	background-color: #f7f7f7;
	border-radius: 5px;
	width: 100%;
	margin: 0.5em auto;
	padding: 0.5em;
`;

const AccordionHeader = styled.div`
	box-sizing: border-box;
	position: relative;
	width: 100%;
	padding: 0 0.5em;
	cursor: pointer;
`;

const AccordionContent = styled(motion.div)`
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 5px;
	overflow-y: hidden;
	padding: 0;
	margin: 0;
	height: 0;
`;

const AccordionIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 1em;
	top: calc(50% - 8px);
`;

const contentVariants = {
	open: { height: "auto", padding: "0 0.5em", marginTop: "0.5em" },
	closed: {},
};

export default function Accordion({ children, header, loading }: { children?: JSX.Element; header?: JSX.Element; loading?: boolean }) {
	const [open, setOpen] = useState<boolean>(false);
	const containsElements = (children: JSX.Element | undefined) => {
		const c = children?.props?.children;
		if (!c || c === false || (c.length && c.length === 0)) return false;
		return true;
	};

	return (
		<AccordionWrapper>
			{loading ? (
				<AccordionHeader>
					<Skeleton />
				</AccordionHeader>
			) : (
				<AccordionHeader onClick={() => (containsElements(children) ? setOpen(!open) : null)}>
					{header}
					{containsElements(children) ? <AccordionIcon icon={open ? faChevronUp : faChevronDown} /> : null}
				</AccordionHeader>
			)}
			<AccordionContent animate={open ? "open" : "closed"} variants={contentVariants}>
				{children}
			</AccordionContent>
		</AccordionWrapper>
	);
}
