import { Link } from "react-router-dom";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import Tabs from "components/organisms/Tabs";
import useAccess from "hooks/useAccess";
import GridOwnerMappingsPageMappedTab from "pages/GridOwnerMapping/GridOwnerMappingsPage/GridOwnerMappingsPageMappedTab";
import GridOwnerMappingsPageUnmappedTab from "pages/GridOwnerMapping/GridOwnerMappingsPage/GridOwnerMappingsPageUnmappedTab";

export default function GridOwnerMappingsPage() {
	const access = useAccess();

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<MainLayoutPageHeader
				title={
					<Flex gap="var(--grid-2)">
						Netteiere
						{access.includes("Admin") && (
							<Link to="create" title="Ny netteier">
								<FontAwesomeIcon icon={faPlusCircle} />
							</Link>
						)}
					</Flex>
				}
			/>
			<Tabs
				tabs={[
					{
						label: "Sammenkoblet",
						name: "mapped",
						renderer: () => <GridOwnerMappingsPageMappedTab />,
					},
					{
						label: "Ikke sammenkoblet",
						name: "unmapped",
						renderer: () => <GridOwnerMappingsPageUnmappedTab />,
					},
				]}
			/>
		</Flex>
	);
}
