import { format } from "date-fns";
import { nb } from "date-fns/locale";
import styled from "styled-components";

import { Direction, Flex } from "../atoms/Flex";

const FeedPreviewWrapper = styled(Flex)`
	padding: 17px;
	box-sizing: border-box;
	background: #081e29;
	color: white;
	border-radius: 16px;
	box-shadow: 0px 4px 10px rgba(5, 113, 168, 0.06);
	overflow: hidden;
`;
const FeedPreviewDate = styled.span`
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.3;
	letter-spacing: 0.005em;
`;
const FeedPreviewTitle = styled.span`
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.1875rem;
`;
const FeedPreviewContent = styled.span`
	font-weight: normal;
	font-size: 0.875rem;
	line-height: 1.5;
`;
const FeedPreviewImage = styled.div<{ image: string }>`
	background-image: url(${(p: any) => p.image});
	width: calc(100% + 34px);
	height: 180px;
	margin: -17px -17px 17px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

interface FeedPreviewProps {
	title: string;
	content: string;
	imageUrl?: string;
	date?: Date | string;
}

export default function FeedPreview(props: FeedPreviewProps) {
	return (
		<FeedPreviewWrapper direction={Direction.column}>
			{props.imageUrl && <FeedPreviewImage image={props.imageUrl} />}
			<FeedPreviewDate>
				{props.date &&
					format(new Date(props.date), "dd. MMMM yyyy", {
						locale: nb,
					})}{" "}
			</FeedPreviewDate>
			<FeedPreviewTitle>{props.title}</FeedPreviewTitle>
			<FeedPreviewContent>{props.content}</FeedPreviewContent>
		</FeedPreviewWrapper>
	);
}
