// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StatisticsPageDataQueryVariables = Types.Exact<{
  oneMonthAgo: Types.Scalars['DateTime'];
  oneWeekAgo: Types.Scalars['DateTime'];
  oneHourAgo: Types.Scalars['DateTime'];
}>;


export type StatisticsPageDataQuery = { statistics?: { hasVarsel: number, totalVarsel: number, withoutVarsel: number, haveHadVarsel: number }, notifications?: { total?: any }, notificationErrors?: { total?: any }, notificationErrorsHandled?: { total?: any }, users?: { total?: any }, activeLastMonth?: { total?: any }, iphonesActiveLastMonth?: { total?: any }, androidsActiveLastMonth?: { total?: any }, activeLastWeek?: { total?: any }, iphonesActiveLastWeek?: { total?: any }, androidsActiveLastWeek?: { total?: any }, activeLastHour?: { total?: any }, iphonesActiveLastHour?: { total?: any }, androidsActiveLastHour?: { total?: any } };

export type TotalActiveContractsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TotalActiveContractsQuery = { totalActiveContracts?: number };

export type GroupedMeteringValuesDetailsFragment = { time?: string, meteringValues?: number, cashFlowFiles?: number };

export type MeteringValuesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeteringValuesQuery = { meteringValueFileStats?: Array<{ time?: string, meteringValues?: number, cashFlowFiles?: number }> };

export const GroupedMeteringValuesDetailsFragmentDoc = gql`
    fragment GroupedMeteringValuesDetails on GroupedMeteringValue {
  time
  meteringValues
  cashFlowFiles
}
    `;
export const StatisticsPageDataDocument = gql`
    query statisticsPageData($oneMonthAgo: DateTime!, $oneWeekAgo: DateTime!, $oneHourAgo: DateTime!) {
  statistics {
    hasVarsel
    totalVarsel
    withoutVarsel
    haveHadVarsel
  }
  notifications {
    total
  }
  notificationErrors: notifications(pushStatus: SENDING_ERROR) {
    total
  }
  notificationErrorsHandled: notifications(
    pushStatus: SENDING_ERROR_CAUSE_HANDLED
  ) {
    total
  }
  users: paginatedUsers {
    total
  }
  activeLastMonth: paginatedUsers(deviceLastSeenAfter: $oneMonthAgo) {
    total
  }
  iphonesActiveLastMonth: paginatedUsers(
    devicePlatform: "ios"
    deviceLastSeenAfter: $oneMonthAgo
  ) {
    total
  }
  androidsActiveLastMonth: paginatedUsers(
    devicePlatform: "android"
    deviceLastSeenAfter: $oneMonthAgo
  ) {
    total
  }
  activeLastWeek: paginatedUsers(deviceLastSeenAfter: $oneWeekAgo) {
    total
  }
  iphonesActiveLastWeek: paginatedUsers(
    devicePlatform: "ios"
    deviceLastSeenAfter: $oneWeekAgo
  ) {
    total
  }
  androidsActiveLastWeek: paginatedUsers(
    devicePlatform: "android"
    deviceLastSeenAfter: $oneWeekAgo
  ) {
    total
  }
  activeLastHour: paginatedUsers(deviceLastSeenAfter: $oneHourAgo) {
    total
  }
  iphonesActiveLastHour: paginatedUsers(
    devicePlatform: "ios"
    deviceLastSeenAfter: $oneHourAgo
  ) {
    total
  }
  androidsActiveLastHour: paginatedUsers(
    devicePlatform: "android"
    deviceLastSeenAfter: $oneHourAgo
  ) {
    total
  }
}
    `;

/**
 * __useStatisticsPageDataQuery__
 *
 * To run a query within a React component, call `useStatisticsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsPageDataQuery({
 *   variables: {
 *      oneMonthAgo: // value for 'oneMonthAgo'
 *      oneWeekAgo: // value for 'oneWeekAgo'
 *      oneHourAgo: // value for 'oneHourAgo'
 *   },
 * });
 */
export function useStatisticsPageDataQuery(baseOptions: Apollo.QueryHookOptions<StatisticsPageDataQuery, StatisticsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatisticsPageDataQuery, StatisticsPageDataQueryVariables>(StatisticsPageDataDocument, options);
      }
export function useStatisticsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatisticsPageDataQuery, StatisticsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatisticsPageDataQuery, StatisticsPageDataQueryVariables>(StatisticsPageDataDocument, options);
        }
export type StatisticsPageDataQueryHookResult = ReturnType<typeof useStatisticsPageDataQuery>;
export type StatisticsPageDataLazyQueryHookResult = ReturnType<typeof useStatisticsPageDataLazyQuery>;
export type StatisticsPageDataQueryResult = Apollo.QueryResult<StatisticsPageDataQuery, StatisticsPageDataQueryVariables>;
export const TotalActiveContractsDocument = gql`
    query TotalActiveContracts {
  totalActiveContracts
}
    `;

/**
 * __useTotalActiveContractsQuery__
 *
 * To run a query within a React component, call `useTotalActiveContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalActiveContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalActiveContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalActiveContractsQuery(baseOptions?: Apollo.QueryHookOptions<TotalActiveContractsQuery, TotalActiveContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalActiveContractsQuery, TotalActiveContractsQueryVariables>(TotalActiveContractsDocument, options);
      }
export function useTotalActiveContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalActiveContractsQuery, TotalActiveContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalActiveContractsQuery, TotalActiveContractsQueryVariables>(TotalActiveContractsDocument, options);
        }
export type TotalActiveContractsQueryHookResult = ReturnType<typeof useTotalActiveContractsQuery>;
export type TotalActiveContractsLazyQueryHookResult = ReturnType<typeof useTotalActiveContractsLazyQuery>;
export type TotalActiveContractsQueryResult = Apollo.QueryResult<TotalActiveContractsQuery, TotalActiveContractsQueryVariables>;
export const MeteringValuesDocument = gql`
    query MeteringValues {
  meteringValueFileStats {
    ...GroupedMeteringValuesDetails
  }
}
    ${GroupedMeteringValuesDetailsFragmentDoc}`;

/**
 * __useMeteringValuesQuery__
 *
 * To run a query within a React component, call `useMeteringValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeteringValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeteringValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeteringValuesQuery(baseOptions?: Apollo.QueryHookOptions<MeteringValuesQuery, MeteringValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeteringValuesQuery, MeteringValuesQueryVariables>(MeteringValuesDocument, options);
      }
export function useMeteringValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeteringValuesQuery, MeteringValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeteringValuesQuery, MeteringValuesQueryVariables>(MeteringValuesDocument, options);
        }
export type MeteringValuesQueryHookResult = ReturnType<typeof useMeteringValuesQuery>;
export type MeteringValuesLazyQueryHookResult = ReturnType<typeof useMeteringValuesLazyQuery>;
export type MeteringValuesQueryResult = Apollo.QueryResult<MeteringValuesQuery, MeteringValuesQueryVariables>;