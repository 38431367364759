import dot from "dot-object";
import { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import z, { SafeParseError, SafeParseSuccess, ZodFormattedError } from "zod";

import { getDataFromSubmit } from "utils/FormValidator";

const StyledForm = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

interface FormElement {
	getValue?: (value?: any) => any;
}

export type FormElementError<T> =
	| (ZodFormattedError<T> & {
			[key: string]: FormElementError<T>;
	  })
	| null
	| undefined;

interface FormContextValue {
	elements: { [key: string]: FormElement };
	errors: FormElementError<unknown>;
	values?: Partial<z.output<any>>;
	rawValue?: Partial<z.output<any>>;
	register: (name: string, element: FormElement) => void;
	deRegister: (name: string) => void;
	loading?: boolean;
	disabled?: boolean;
}

export const formContext = createContext<FormContextValue>({} as FormContextValue);

export function useForm(name?: string, element: FormElement = {}) {
	const context = useContext(formContext);
	const defaultValue = typeof name !== "undefined" ? context?.values?.[name] || context?.rawValue?.[name] : undefined;

	useEffect(() => {
		if (!name) return;

		const newName = name;
		context.register(newName, element);
		return () => {
			context.deRegister(newName);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name]);

	return {
		errors: context.errors?.[name!] || { _errors: [] },
		loading: context.loading,
		disabled: context.disabled,
		defaultValue,
	};
}

export function useFormValue(name: string) {
	const context = useContext(formContext);
	const defaultValue = typeof name !== "undefined" ? context?.values?.[name] || context?.rawValue?.[name] : undefined;

	return defaultValue;
}

interface FormProps<T extends z.ZodTypeAny> {
	children?: any;
	schema?: T;
	loading?: boolean;
	disabled?: boolean;
	value?: Partial<z.output<T>>;
	onSubmit?: (data: Required<z.output<T>>) => void;
	onError?: (error: ZodFormattedError<T>) => void;
	onChange?: (e: React.FormEvent<HTMLFormElement>) => void;
}

export default function Form<T extends z.ZodTypeAny>({ children, schema, value, loading, disabled, onSubmit, onError, ...props }: FormProps<T>) {
	const [elements, setElements] = useState<{ [key: string]: FormElement }>({});
	const [errors, setErrors] = useState<FormElementError<T>>(null);

	return (
		<formContext.Provider
			value={{
				disabled: disabled || loading,
				loading,
				errors,
				values: (value && dot.dot(value)) || value,
				rawValue: value,
				elements,
				register: (name, formElement) => {
					setElements((prev) => {
						return {
							...prev,
							[name]: formElement,
						};
					});
				},
				deRegister: (name) => {
					setElements((prev) => {
						delete prev[name];

						return { ...prev };
					});
				},
			}}
		>
			<StyledForm
				onSubmit={(e) => {
					e.preventDefault();

					if (!schema) return;

					// TODO: Needs to be tested more. const parsed = getDataFromSubmit(e.target, schema as any);

					const formElement = e.target as HTMLFormElement;
					const rawValues: Partial<z.output<T>> = {};

					Object.keys(elements).forEach((key: keyof z.output<T>) => {
						const inputElement = formElement[key as keyof EventTarget] as unknown as HTMLInputElement;
						rawValues[key] = elements[key as keyof EventTarget]?.getValue?.(inputElement?.value) ?? inputElement?.value ?? undefined;
					});

					const values = dot.object(rawValues);

					const parsed = schema.safeParse(values) as SafeParseSuccess<T> | SafeParseError<T>;

					if (parsed.success) {
						setErrors(null);

						return onSubmit?.(parsed.data as Required<z.output<T>>);
					}

					const parsedErrors = (parsed as SafeParseError<T>).error.format();
					setErrors(parsedErrors as FormElementError<T>);
					onError?.(parsedErrors);
					console.error(parsedErrors);
				}}
				{...props}
			>
				{children}
			</StyledForm>
		</formContext.Provider>
	);
}
