import { ReactNode } from "react";
import styled from "styled-components";

import { Flex, Justify } from "components/atoms/Flex";

const ModalWrapper = styled(Flex)`
	z-index: 9999;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const ModalBackdrop = styled.div`
	background: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const ModalContent = styled.div`
	background: white;
	z-index: 999;
	max-height: calc(100vh - 42px);
	overflow: auto;
`;

export default function Modal(props: { children: ReactNode; style?: any; onClose?: () => void }) {
	return (
		<ModalWrapper style={props.style} justifyContent={Justify.center} alignItems={Justify.center}>
			<ModalContent>{props.children}</ModalContent>
			<ModalBackdrop onClick={props.onClose} />
		</ModalWrapper>
	);
}
