import { HTMLProps } from "react";

import { BaseField } from "components/organisms/form/BaseField";
import { FormElementError, useForm } from "components/organisms/form/Form";
import { Description } from "components/organisms/form/TextField";
import { useRefState } from "hooks/useRefState";
import { getErrorForField } from "utils/FormValidator";

export type TextareaFieldProps = HTMLProps<HTMLTextAreaElement> & {
	inputRef?: React.MutableRefObject<any>;
	description?: string; // Small text under input field, for example hints
	setValue?: (value: string) => void;
	formattedErrors?: { _errors: string[] }; // formErrors is the future, formattedErrors is legacy from useForm
	formErrors?: FormElementError<unknown>;
};
export function TextareaFieldClean({ name, disabled, inputRef, description, onChange, setValue, formattedErrors, formErrors, ...props }: TextareaFieldProps) {
	const errorsForField = formErrors ? getErrorForField(formErrors, name) : null;

	return (
		<BaseField errors={(formattedErrors as FormElementError<unknown>) || (errorsForField as FormElementError<unknown>)}>
			<label>
				{props.label}
				<textarea
					{...props}
					ref={inputRef}
					name={name}
					onChange={(e) => {
						setValue?.(e.target.value);
						onChange?.(e);
					}}
				/>
			</label>
			<Description>{description}</Description>
		</BaseField>
	);
}
export default function TextareaField({ ...props }: TextareaFieldProps) {
	const {
		errors,
		disabled: disabledState,
		defaultValue: formDefaultValue,
	} = useForm(props.name, {
		getValue: () => props.value || valueRef.current,
	});
	const [valueRef, setValue] = useRefState<string>((props.defaultValue as string) || formDefaultValue || "");

	return (
		<TextareaFieldClean
			{...props}
			setValue={setValue}
			disabled={props.disabled || disabledState}
			defaultValue={props.defaultValue || formDefaultValue}
			formattedErrors={errors}
		/>
	);
}
