// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGridOwnerMappingQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetGridOwnerMappingQuery = { gridOwnerMapping?: { id: string, name: string, ecomonId: string, websiteURL: string, mergedCompanies?: Array<string>, numberOfUsers?: any } };

export type UpdateGridOwnerMappingMutationVariables = Types.Exact<{
  mapping: Types.GridOwnerMappingInput;
  syncronizeNow: Types.Scalars['Boolean'];
}>;


export type UpdateGridOwnerMappingMutation = { updateGridOwnerMapping: { id: string, name: string, ecomonId: string, websiteURL: string, mergedCompanies?: Array<string>, numberOfUsers?: any } };

export type GridOwnerMappingDetailsFragment = { id: string, name: string, ecomonId: string, websiteURL: string, mergedCompanies?: Array<string>, numberOfUsers?: any };

export const GridOwnerMappingDetailsFragmentDoc = gql`
    fragment GridOwnerMappingDetails on GridOwnerMapping {
  id
  name
  ecomonId
  websiteURL
  mergedCompanies
  numberOfUsers
}
    `;
export const GetGridOwnerMappingDocument = gql`
    query GetGridOwnerMapping($id: String!) {
  gridOwnerMapping(id: $id) {
    ...GridOwnerMappingDetails
  }
}
    ${GridOwnerMappingDetailsFragmentDoc}`;

/**
 * __useGetGridOwnerMappingQuery__
 *
 * To run a query within a React component, call `useGetGridOwnerMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGridOwnerMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGridOwnerMappingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGridOwnerMappingQuery(baseOptions: Apollo.QueryHookOptions<GetGridOwnerMappingQuery, GetGridOwnerMappingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGridOwnerMappingQuery, GetGridOwnerMappingQueryVariables>(GetGridOwnerMappingDocument, options);
      }
export function useGetGridOwnerMappingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGridOwnerMappingQuery, GetGridOwnerMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGridOwnerMappingQuery, GetGridOwnerMappingQueryVariables>(GetGridOwnerMappingDocument, options);
        }
export type GetGridOwnerMappingQueryHookResult = ReturnType<typeof useGetGridOwnerMappingQuery>;
export type GetGridOwnerMappingLazyQueryHookResult = ReturnType<typeof useGetGridOwnerMappingLazyQuery>;
export type GetGridOwnerMappingQueryResult = Apollo.QueryResult<GetGridOwnerMappingQuery, GetGridOwnerMappingQueryVariables>;
export const UpdateGridOwnerMappingDocument = gql`
    mutation UpdateGridOwnerMapping($mapping: GridOwnerMappingInput!, $syncronizeNow: Boolean!) {
  updateGridOwnerMapping(mapping: $mapping, syncronizeNow: $syncronizeNow) {
    ...GridOwnerMappingDetails
  }
}
    ${GridOwnerMappingDetailsFragmentDoc}`;
export type UpdateGridOwnerMappingMutationFn = Apollo.MutationFunction<UpdateGridOwnerMappingMutation, UpdateGridOwnerMappingMutationVariables>;

/**
 * __useUpdateGridOwnerMappingMutation__
 *
 * To run a mutation, you first call `useUpdateGridOwnerMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGridOwnerMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGridOwnerMappingMutation, { data, loading, error }] = useUpdateGridOwnerMappingMutation({
 *   variables: {
 *      mapping: // value for 'mapping'
 *      syncronizeNow: // value for 'syncronizeNow'
 *   },
 * });
 */
export function useUpdateGridOwnerMappingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGridOwnerMappingMutation, UpdateGridOwnerMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGridOwnerMappingMutation, UpdateGridOwnerMappingMutationVariables>(UpdateGridOwnerMappingDocument, options);
      }
export type UpdateGridOwnerMappingMutationHookResult = ReturnType<typeof useUpdateGridOwnerMappingMutation>;
export type UpdateGridOwnerMappingMutationResult = Apollo.MutationResult<UpdateGridOwnerMappingMutation>;
export type UpdateGridOwnerMappingMutationOptions = Apollo.BaseMutationOptions<UpdateGridOwnerMappingMutation, UpdateGridOwnerMappingMutationVariables>;