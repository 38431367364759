import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`
	*, html {
		box-sizing: border-box;
	}

    html, body {
		--grid: 8px;
		--grid-2: calc(var(--grid) * 2);
		--grid-4: calc(var(--grid) * 4);
		--max-textbox-width: 700px;
		--sidebar-width: 300px;
		--layout-maxwidth: 1200px;
		--header-height: 86px;
		--logo-width: 70px;
		--varsel-groenn: #007515;
		--varsel-lys-groenn: #D4E8D7;
		--varsel-gul: #8A6402;
		--varsel-lys-gul: #FFF3AE;
		--varsel-orange: #FFBA53;
		--varsel-roed: #AD0000;
		--varsel-roed-10: #AD00001A;
		--fjutt-tekst: #464354;
		--fjutt-lys: #DEEDF0;
		--fjutt-travle-trine: #E9F3E9;
		--fjutt-roed: #FFE5E2;
		--fjutt-groenn: #70C48E;
		--fjutt-groenn-lys: #85C293;
		--fjutt-sort: #0C4763;
		--nte-blaa: #0089C4;
		--nte-blaa-himmel: #F0F8FC;
		--nte-blaa-10: #E5F3F9;
		--nte-blaa-20: #CCE7F3;
		--nte-blaa-50: #80C4E1;
		--nte-blaa-300: #CEECF9;
		--nte-blaa-lys: #14AFDD;
		--nte-blaa-logo: #0079ad;
		--nte-blaa-uu: #0571A8;
		--nte-blaa-mork: #094462;
		--nte-korall: #F7C2B5;
		--nte-korall-moerkere: #F29780;
		--nte-korall-morkest: #EC512B;
		--nte-korall-enda-moerkere: #B22727;
		--nte-groenn: #7DC189;
		--nte-groenn-20: #E8F3E8;
		--nte-groenn-moerk: #397F57;
		--nte-graa-lys: #EDEDED;
		--nte-graa-lys-50: #F7F7F7;
		--nte-graa-medium: #71767E;
		--nte-graa-uu: #757575;
		--nte-graa-mork: #505359;
		--nte-sort: #28292C;
        --nte-border-color: rgba(112, 124, 151, 0.25);
		--nte-border: 1px solid var(--nte-border-color);
		--white: white;
		--graa-bg-lys: #F8F8F8;
		--graa-bg: #f5f5f5;
		--overlay-gray: rgba(40, 41, 44, 0.21);
		--overlay-white: rgba(255, 255, 255, 0.5);
		--clickable-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
		--clickable-box-shadow-float: 0px 4px 10px rgba(20, 106, 255, 0.06);
		--clickable-box-shadow-app:  0px 4px 10px rgba(29, 30, 31, 0.06);//0px 4px 10px rgba(5, 113, 168, 0.06);
		--border-radius-large: 16px;
		--border-radius: 9px;
		--border-radius-small: 6px;
		--border-radius-smallest: 3px;


		min-height: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
        font-size: ${(props) => props.theme.typography.body.fontSize};
        font-weight: ${(props) => props.theme.typography.body.fontWeight};
        line-height: ${(props) => props.theme.typography.body.lineHeight};
        letter-spacing: ${(props) => props.theme.typography.body.letterSpacing};
		font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		color:  ${(props) => props.theme.colors.text};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
    }

	hr {
		height: 1px;
		width: 100%;
        background-color: var(--nte-graa-lys);
        border: none;
	}

    h1 {
        font-size: ${(props) => props.theme.typography.h1.fontSize};
        font-weight: ${(props) => props.theme.typography.h1.fontWeight};
        line-height: ${(props) => props.theme.typography.h1.lineHeight};
		letter-spacing: ${(props) => props.theme.typography.h1.letterSpacing};
		${(props) =>
			props.theme.media.desktop(css`
				font-size: 2.5rem;
			`)}
		margin: 0;
    }

    h2 {
        font-size: ${(props) => props.theme.typography.h2.fontSize};
        font-weight: ${(props) => props.theme.typography.h2.fontWeight};
        line-height: ${(props) => props.theme.typography.h2.lineHeight};
		letter-spacing: ${(props) => props.theme.typography.h2.letterSpacing};
		${(props) =>
			props.theme.media.mobile(css`
				font-size: 1.125rem;
				line-height: 1.625rem;
				margin-top: 0px;
			`)}
		margin: 0;
    }

    h3 {
        font-size: ${(props) => props.theme.typography.h3.fontSize};
        font-weight: ${(props) => props.theme.typography.h3.fontWeight};
        line-height: ${(props) => props.theme.typography.h3.lineHeight};
        letter-spacing: ${(props) => props.theme.typography.h3.letterSpacing};
		margin: 0;
    }

    h4 {
        font-size: ${(props) => props.theme.typography.h4.fontSize};
        font-weight: ${(props) => props.theme.typography.h4.fontWeight};
        line-height: ${(props) => props.theme.typography.h4.lineHeight};
        letter-spacing: ${(props) => props.theme.typography.h4.letterSpacing};
		margin: 0;
    }

    h5 {
        font-size: ${(props) => props.theme.typography.h5.fontSize};
        font-weight: ${(props) => props.theme.typography.h5.fontWeight};
        line-height: ${(props) => props.theme.typography.h5.lineHeight};
        letter-spacing: ${(props) => props.theme.typography.h5.letterSpacing};
		margin: 0;
    }

    h6 {
        font-size: ${(props) => props.theme.typography.h6.fontSize};
        font-weight: ${(props) => props.theme.typography.h6.fontWeight};
        line-height: ${(props) => props.theme.typography.h6.lineHeight};
        letter-spacing: ${(props) => props.theme.typography.h6.letterSpacing};
		margin: 0;
	}
	
	.align-center {
		text-align: center;
	}

    button, .button, [type="submit"] {
        font-size: ${(props) => props.theme.button.fontSize};
        font-weight: ${(props) => props.theme.button.fontWeight};
        padding: ${(props) => props.theme.button.padding};
        height: ${(props) => props.theme.button.height};
        line-height: ${(props) => props.theme.button.height};
        background: ${(props) => props.theme.button.background};
        color: ${(props) => props.theme.button.color};
        border: ${(props) => props.theme.button.border};
		border-radius: ${(props) => props.theme.button.borderRadius};
        transition: all 0.2s ease-out;
		outline: none;
		cursor: pointer;
		box-sizing: border-box;
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
        text-decoration: none;
		&:hover {
			background: ${(props) => props.theme.button.hoverBackground};
			color: ${(props) => props.theme.button.hoverColor};
		}
		&:focus {
			outline: 0;
			box-shadow: inset 0 0 0 1px black;
        }
        &:disabled {
            opacity: 0.25;
        }
        &.secondary {
            background: ${(props) => props.theme.secondaryButton.background};
            color: ${(props) => props.theme.secondaryButton.color};
			border: ${(props) => props.theme.secondaryButton.border};
			&:hover {
				background: ${(props) => props.theme.secondaryButton.hoverBackground};
				color: ${(props) => props.theme.secondaryButton.hoverColor};
			}
		}
		&.gray {
			background: ${(props) => props.theme.grayButton.background};
			color: ${(props) => props.theme.grayButton.color};
			border: ${(props) => props.theme.grayButton.border};
			&:hover {
				background: ${(props) => props.theme.grayButton.hoverBackground};
				color: ${(props) => props.theme.grayButton.hoverColor};
			}
		}
		&.small {
			font-size: 16px;
			padding: 8px 16px;
		}
		&.noStyle {
            background: transparent;
            color: ${(props) => props.theme.colors.text};
			border: 0;
			padding: 0;
			font-size: 20px;
			line-height: 24px;
			&:hover {
				background: transparent;
				color: ${(props) => props.theme.colors.text};
				border: 0;
			}
			&:disabled {
				cursor: default;
			}
        }
	}
	
	a {
		color: ${(props) => props.theme.colors.link};
		box-sizing: border-box;
		cursor: pointer;
		transition: color 350ms ease;
		&:hover {
			color: ${(props) => props.theme.colors.linkHover};
		}
	}

	/* Text meant only for screen readers. */
	.screen-reader-text {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		word-wrap: normal !important;
	}

	// Forms
	label {
		display: block;
		margin-bottom: 5px;
		font-weight: 700;
		font-size: 1rem;
		line-height: 1.1875rem;
		color: var(--nte-graa-mork);
		.required {
			color: ${(props) => props.theme.colors.emergencyMessage};
		}
	}

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), textarea, select {
		width: 100%;
		padding: 15px;
		font-size: 17px;
		font-family: inherit;
		&:focus {
			outline: solid 1px ${(props) => props.theme.colors.inputBorderFocus};
			outline-offset: -1px;
		}
		border-radius: 5px;
		border: 1px solid ${(props) => props.theme.colors.formWrapperBorder};
		background: white;
		resize: vertical;
	}

	textarea{
		min-height: 100px; 
	}
	input[type="checkbox"] {
		margin: 0 0 0 10px;
		height: 20px;
		width: 20px;
		accent-color: var(--nte-blaa-uu);
	}

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
		height: 50px;
	}
`;

export default GlobalStyle;
