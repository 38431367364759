import {Link, useNavigate} from "react-router-dom";

import {faPlusCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Direction, Flex, Justify} from "components/atoms/Flex";
import {InfoColumn, InfoRow} from "components/atoms/InfoRow";
import TrafficLightBadge from "components/molecules/TrafficLightBadge";
import {MainLayoutPageHeader} from "components/organisms/MainLayout";
import {ErrorWrapper} from "components/organisms/form/BaseField";
import useDateTime from "hooks/useDateTime";
import {InfoColumnValue} from "pages/Users/EditUserPage";

import {useContentQuery, useContentTextQuery} from "./ContentPage.graphql-gen";
import Error from "components/molecules/Error";
import Loading from "components/molecules/Loading";

export default function ContentBlocksPage() {
	const navigate = useNavigate();
	const date = useDateTime();
	const {data, error, loading} = useContentTextQuery();

	return (<>
				<MainLayoutPageHeader
					title={
						<Flex gap="var(--grid-2)">
							Innhold Tekst
							<Link to="/contentblocks/create" title="Ny tekst">
								<FontAwesomeIcon icon={faPlusCircle} />
							</Link>
						</Flex>
					}
				/>
				<Flex style={{ marginTop: "40px", borderRadius: "15px", overflow: "hidden" }} direction={Direction.column}>
					{(error && !loading) && <Error errorMessage="Feil ved henting av tekstblokker" />}
					{(loading && !data) && <Loading />}
					{data?.allContentTexts
						?.map((content) => (
							<InfoRow
								key={content.id}
								interactive
								onClick={() => {
									navigate("/contentblocks/" + content.id);
								}}
							>
								<InfoColumn width="50%">
									<InfoColumnValue>
										<TrafficLightBadge green={content?.published} red={!content?.published}>
											<>{content?.published ? "Publisert" : "Ikke publisert"}</>
										</TrafficLightBadge>
									</InfoColumnValue>
								</InfoColumn>
								<InfoColumn width="50%">
									<InfoColumnValue>
										<b>{content?.title}</b> sist oppdatert
										<b>{date.format(content?.lastUpdated)}</b>
									</InfoColumnValue>
								</InfoColumn>
							</InfoRow>
						))}
				</Flex>
				</>
	);
}
