import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	text-align: center;
	padding: 30px;
	max-width: 688px;
	margin: 0 auto;

	svg {
		width: 60px;
		max-width: 100%;
	}
`;

const LoadingAnimation = () => {
	return (
		<Wrapper>
			<svg x="0px" y="0px" viewBox="0 0 52 20" enableBackground="new 0 0 0 0">
				<circle fill="#757575" stroke="none" cx="6" cy="10" r="6">
					<animate
						attributeName="opacity"
						dur="2s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.1"
					/>
				</circle>

				<circle fill="#757575" stroke="none" cx="26" cy="10" r="6">
					<animate
						attributeName="opacity"
						dur="2s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.2"
					/>
				</circle>

				<circle fill="#757575" stroke="none" cx="46" cy="10" r="6">
					<animate
						attributeName="opacity"
						dur="2s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.3"
					/>
				</circle>
			</svg>
		</Wrapper>
	);
};

export default LoadingAnimation;