import { HTMLProps } from "react";

import { RequiredAsterisk } from "components/atoms/Required";
import { BaseField } from "components/organisms/form/BaseField";
import { FormElementError, useForm } from "components/organisms/form/Form";
import { useRefState } from "hooks/useRefState";
import { getErrorForField } from "utils/FormValidator";

type SelectOption = {
	label: string;
	value: string;
	disabled?: boolean;
	hidden?: boolean;
};

type SelectFieldProps = Omit<HTMLProps<HTMLSelectElement>, "onChange"> & {
	options?: SelectOption[];
	onChange?: (value: string) => void;
	setValue?: (value: string) => void;
	formattedErrors?: { _errors: string[] }; // formErrors is the future, formattedErrors is legacy from useForm
	formErrors?: FormElementError<unknown>;
};
export function SelectFieldClean({
	name,
	value,
	defaultValue,
	onChange,
	disabled,
	required,
	options,
	setValue,
	formattedErrors,
	formErrors,
	...props
}: SelectFieldProps) {
	const errorsForField = formErrors ? getErrorForField(formErrors, name) : null;

	return (
		<BaseField errors={(formattedErrors as FormElementError<unknown>) || (errorsForField as FormElementError<unknown>)}>
			<label>
				{props.label}
				{required && <RequiredAsterisk />}
				<select
					{...props}
					disabled={disabled}
					required={required}
					value={value}
					defaultValue={defaultValue}
					name={name}
					onChange={(e) => {
						const value = Array.from(e.target.options).find((option) => option.selected);
						setValue?.(value?.value || "");
						onChange?.(value?.value || "");
					}}
				>
					{options?.map((option, i) => (
						<option key={i} value={option.value} disabled={option.disabled} hidden={option.hidden}>
							{option.label}
						</option>
					))}
				</select>
			</label>
		</BaseField>
	);
}
export default function SelectField({ ...props }: SelectFieldProps) {
	const {
		errors,
		disabled: disabledState,
		defaultValue: formDefaultValue,
	} = useForm(props.name, {
		getValue: () => props.value || valueRef.current,
	});
	const [valueRef, setValue] = useRefState<string>((props.defaultValue as string) || formDefaultValue || "");

	return (
		<SelectFieldClean
			{...props}
			setValue={setValue}
			disabled={props.disabled || disabledState}
			defaultValue={props.defaultValue || formDefaultValue}
			formattedErrors={errors}
		/>
	);
}
