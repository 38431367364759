// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManualTriggerSporpticeNotificationsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ManualTriggerSporpticeNotificationsMutation = { manualTriggerSporpticeNotifications: boolean };

export type SyncContractsForListOfAccountingPointsMutationVariables = Types.Exact<{
  accountingPointsCSV: Types.Scalars['String'];
}>;


export type SyncContractsForListOfAccountingPointsMutation = { syncContractsForListOfAccountingPoints: boolean };


export const ManualTriggerSporpticeNotificationsDocument = gql`
    mutation manualTriggerSporpticeNotifications {
  manualTriggerSporpticeNotifications
}
    `;
export type ManualTriggerSporpticeNotificationsMutationFn = Apollo.MutationFunction<ManualTriggerSporpticeNotificationsMutation, ManualTriggerSporpticeNotificationsMutationVariables>;

/**
 * __useManualTriggerSporpticeNotificationsMutation__
 *
 * To run a mutation, you first call `useManualTriggerSporpticeNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManualTriggerSporpticeNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manualTriggerSporpticeNotificationsMutation, { data, loading, error }] = useManualTriggerSporpticeNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useManualTriggerSporpticeNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ManualTriggerSporpticeNotificationsMutation, ManualTriggerSporpticeNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManualTriggerSporpticeNotificationsMutation, ManualTriggerSporpticeNotificationsMutationVariables>(ManualTriggerSporpticeNotificationsDocument, options);
      }
export type ManualTriggerSporpticeNotificationsMutationHookResult = ReturnType<typeof useManualTriggerSporpticeNotificationsMutation>;
export type ManualTriggerSporpticeNotificationsMutationResult = Apollo.MutationResult<ManualTriggerSporpticeNotificationsMutation>;
export type ManualTriggerSporpticeNotificationsMutationOptions = Apollo.BaseMutationOptions<ManualTriggerSporpticeNotificationsMutation, ManualTriggerSporpticeNotificationsMutationVariables>;
export const SyncContractsForListOfAccountingPointsDocument = gql`
    mutation syncContractsForListOfAccountingPoints($accountingPointsCSV: String!) {
  syncContractsForListOfAccountingPoints(
    accountingPointsCSV: $accountingPointsCSV
  )
}
    `;
export type SyncContractsForListOfAccountingPointsMutationFn = Apollo.MutationFunction<SyncContractsForListOfAccountingPointsMutation, SyncContractsForListOfAccountingPointsMutationVariables>;

/**
 * __useSyncContractsForListOfAccountingPointsMutation__
 *
 * To run a mutation, you first call `useSyncContractsForListOfAccountingPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncContractsForListOfAccountingPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncContractsForListOfAccountingPointsMutation, { data, loading, error }] = useSyncContractsForListOfAccountingPointsMutation({
 *   variables: {
 *      accountingPointsCSV: // value for 'accountingPointsCSV'
 *   },
 * });
 */
export function useSyncContractsForListOfAccountingPointsMutation(baseOptions?: Apollo.MutationHookOptions<SyncContractsForListOfAccountingPointsMutation, SyncContractsForListOfAccountingPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncContractsForListOfAccountingPointsMutation, SyncContractsForListOfAccountingPointsMutationVariables>(SyncContractsForListOfAccountingPointsDocument, options);
      }
export type SyncContractsForListOfAccountingPointsMutationHookResult = ReturnType<typeof useSyncContractsForListOfAccountingPointsMutation>;
export type SyncContractsForListOfAccountingPointsMutationResult = Apollo.MutationResult<SyncContractsForListOfAccountingPointsMutation>;
export type SyncContractsForListOfAccountingPointsMutationOptions = Apollo.BaseMutationOptions<SyncContractsForListOfAccountingPointsMutation, SyncContractsForListOfAccountingPointsMutationVariables>;