// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSpotpriceAreasQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSpotpriceAreasQuery = { spotpriceZones?: Array<{ id?: Types.NordpoolArea, notifyOver?: number, highPrice?: number, lowPrice?: number }> };


export const GetSpotpriceAreasDocument = gql`
    query GetSpotpriceAreas {
  spotpriceZones {
    id
    notifyOver
    highPrice
    lowPrice
  }
}
    `;

/**
 * __useGetSpotpriceAreasQuery__
 *
 * To run a query within a React component, call `useGetSpotpriceAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotpriceAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotpriceAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpotpriceAreasQuery(baseOptions?: Apollo.QueryHookOptions<GetSpotpriceAreasQuery, GetSpotpriceAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpotpriceAreasQuery, GetSpotpriceAreasQueryVariables>(GetSpotpriceAreasDocument, options);
      }
export function useGetSpotpriceAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpotpriceAreasQuery, GetSpotpriceAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpotpriceAreasQuery, GetSpotpriceAreasQueryVariables>(GetSpotpriceAreasDocument, options);
        }
export type GetSpotpriceAreasQueryHookResult = ReturnType<typeof useGetSpotpriceAreasQuery>;
export type GetSpotpriceAreasLazyQueryHookResult = ReturnType<typeof useGetSpotpriceAreasLazyQuery>;
export type GetSpotpriceAreasQueryResult = Apollo.QueryResult<GetSpotpriceAreasQuery, GetSpotpriceAreasQueryVariables>;