// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { GridOwnerMappingDetailsFragmentDoc } from './SingleGridOwnerMappingPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateGridOwnerMappingMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  ecomonId: Types.Scalars['String'];
  websiteURL: Types.Scalars['String'];
  syncronizeNow: Types.Scalars['Boolean'];
}>;


export type CreateGridOwnerMappingMutation = { createGridOwnerMapping: { id: string, name: string, ecomonId: string, websiteURL: string, mergedCompanies?: Array<string>, numberOfUsers?: any } };

export type GetDistributionModelSupportQueryVariables = Types.Exact<{
  ecomonId: Types.Scalars['String'];
}>;


export type GetDistributionModelSupportQuery = { distributionModelSupport: { id: string, gln: string, timezone: string, validFrom: string, capacityModelType: string, energyModelType: string, supported: boolean } };

export type GetAvailableModelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAvailableModelsQuery = { availableModels: Array<string> };


export const CreateGridOwnerMappingDocument = gql`
    mutation CreateGridOwnerMapping($name: String!, $ecomonId: String!, $websiteURL: String!, $syncronizeNow: Boolean!) {
  createGridOwnerMapping(
    name: $name
    ecomonId: $ecomonId
    websiteURL: $websiteURL
    syncronizeNow: $syncronizeNow
  ) {
    ...GridOwnerMappingDetails
  }
}
    ${GridOwnerMappingDetailsFragmentDoc}`;
export type CreateGridOwnerMappingMutationFn = Apollo.MutationFunction<CreateGridOwnerMappingMutation, CreateGridOwnerMappingMutationVariables>;

/**
 * __useCreateGridOwnerMappingMutation__
 *
 * To run a mutation, you first call `useCreateGridOwnerMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGridOwnerMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGridOwnerMappingMutation, { data, loading, error }] = useCreateGridOwnerMappingMutation({
 *   variables: {
 *      name: // value for 'name'
 *      ecomonId: // value for 'ecomonId'
 *      websiteURL: // value for 'websiteURL'
 *      syncronizeNow: // value for 'syncronizeNow'
 *   },
 * });
 */
export function useCreateGridOwnerMappingMutation(baseOptions?: Apollo.MutationHookOptions<CreateGridOwnerMappingMutation, CreateGridOwnerMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGridOwnerMappingMutation, CreateGridOwnerMappingMutationVariables>(CreateGridOwnerMappingDocument, options);
      }
export type CreateGridOwnerMappingMutationHookResult = ReturnType<typeof useCreateGridOwnerMappingMutation>;
export type CreateGridOwnerMappingMutationResult = Apollo.MutationResult<CreateGridOwnerMappingMutation>;
export type CreateGridOwnerMappingMutationOptions = Apollo.BaseMutationOptions<CreateGridOwnerMappingMutation, CreateGridOwnerMappingMutationVariables>;
export const GetDistributionModelSupportDocument = gql`
    query GetDistributionModelSupport($ecomonId: String!) {
  distributionModelSupport(ecomonId: $ecomonId) {
    id
    gln
    timezone
    validFrom
    capacityModelType
    energyModelType
    supported
  }
}
    `;

/**
 * __useGetDistributionModelSupportQuery__
 *
 * To run a query within a React component, call `useGetDistributionModelSupportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionModelSupportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributionModelSupportQuery({
 *   variables: {
 *      ecomonId: // value for 'ecomonId'
 *   },
 * });
 */
export function useGetDistributionModelSupportQuery(baseOptions: Apollo.QueryHookOptions<GetDistributionModelSupportQuery, GetDistributionModelSupportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistributionModelSupportQuery, GetDistributionModelSupportQueryVariables>(GetDistributionModelSupportDocument, options);
      }
export function useGetDistributionModelSupportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistributionModelSupportQuery, GetDistributionModelSupportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistributionModelSupportQuery, GetDistributionModelSupportQueryVariables>(GetDistributionModelSupportDocument, options);
        }
export type GetDistributionModelSupportQueryHookResult = ReturnType<typeof useGetDistributionModelSupportQuery>;
export type GetDistributionModelSupportLazyQueryHookResult = ReturnType<typeof useGetDistributionModelSupportLazyQuery>;
export type GetDistributionModelSupportQueryResult = Apollo.QueryResult<GetDistributionModelSupportQuery, GetDistributionModelSupportQueryVariables>;
export const GetAvailableModelsDocument = gql`
    query GetAvailableModels {
  availableModels
}
    `;

/**
 * __useGetAvailableModelsQuery__
 *
 * To run a query within a React component, call `useGetAvailableModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableModelsQuery, GetAvailableModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableModelsQuery, GetAvailableModelsQueryVariables>(GetAvailableModelsDocument, options);
      }
export function useGetAvailableModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableModelsQuery, GetAvailableModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableModelsQuery, GetAvailableModelsQueryVariables>(GetAvailableModelsDocument, options);
        }
export type GetAvailableModelsQueryHookResult = ReturnType<typeof useGetAvailableModelsQuery>;
export type GetAvailableModelsLazyQueryHookResult = ReturnType<typeof useGetAvailableModelsLazyQuery>;
export type GetAvailableModelsQueryResult = Apollo.QueryResult<GetAvailableModelsQuery, GetAvailableModelsQueryVariables>;