// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { AbTestDetailsFragmentDoc } from './ABTestsPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertAbTestMutationVariables = Types.Exact<{
  abTest: Types.AbTestInput;
}>;


export type UpsertAbTestMutation = { upsertABTest?: { id?: string, name: string, enabled?: boolean, groupConnections?: Array<{ groupId?: string, percentage?: number, group?: { id?: string, displayName: string, usersCount?: any } }> } };


export const UpsertAbTestDocument = gql`
    mutation UpsertABTest($abTest: ABTestInput!) {
  upsertABTest(abTest: $abTest) {
    ...ABTestDetails
  }
}
    ${AbTestDetailsFragmentDoc}`;
export type UpsertAbTestMutationFn = Apollo.MutationFunction<UpsertAbTestMutation, UpsertAbTestMutationVariables>;

/**
 * __useUpsertAbTestMutation__
 *
 * To run a mutation, you first call `useUpsertAbTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAbTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAbTestMutation, { data, loading, error }] = useUpsertAbTestMutation({
 *   variables: {
 *      abTest: // value for 'abTest'
 *   },
 * });
 */
export function useUpsertAbTestMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAbTestMutation, UpsertAbTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAbTestMutation, UpsertAbTestMutationVariables>(UpsertAbTestDocument, options);
      }
export type UpsertAbTestMutationHookResult = ReturnType<typeof useUpsertAbTestMutation>;
export type UpsertAbTestMutationResult = Apollo.MutationResult<UpsertAbTestMutation>;
export type UpsertAbTestMutationOptions = Apollo.BaseMutationOptions<UpsertAbTestMutation, UpsertAbTestMutationVariables>;