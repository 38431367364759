import {useState} from "react";
import {useParams} from "react-router-dom";
import styled from "styled-components";

import {Direction, Flex} from "components/atoms/Flex";
import {MainLayoutPageHeader} from "components/organisms/MainLayout";
import Tabs from "components/organisms/Tabs";
import useDateTime from "hooks/useDateTime";
import {
	useCreateUserTestOtpMutation,
	useCreateUserTestTokenMutation,
	useGetUserQuery,
} from "pages/Users/EditUserPage.graphql-gen";
import EditUserPageInfoTab from "pages/Users/EditUserPage/EditUserPageInfoTab";
import EditUserPageNotificationsTab from "pages/Users/EditUserPage/EditUserPageNotificationsTab";

import Modal from "../../components/molecules/Modal";

export default function EditUserPage() {
	const { format } = useDateTime();
	const [showAppToken, setShowAppToken] = useState("");
	const [createUserTestToken] = useCreateUserTestTokenMutation();
	const { userId } = useParams<{ userId: string }>();
	const [otp, setOtp] = useState<string>("");

	const [fraktalModalData, setFraktalModalData] = useState<null | { body?: string; status?: number }>(null);

	const [createUserTestOtp] = useCreateUserTestOtpMutation({
		variables: {
			userId: userId || "",
		},
	});

	const { data, loading } = useGetUserQuery({
		variables: {
			userId: userId || "",
		},
	});

	const user = data?.user;

	return (
		<Flex direction={Direction.row} gap="var(--grid-2)">
			<div
				style={{
					flexGrow: "1",
				}}
			>
				{fraktalModalData && (
					<Modal onClose={() => setFraktalModalData(null)}>
						<div style={{ padding: "16px" }}>
							<h2 style={{ textAlign: "center" }}>Status: {fraktalModalData.status}</h2>
							<pre>{fraktalModalData.body}</pre>
						</div>
					</Modal>
				)}
				<MainLayoutPageHeader
					loading={loading}
					title={
						<>
							{user?.firstName && user?.lastName ? user?.firstName + " " + user?.lastName : <u>Åpen bruker</u>}
							<span style={{ fontSize: "1rem", marginLeft: "16px" }}>
								(siste synkronisering: {user?.lastUpdated && format(user?.lastUpdated, "yyyy.MM.dd HH:mm")})
							</span>
						</>
					}
					intro={
						<div>
							{`Kundenummer: ${(user?.customerIds?.length && user?.customerIds[0]) || "―"}`}{" "}
						</div>
					}
				/>
				<div style={{marginTop: "24px"}}>
					<EditUserPageInfoTab
						loading={loading}
						user={user}
						userId={userId}
						showAppToken={showAppToken}
						setShowAppToken={setShowAppToken}
						createUserTestToken={createUserTestToken}
					/>
				</div>
			</div>
			<Right show={!!showAppToken}>
				<StyledIframe
					src={(showAppToken && `${process.env.REACT_APP_MITT_NTE_APP_BASE_URL}?token=` + showAppToken) || ""}
					title="Mitt NTE"
					height="844"
					width="390"
				/>
			</Right>
		</Flex>
	);
}

const StyledIframe = styled.iframe`
	border: none;
	border-radius: 10px;
	box-shadow: 1px 3px 27px -17px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 1px 3px 27px -17px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 1px 3px 27px -17px rgba(0, 0, 0, 0.75);
	margin: 0 auto;
	display: block;
`;

export const InfoColumnLabel = styled.div`
	font-weight: bold;
`;
export const InfoColumnValue = styled.div``;

const Left = styled.div`
	flex: 1;
`;

const Right = styled.div<{ show?: any }>`
	max-width: ${(p) => (p.show ? "390px" : "0px")};
	transition: all 0.5s;
	overflow: ${(p) => (p.show ? "visible" : "hidden")};
`;
