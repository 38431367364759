// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFeatureFlagQueryVariables = Types.Exact<{
  featureFlagId: Types.Scalars['String'];
}>;


export type GetFeatureFlagQuery = { featureFlag?: { id?: string, key: string, displayName: string, global?: boolean, usersCount?: any, disabledMessage?: string, enabled?: boolean, from?: string, propertiesJson?: string, to?: string, version?: number } };

export type FeatureFlagDetailsFragment = { id?: string, key: string, displayName: string, global?: boolean, usersCount?: any, disabledMessage?: string, enabled?: boolean, from?: string, propertiesJson?: string, to?: string, version?: number };

export const FeatureFlagDetailsFragmentDoc = gql`
    fragment FeatureFlagDetails on FeatureFlag {
  id
  key
  displayName
  global
  usersCount
  disabledMessage
  enabled
  from
  propertiesJson
  to
  version
}
    `;
export const GetFeatureFlagDocument = gql`
    query GetFeatureFlag($featureFlagId: String!) {
  featureFlag(id: $featureFlagId) {
    ...FeatureFlagDetails
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

/**
 * __useGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagQuery({
 *   variables: {
 *      featureFlagId: // value for 'featureFlagId'
 *   },
 * });
 */
export function useGetFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
      }
export function useGetFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
        }
export type GetFeatureFlagQueryHookResult = ReturnType<typeof useGetFeatureFlagQuery>;
export type GetFeatureFlagLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagLazyQuery>;
export type GetFeatureFlagQueryResult = Apollo.QueryResult<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>;