import { ReactNode } from "react";
import styled from "styled-components";

type CardProps = {
	title?: string | ReactNode;
	children?: ReactNode;
};

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: var(--nte-blaa-300);
	border-radius: 1rem;
	gap: 1rem;
	padding: 1.5rem 1.3rem;
	height: max-content;
`;
export default function Card(props: CardProps) {
	return (
		<CardWrapper>
			{props.title && <h3>{props.title}</h3>}
			{props.children}
		</CardWrapper>
	);
}
