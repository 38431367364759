import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

export type UcPrice = {
	navn: string;
	priser: ProductPrice[];
};
export type ProductPrice = {
	verdi: string;
	gyldigFra: Date;
	gyldigTil: Date;
};

export type Price = {
	prisTypeId: string;
	gjeldendePris: string;
	endring?: PriceChange;
};
type PriceChange = {
	nyPris: string;
	gjelderFra: Date;
};

export type Term = {
	tekst: string;
};

export type Product = {
	id: string; // ucProductId
	navn?: string;
	beskrivelse?: string;
	type?: string;
	utilityCloudPriser?: UcPrice[];
	priselementer?: Price[];
	tariffId: string;
	prisbeskrivelse?: string;
	ordrebekreftelse?: string;
	harOrdrebekreftelse_ingen_IUC?: boolean;
	kredittsjekk?: boolean;
	vilkår?: Term[];
	fotnote?: Footnote;
	avtaletype?: string;
	kundetype?: Kundetype;
	oppdatert?: Updated;
};
type Footnote = {
	formattert: string;
	skript: string;
};
type Updated = {
	sistOppdatert?: Date;
	oppdatertAv?: string;
	brukerId?: string;
};

export type ProductDetails = {
	alias: string[];
	regler: string[];
	hovedprodukt: boolean;
	kredittsjekk: boolean;
	manuell: boolean;
	// There are more, but only added relevant ones for now
};

export type ProductWithDetails = {
	produkt: Product;
	produktSpesifikasjon: ProductDetails;
};

export enum Kundetype {
	FORBRUKER = "FORBRUKER",
	BEDRIFT = "BEDRIFT",
}

export type ProductBody = {
	navn: string;
	tariffId: string;
	avtaleType: string;
	kundeType: Kundetype;
	beskrivelse: string;
	fotnotetekst?: string;
	oppsummering: string;
	vilkår: string[];
	ordrebekreftelsesmal: string;
	priser?: Price[];
};

export type OrderConfirmation = {
	epostUrl?: string;
	id: string;
	navn: string;
	prioritet?: number;
	prising?: OrderConfirmationPrices[];
	vedlegg?: OrderConfirmationAttachment[];
};
type OrderConfirmationPrices = {
	prisTypeId: string;
	tittel: string;
	vilkaarstekst: string;
};

type OrderConfirmationAttachment = {
	filnavn: string;
	id: string;
	type: string;
};

// "ingen" is reserved term for No Order Confirmation in strom api
export const noneOrderConfirmation: OrderConfirmation = { id: "ingen", navn: "Ingen ordrebekreftelse" };

export type OrderConfirmationResponse = {
	tittel: string;
	innhold: string;
};

export default function useStromApi<T>(endpoint: string, isText?: boolean) {
	const auth = useAuth();

	const [data, setData] = useState<T>();
	const [isLoading, _setIsLoading] = useState(false);
	const [updateData, _setUpdateData] = useState(false);
	const [error, setError] = useState<string>();
	const debouncedLoading = debounce((val) => _setIsLoading(val), 500);
	const debouncedUpdateData = debounce((val) => _setUpdateData(val), 500);
	const setIsLoading = (val: boolean) => {
		if (val) {
			_setIsLoading(val);
		} else {
			debouncedLoading(val);
		}
	};
	const setUpdateData = (val: boolean) => {
		if (val) {
			_setUpdateData(val);
		} else {
			debouncedUpdateData(val);
		}
	};

	useEffect(() => {
		const token = auth?.user?.access_token;

		if ((token && endpoint && updateData) || (token && endpoint && !data && !updateData)) {
			setIsLoading(true);
			fetch(`${process.env.REACT_APP_STROEMBESTILLING_API_URL}${endpoint}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
				.then((response) => {
					if (!response || !response.ok) {
						setIsLoading(false);
						setUpdateData(false);
						setError(`Failed to get ${endpoint}, error: ${response.text}`);
						return Promise.reject(error);
					}
					return isText ? response.text() : response.json();
				})
				.then((result) => {
					setData(result as T);
					setIsLoading(false);
					setUpdateData(false);
				})
				.catch((e) => {
					setIsLoading(false);
					setUpdateData(false);
					console.error(e);
					setError(`Failed to get ${endpoint}, error: ${e}`);
					return e;
				});
		}
		// eslint-disable-next-line
	}, [auth, endpoint, updateData]);

	return {
		isLoading,
		data,
		error,
		isUpdating: updateData,
		updateData: () => setUpdateData(true),
	};
}
