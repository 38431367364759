// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { GroupDetailsFragmentDoc } from './SingleGroupPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertGroupMutationVariables = Types.Exact<{
  group: Types.GroupInput;
}>;


export type UpsertGroupMutation = { upsertGroup?: { id?: string, key: string, displayName: string, usersCount?: any, usersCanOptIn?: boolean, featureFlagKeys?: Array<string>, featureFlags?: Array<{ id?: string, key: string, displayName: string, global?: boolean, usersCount?: any }> } };


export const UpsertGroupDocument = gql`
    mutation UpsertGroup($group: GroupInput!) {
  upsertGroup(group: $group) {
    ...GroupDetails
  }
}
    ${GroupDetailsFragmentDoc}`;
export type UpsertGroupMutationFn = Apollo.MutationFunction<UpsertGroupMutation, UpsertGroupMutationVariables>;

/**
 * __useUpsertGroupMutation__
 *
 * To run a mutation, you first call `useUpsertGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertGroupMutation, { data, loading, error }] = useUpsertGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useUpsertGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpsertGroupMutation, UpsertGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertGroupMutation, UpsertGroupMutationVariables>(UpsertGroupDocument, options);
      }
export type UpsertGroupMutationHookResult = ReturnType<typeof useUpsertGroupMutation>;
export type UpsertGroupMutationResult = Apollo.MutationResult<UpsertGroupMutation>;
export type UpsertGroupMutationOptions = Apollo.BaseMutationOptions<UpsertGroupMutation, UpsertGroupMutationVariables>;