// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaginateIntercomSegmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PaginateIntercomSegmentsQuery = { labelSegments?: Array<{ id?: string, label?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, numberOfSegments: number }> };


export const PaginateIntercomSegmentsDocument = gql`
    query PaginateIntercomSegments {
  labelSegments {
    id
    label
    segmentIn
    segmentNotIn
    numberOfSegments
  }
}
    `;

/**
 * __usePaginateIntercomSegmentsQuery__
 *
 * To run a query within a React component, call `usePaginateIntercomSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateIntercomSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateIntercomSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaginateIntercomSegmentsQuery(baseOptions?: Apollo.QueryHookOptions<PaginateIntercomSegmentsQuery, PaginateIntercomSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginateIntercomSegmentsQuery, PaginateIntercomSegmentsQueryVariables>(PaginateIntercomSegmentsDocument, options);
      }
export function usePaginateIntercomSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateIntercomSegmentsQuery, PaginateIntercomSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginateIntercomSegmentsQuery, PaginateIntercomSegmentsQueryVariables>(PaginateIntercomSegmentsDocument, options);
        }
export type PaginateIntercomSegmentsQueryHookResult = ReturnType<typeof usePaginateIntercomSegmentsQuery>;
export type PaginateIntercomSegmentsLazyQueryHookResult = ReturnType<typeof usePaginateIntercomSegmentsLazyQuery>;
export type PaginateIntercomSegmentsQueryResult = Apollo.QueryResult<PaginateIntercomSegmentsQuery, PaginateIntercomSegmentsQueryVariables>;