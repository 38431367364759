import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import TextField from "components/organisms/form/TextField";
import { spotpriceAreaDisplayName } from "utils/SpotpriceArea";

import { NordpoolArea } from "generated/graphql-types";

import { useGetSingleSpotpriceAreaQuery, useUpsertSpotpriceAreaMutation } from "./SingleSpotpriceAreaPage.graphql-gen";

export default function SingleSpotpriceAreaPage() {
	const navigate = useNavigate();
	const { spotpriceAreaId } = useParams<{ spotpriceAreaId: NordpoolArea }>();
	const { data, loading, error: getError } = useGetSingleSpotpriceAreaQuery({ variables: { spotpriceAreaId: spotpriceAreaId || "" } });
	const [upsertSpotpriceArea, { loading: upsertLoading, error: upsertError }] = useUpsertSpotpriceAreaMutation();

	const error = getError || upsertError;

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<MainLayoutPageHeader
				loading={loading}
				title={
					<Flex gap="var(--grid-2)">
						{spotpriceAreaDisplayName(spotpriceAreaId)} ({spotpriceAreaId})
					</Flex>
				}
			/>
			{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
			<Form
				loading={loading || upsertLoading}
				key={data?.singleSpotpriceZone?.id}
				value={{
					notifyOver: data?.singleSpotpriceZone?.notifyOver?.toString() ?? "",
					highPrice: data?.singleSpotpriceZone?.highPrice?.toString() ?? "",
					lowPrice: data?.singleSpotpriceZone?.lowPrice?.toString() ?? "",
				}}
				schema={z.object({
					notifyOver: z.string(),
					highPrice: z.string(),
					lowPrice: z.string(),
				})}
				onSubmit={async (data) => {
					const notifyOver = parseFloat(data.notifyOver);
					const highPrice = parseFloat(data.highPrice);
					const lowPrice = parseFloat(data.lowPrice);

					const spotpriceArea = {
						id: spotpriceAreaId,
						notifyOver,
						highPrice,
						lowPrice,
					};

					const response = await upsertSpotpriceArea({
						variables: {
							spotpriceArea: spotpriceArea,
						},
					});

					if (response.data?.upsertSpotpriceZone) {
						navigate("/spotprice-areas/");
					}
				}}
			>
				<TextField name="notifyOver" type="number" label="Send varsel over øre:" />
				<h3 style={{ margin: "32px 0 0", padding: 0 }}>Spotprisgraf:</h3>
				<TextField name="highPrice" type="number" label="Prisen er høy når den er over øre:" />
				<TextField name="lowPrice" type="number" label="Prisen er lav når den er under øre:" />
				<Button>Lagre</Button>
			</Form>
		</Flex>
	);
}
