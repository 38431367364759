import styled from "styled-components";
import {motion} from "framer-motion";
import {ReactNode, useEffect, useState} from "react";
import {AnimatePresence} from "framer-motion";
import {format} from "date-fns";
import {nb} from "date-fns/locale";
import {useGetInfoScreenDataWithNteNoQuery} from "./TransformationPlayground.graphql-gen";

type NotificationCardProps = {
    ContentCard?: any;
    id?: string;
    title?: string;
    description?: string;
    content?: string;
    image?: {
        url?: string;
    };
    date?: string;
    link?: {
        view?: any;
        viewType?: any;
        viewDataJSON?: any;
        url?: string;
        type?: any;
    };
};

const contentCard = {
    "contentCards":
        {
            "link": {
                "url": "",
                "view": "notifications_settings",
                "type": "APP",
                "__typename": "ContentLink"
            },
            "image": {
                "url": "https://images.ctfassets.net/wjuvvn17b6p4/1RapCzkgspI8gZZm0eDX2F/4571a2069ec105925ccb3c769c8c1e2a/varsel.png",
                "__typename": "ContentImage"
            },
            "id": "855cd320-4efa-43e1-bbfc-79d072cac310",
            "title": "Aktiver spotprisvarsel og vær forberedt på høye strømpriser",
            "publishDate": "2023-02-03T09:25:00Z",
            "content": "Kom i gang med oppsettet ➡️",
            "__typename": "Content"
        }
};

export function TransformationPlayground() {

    const [index, setIndex] = useState<boolean>(true);
    const {data, error, loading} = useGetInfoScreenDataWithNteNoQuery({pollInterval: 30000, variables: {token: window.location.pathname.substring(window.location.pathname.length-8, window.location.pathname.length)}});
    const infoScreenData = data?.infoScreenData;
    const [todayOrToday, setTodayOrToday] = useState<string>()
    const activeLastXDaysArray = [infoScreenData?.activeUsersLast1Day, infoScreenData?.activeUsersLast7Days, infoScreenData?.activeUsersLast30Days]
    const [scroll, setScroll] = useState<number>(2);

    useEffect(() => {

        setTimeout(() => {
            setIndex(!index)
        }, 100000);


/*
        window.addEventListener("keydown", (e) => {
            if (e.key === "ArrowRight") {
                setIndex(!index)
            }
        })
*/

    }, [index]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScroll(scroll + 1);
        }, 10000)

        //  ( ͡° ͜ʖ ͡°)
        function run() {
            const date = new Date();
            if ((date.getHours() % 2) === 0) {
                setTodayOrToday("Brukere hittil idag")
            } else {
                setTodayOrToday("Brukere hittil i dag")
            }
        }

        run()
    }, [scroll])

    const chooseTitle = () => {
        if ((scroll % 3) === 0)
            return todayOrToday
        else if ((scroll % 3) === 1)
            return "Brukere siste 7 dager"
        else if ((scroll % 3) === 2)
            return "Brukere siste 30 dager"
    }

    return (
        <div style={{overflow: "hidden"}}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                }}>
                <img src={"/assets/nte-logo.svg"} style={{height: "100vh", width: "50%"}}/>
            </div>

            <AnimatePresence exitBeforeEnter>
                {index ?

                    <Curtan key={"mitt_nte"}>
                        <WrapperMittNTE>
                            <IconHeader>
                                <div style={{display: "flex", verticalAlign: "middle", alignItems: "center"}}>
                                    <MediumTitle fontColor={"white"}>Mitt NTE</MediumTitle>
                                </div>

                                <div style={{display: "flex", verticalAlign: "middle", alignItems: "center"}}>
                                    <img style={{height: "33px", width: "100px"}}
                                         src={"assets/icons/download-on-apple-store.png"}/>
                                    <img style={{height: "41px", width: "100px"}}
                                         src={"assets/icons/download-on-google-play.png"}/>
                                </div>
                            </IconHeader>

                            <FirstDataPoint>
                                <BigDataPointWrapper>
                                    <Number fontColour={"white"}>{infoScreenData?.currentActiveUsers.toLocaleString()}</Number>
                                    <Text fontColour={"white"}>Brukere akkurat nå</Text>
                                </BigDataPointWrapper>
                            </FirstDataPoint>

                            <SecondDataPoint>
                                <BigDataPointWrapper>
                                    <div style={{height: "117px", width: "100%", position: "relative"}}>
                                        <AnimatePresence initial={false} custom={scroll}>
                                            <motion.div
                                                style={{
                                                    position: "absolute",
                                                    left: "0%",
                                                    right: "0",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    color: "white",
                                                    textAlign: "center"
                                                }}
                                                key={scroll}
                                                initial={{x: 300, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: -300, opacity: 0}}
                                            >
                                                <LargeTitle>{activeLastXDaysArray[scroll % 3]?.toLocaleString().replaceAll(",", " ")}</LargeTitle>
                                                <Text fontColour={"white"}>{chooseTitle()}</Text>
                                            </motion.div>
                                        </AnimatePresence>
                                    </div>
                                </BigDataPointWrapper>
                            </SecondDataPoint>

                            <MixpanelNumberWrapper colour={"transparent"} initialGridRow={4}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="100%"
                                     height="64"
                                     style={{transform: "rotate(180deg)"}}
                                     viewBox="0 0 100% 64"
                                     fill="#09446280">
                                    <path
                                        d="M172.25 33.7067C92.17 30.8053 24.2667 42.7378 0 49.0667V0H1248V25.6C1104.02 -3.2 973.375 -4.48 861.25 6.4C655.064 26.4071 597.35 64 475.15 64C352.95 64 272.35 37.3333 172.25 33.7067Z"
                                        fill="#094462"/>
                                </svg>
                                <div style={{
                                    marginTop: "-5px",
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "#094462"
                                }}>
                                    <div key={(Math.random() + 1).toString(36).substring(7)}
                                         style={{
                                             width: "100%",
                                             display: "flex",
                                             flexDirection: "row",
                                             marginTop: "50px"
                                         }}>

                                        <LongDataList>
                                            <h2 style={{color: "white", fontSize: "26px"}}>Populært nå 🔥</h2>
                                            {infoScreenData?.popularPages?.map((data, index: number) => {
                                                return <Text fontColour={"white"}>{index + 1 + ". " + data.key}</Text>
                                            })}
                                        </LongDataList>

                                        <LongDataList>

                                            <h2 style={{color: "white", fontSize: "26px"}}>Salg hittil
                                                i {new Date(Date.now())?.toLocaleString('no', {month: 'long'})} 💰</h2>
                                            {infoScreenData?.totalSales?.map((data) => {
                                                return (
                                                    <Text
                                                        fontColour={"white"}>{data.key + ": "}<b>{data.value}</b></Text>
                                                )
                                            })}
                                        </LongDataList>
                                    </div>

                                    <h2 style={{
                                        color: "white",
                                        fontSize: "26px",
                                        marginLeft: "55px",
                                        marginBottom: "10px",
                                        marginTop: "40px",
                                    }}>Siste innhold 🔔</h2>

                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        paddingRight: "49px",
                                        paddingLeft: "49px",
                                    }}>
                                        {infoScreenData?.contentCards?.map((contentCard) => {
                                            return (
                                                <ContentCard>
                                                    <NotificationCard ContentCard={contentCard}></NotificationCard>
                                                </ContentCard>
                                            )
                                        })}
                                    </div>
                                </div>
                            </MixpanelNumberWrapper>
                        </WrapperMittNTE>
                    </Curtan>

                    :

                    <Curtan key={"nte_no"}>
                        <WrapperNteNo>
                            <IconHeader>
                                <MediumTitle style={{marginTop: "30px", marginLeft: "-40px", fontSize: "30px"}} fontColor={"black"}>NTE.no</MediumTitle>
                            </IconHeader>

                            <FirstDataPoint>
                                <BigDataPointWrapper>
                                    <Number fontColour={"black"}>{infoScreenData?.usersTodayNTENo.toLocaleString().replaceAll(",", ".")}</Number>
                                    <Text fontColour={"black"}>Brukere idag</Text>
                                </BigDataPointWrapper>
                            </FirstDataPoint>

                            <SecondDataPoint>
                                <BigDataPointWrapper>
                                    <Number fontColour={"black"}>{infoScreenData?.activeUsersLast30DaysNTENo.toLocaleString().replaceAll(",", ".")}</Number>
                                    <Text fontColour={"black"}>Brukere siste 30 dagene</Text>
                                </BigDataPointWrapper>
                            </SecondDataPoint>

                            <ThirdDataPoint>
                                <BigDataPointWrapper>
                                    <Number
                                        fontColour={"black"}>{infoScreenData?.conversionsLast30DaysNTENo.toLocaleString().replaceAll(",", ".")}</Number>
                                    <Text fontColour={"black"}>Konverteringer siste 30 dagene</Text>
                                </BigDataPointWrapper>
                            </ThirdDataPoint>

                            <MixpanelNumberWrapper colour={"transparent"} initialGridRow={5}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="auto"
                                     height="67"
                                     style={{transform: "rotate(180deg)"}}
                                     viewBox="0 0 auto auto"
                                     fill="#FFF"
                                     >
                                    <path
                                        d="M172.25 33.7067C92.17 30.8053 24.2667 42.7378 0 49.0667V0H1248V25.6C1104.02 -3.2 973.375 -4.48 861.25 6.4C655.064 26.4071 597.35 64 475.15 64C352.95 64 272.35 37.3333 172.25 33.7067Z"
                                        fill="white"/>
                                </svg>
                                <div style={{marginTop: "-5px", width: "100%", height: "100%", display: "flex", flexDirection: "row", paddingTop: "40px", backgroundColor: "#FFF"}}>
                                    <LongDataList>
                                        <h2>Populært nå 🔥</h2>
                                        {infoScreenData?.popularPagesNTENo?.map((entry, index: number) => {
                                            return <Text fontColour={"black"}><b>{index + 1 + ". "}</b> {entry.key?.charAt(0).toUpperCase()}{entry.key?.slice(1)}
                                            </Text>
                                        })}
                                    </LongDataList>

                                    <LongDataList>
                                        <h2>Konverteringer 💰</h2>
                                        {infoScreenData?.conversionsNTENo?.map((entry) => {
                                            return <Text fontColour={"black"}>{entry.key + ": "}
                                                <b>{entry.value.toLocaleString().replaceAll(",", ".")}</b></Text>
                                        })}
                                    </LongDataList>
                                </div>
                            </MixpanelNumberWrapper>
                        </WrapperNteNo>
                    </Curtan>
                }
            </AnimatePresence>
        </div>
    )
}

function Curtan({children}: { children: ReactNode; }) {

    return (
        <>
            <StyledMotionDiv
                initial={{y: "-1300px", opacity: 0}}
                animate={{
                    opacity: [0, 1, 1],
                    y: ["-1300px", "-1300px", "0px"]
                }}
                exit={{
                    y: ["0px", "-1300px", "-1300px"],
                    opacity: [1, 1, 0]
                }}
                transition={{duration: 2.5, ease: "easeInOut"}}
            >
                {children}
            </StyledMotionDiv>
        </>
    )
}

function NotificationCard(props: NotificationCardProps) {

    //TODO: Fix prop drilling
    return (
        <Event key={ContentCard.id}>

            <div>
                {props.ContentCard.image.url && <Image image={props.ContentCard.image.url}/>}

                <ContentWrapper>
                    <div>
                        <Header>
                            <ContentTitle>
                                <span style={{marginRight: "10px"}}>{props.ContentCard.title}</span>
                                {(
                                    <ContentDate>
                                        {format(new Date(props.ContentCard.date ? props.ContentCard.date : new Date()), "dd. MMM HH:mm", {
                                            locale: nb,
                                        })}{" "}
                                    </ContentDate>
                                )}
                            </ContentTitle>
                        </Header>
                        <ContentText>{props.ContentCard.content}</ContentText>
                    </div>
                </ContentWrapper>
            </div>
        </Event>
    );
}

//TODO: overflow hidden

const LargeTitle = styled.div`
    line-height: 117px;
    font-size: 100px;
    font-weight: 700;
`;


const Image: any = styled.div`
    background-image: url(${(p: any) => p.image});
    width: 100%;
    height: 180px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -30px;
`;

const ContentText = styled.p`
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 0;
`;

const ContentDate = styled.span`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.3;
    letter-spacing: 0.005em;
    margin: 0;
    display: inline-block;
    flex: 0 0 80px;
`;

const ContentTitle: any = styled.h2`
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
    flex-shrink: 0;
    max-width: 100%;
`;

const Header = styled.div`
    display: flex;
    margin-bottom: 8px;
    flex-wrap: wrap;
    align-items: center;
`;

const ContentWrapper = styled.div`
    padding: 17px;
    box-sizing: border-box;
`;

const Event = styled.li`
    height: 100%;
    list-style-type: none;
    box-sizing: border-box;
    background-color: rgba(255 255 255 0.5);
    border-radius: 16px;
    margin-bottom: 16px;
    box-shadow: 0px 4px 10px rgba(5, 113, 168, 0.06);
    overflow: hidden;
`;

const ContentCard = styled.div`
    position: relative;
    align-items: stretch;
    background-color: white;
    color: black;
    border-radius: 20px 20px 20px 20px;
    width: 48%;
`;

const LongDataList = styled.div`
    width: 50%;
    height: auto;
    padding-left: 8%;
`;

const BigDataPointWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Number = styled.div<{ fontColour: string }>`
    line-height: 117px;
    font-size: 100px;
    font-weight: 700;
    color: ${(p) => p.fontColour};
`;

const Text = styled.div<{ fontColour: string }>`
    line-height: 23px;
    font-size: 20px;
    font-weight: 400;
    margin-top: 16px;
    color: ${(p) => p.fontColour};
`;

const IconHeader = styled.div`
    grid-column: 2 / 4;
    grid-row: 1 / 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FirstDataPoint = styled.div`
    grid-column: 2 / 4;
    grid-row: 2 / 2;
    display: flex;
    justify-content: center;
    align-items: center;

`;

const SecondDataPoint = styled.div`
    grid-column: 2 / 4;
    grid-row: 3 / 3;

`;

const ThirdDataPoint = styled.div`
    grid-column: 2 / 4;
    grid-row: 4 / 4;
`;

const WrapperMittNTE = styled.div`
    display: grid;
    grid-template-columns: 10% 40% 40% 10%;
    grid-template-rows: 10vh 15vh 15vh 2fr;
    height: 220vh;
    padding: 0;
    margin: 0;

    background: linear-gradient(0deg, rgba(9, 68, 98, 0.7), rgba(9, 68, 98, 0.7)), url("/mitt-nte_infoscreen_background.jpeg");
    background-size: 2000px;
    background-position-x: 38%;
    background-position-y: -210px;

`;

const WrapperNteNo = styled.div`
    display: grid;
    grid-template-columns: 15% 35% 35% 15%;
    grid-template-rows: 10vh 15vh 15vh 15vh 2fr;
    height: 220vh;
    padding: 0;
    margin: 0;
    background: linear-gradient(0deg, rgba(206, 236, 249, 0.7), rgba(206, 236, 249, 0.7)), url("/nte-no_infoscreen_background.jpg");
    background-size: 1200px;
    background-position-x: 70%;
    --mixpanel-data-background-colour: #FFF;
`;

const MixpanelNumberWrapper = styled.div<{ colour: string; initialGridRow: number }>`
    grid-column: 1 / 5;
    grid-row: ${(p) => p.initialGridRow} / 6;
    background: ${(p) => p.colour};

    display: flex;
    flex-direction: column;

`;

const StyledMotionDiv = styled(motion.div)`
    width: 100%;
`;

const MediumTitle = styled.h1<{ fontColor: string }>`
    line-height: 46px;
    font-size: 40px;
    font-weight: 700;
    color: ${(p) => p.fontColor};
`;