import {useGetInfoScreenDataQuery} from "./InfoScreenPage.graphql-gen";
import styled from "styled-components";
import {format, subHours} from "date-fns";
import {nb} from "date-fns/locale";
import {AnimatePresence, motion} from "framer-motion";
import {useEffect, useState} from "react";


type NotificationCardProps = {
    ContentCard?: any;
    id?: string;
    title?: string;
    description?: string;
    content?: string;
    image?: {
        url?: string;
    };
    date?: string;
    link?: {
        view?: any;
        viewType?: any;
        viewDataJSON?: any;
        url?: string;
        type?: any;
    };
};

function NotificationCard(props: NotificationCardProps) {

    const { title, description, image, date, id, ContentCard } = props;

    return (
        <Event key={ContentCard.id}>
            <Content>

                {image?.url && <Image image={image.url} />}

                <ContentWrapper>
                    <Content>
                        <Header>
                            <ContentTitle>
                                <span style={{ marginRight: "10px" }}>{ContentCard.title}</span>
                                {(
                                    <ContentDate>
                                        {format(new Date(ContentCard.date ? ContentCard.date : new Date()), "dd. MMM HH:mm", {
                                            locale: nb,
                                        })}{" "}
                                    </ContentDate>
                                )}
                            </ContentTitle>
                        </Header>
                        <ContentText>{ContentCard.content}</ContentText>
                    </Content>
                </ContentWrapper>
            </Content>
        </Event>
    );
}

export default function InfoScreenPage(){

    const { data: info } = useGetInfoScreenDataQuery({pollInterval: 20000, variables: {token: window.location.pathname.substring(window.location.pathname.length-8, window.location.pathname.length)}});

    const activeLastXDaysArray = [info?.infoScreenData?.activeUsersLast1Day, info?.infoScreenData?.activeUsersLast7Days, info?.infoScreenData?.activeUsersLast30Days]
    const [scroll, setScroll] = useState<number>(2);
    const [todayOrToday, setTodayOrToday] = useState<string>()

    const width = window.innerWidth;
    const height = window.innerHeight;

    useEffect(()=>{
        //  ( ͡° ͜ʖ ͡°)
         function run(){
             const date = new Date();
             if((date.getHours() % 2) === 0){
                setTodayOrToday("Brukere hittil idag")
            }else {
                setTodayOrToday("Brukere hittil i dag")
            }
        }

        run()
    },[false])


    const chooseTitle = ()=>{
        if((scroll % 3) === 0)
            return todayOrToday
        else if((scroll % 3) === 1)
            return "Brukere siste 7 dager"
        else if((scroll % 3) === 2)
            return "Brukere siste 30 dager"
    }

    useEffect((()=>{
        const timeout = setTimeout(()=>{
            setScroll(scroll + 1);
        }, 10000)

    }), [scroll])


    return(
        <Wrapper>
            <HeaderWrapper>
                <MediumTitle style={{fontSize: "40px"}}>Mitt NTE</MediumTitle>
                <div style={{display: "flex", verticalAlign: "middle", alignItems: "center"}}>
                    <img style={{height: "33px", width: "100px"}} src={"assets/icons/download-on-apple-store.png"}/>
                    <img style={{height: "41px", width: "100px"}} src={"assets/icons/download-on-google-play.png"}/>
                </div>
            </HeaderWrapper>
            <TopWrapper>
                <LargeTitle>{info?.infoScreenData?.currentActiveUsers?.toLocaleString().replaceAll(",", " ")}</LargeTitle>
                <Text style={{marginBottom: "48px"}}>Brukere akkurat nå</Text>
                <div style={{height: "117px", width: "100%", position: "relative"}}>
                <AnimatePresence initial={false} custom={scroll}>
                    <motion.div
                        style={{position: "absolute", left: "0", right: "0"}}
                        key={scroll}
                        initial={{ x: 300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -300, opacity: 0 }}
                    >
                        <LargeTitle>{activeLastXDaysArray[scroll % 3]?.toLocaleString().replaceAll(",", " ")}</LargeTitle>
                        <Text>{chooseTitle()}</Text>
                    </motion.div>
                </AnimatePresence>
                </div>
            </TopWrapper>
            <MiddleWrapper>
                <Col>
                    <HackyDivToHideContentCards/>
                    <MediumTitle style={{marginBottom: "24px"}}>Populært nå 🔥</MediumTitle>
                    {info?.infoScreenData?.popularPages?.map((value, index)=>{
                        return(
                            <Text key={index}>{index + 1 + ". " + value.key}</Text>
                        )
                    })}
                </Col>
                <Col>
                    <HackyDivToHideContentCards/>
                    <MediumTitle style={{marginBottom: "24px"}}>Salg hittil i {new Date(Date.now())?.toLocaleString('no', {month: 'long'})} 💰</MediumTitle>
                    {info?.infoScreenData?.totalSales?.map((value, index)=>{
                        return(
                            <Text key={index}>{value.key + ": "} <b>{value.value}</b></Text>
                        )
                    })}
                </Col>
                <div>
                <Col style={{marginBottom: "24px", marginTop: "2rem"}}>
                    <HackyDivToHideContentCards/>
                    <MediumTitle>Siste innhold 🔔</MediumTitle>
                </Col>
                <Col style={{flex: "0 0 100%", display: "flex", justifyContent: "space-between"}}>
                    <HackyDivToHideContentCards/>
                    <>
                        {info?.infoScreenData?.contentCards?.map((c: NotificationCardProps, index: number)=>{
                            return(
                                <ContentCard key={index}>
                                    <NotificationCard ContentCard={c} image={c.image}/>
                                </ContentCard>
                            )
                        })}
                    </>
                </Col>
                </div>
            </MiddleWrapper>
        </Wrapper>
    )
}

const HackyDivToHideContentCards = styled.div`
    display: none;
    @media (min-width: 800px){
        display: block;
        padding-top: 100px;
    }

`;

const Wrapper = styled.div`
  background: url("https://images.ctfassets.net/wjuvvn17b6p4/grdIJcAAfGlnOfGt0nLXA/192399df8e1590899441facfebab2ed6/BG_infoskjerm_02.png");
  background-position: center;
  background-size: cover;
  font-family: 'Roboto';
  font-style: normal;
  display: block;
  width: 100vw;
  height: 100vh;
  color: white;
  @media (min-width: 800px){
    background: #094462;
    display: flex;
    flex-wrap: wrap;
  }
`;

const LargeTitle = styled.div`
  line-height: 117px;
  font-size: 100px;
  font-weight: 700;
`;
const MediumTitle = styled.div`
  line-height: 46px;
  font-size: 3.7vw;
  font-weight: 700;
  
  @media (min-width: 1200px){
    font-size: 28px;
  }
  @media (min-width: 800px){
    font-size: 20px;
  }
`;

const Text = styled.div`
    line-height: 23px;
    font-size: 20px;
    font-weight: 400; 
    margin-bottom: 16px;
`;

const TopWrapper = styled.div`
  text-align: center;
  margin: 0 auto 0;
  padding-top: 71px;
  
  @media (min-width: 1200px){
    margin: 0;
    width: 45%;
    padding-left: 277px;
    padding-top: 14vh;
  } 
    @media (min-width: 800px){
    margin: 0;
    width: 45%;
    padding-left: 100px;
    padding-top: 14vh;
  }
`;
const MiddleWrapper = styled.div`
  margin: 160px auto 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 54px 0;
  box-sizing: border-box;
  
  @media (min-width: 1200px){
    width: 55%;
    margin:0 0 40px;
    padding-left: 0;
    padding-right: 21%;
    
  }
  
  @media (min-width: 800px){
    width: 55%;
    margin:0 0 20px;
    padding-left: 0;
    padding-right: 10%;
    
  }
`;

const Col = styled.div`
  flex: 0 0 50%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 35px 54px 0;
  
  @media (min-width: 1200px){
    width: 100%;
    justify-content: space-around;
    margin: auto;
    padding-top: 0;
  }
  
  @media (min-width: 800px){
    width: 100%;
    justify-content: space-around;
    margin: auto;
    padding-top: 30px;
  }
`;




const ContentWrapper = styled.div`
	padding: 17px;
	box-sizing: border-box;
`;

const Image: any = styled.div`
	background-image: url(${(p: any) => p.image});
	width: 100%;
	height: 160px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
    margin-top: -30px;
`;

const Event = styled.li`
    height: 100%;
    list-style-type: none;
	box-sizing: border-box;
	background-color: rgba(255 255 255 0.5);
	border-radius: 16px;
	margin-bottom: 16px;
	box-shadow: 0px 4px 10px rgba(5, 113, 168, 0.06);
	overflow: hidden;
    @media (min-width: 800px){
        height: 50%;

    }
`;

const Header = styled.div`
	display: flex;
	margin-bottom: 8px;
	flex-wrap: wrap;
	align-items: center;
`;

const Content = styled.div``;

const ContentTitle: any = styled.h2`
    font-weight: 500;
    font-size: 1rem;
	margin: 0;
	line-height: 1.5;
	flex-shrink: 0;
	max-width: 100%;
`;

const ContentDate = styled.span`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.3;
    letter-spacing: 0.005em;
	margin: 0;
	display: inline-block;
	flex: 0 0 80px;
`;

const ContentText = styled.p`
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1.5;
	margin-bottom: 0;
	margin-top: 0;
`;

const ContentCard = styled.div`
  position: relative;
    align-items: stretch;
    background-color: white;
    color: black;
    border-radius: 20px 20px 20px 20px;
    width: 48%;
`;