import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { z } from "zod";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import RangeField from "components/organisms/form/RangeField";
import SelectField from "components/organisms/form/SelectField";
import TextField from "components/organisms/form/TextField";
import ToggleField from "components/organisms/form/ToggleField";

import { useGetGroupsQuery } from "../Groups/GroupsPage.graphql-gen";
import { useUpsertAbTestMutation } from "./CreateABTest.graphql-gen";

export const ABTestSchema = z.object({
	name: z.string().min(1),
	enabled: z.boolean(),
	percentage: z.string(),
	groupA: z.string(),
	groupB: z.string(),
});

export default function CreateABTestPage() {
	const navigate = useNavigate();
	const [upsertABTest, { loading, error }] = useUpsertAbTestMutation();
	const { data: groupsData } = useGetGroupsQuery();

	const [percentage, setPercentage] = useState("50");

	const percentageNumber = parseFloat(percentage);
	const aPercentage = percentageNumber;
	const bPercentage = 100 - percentageNumber;

	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<MainLayoutPageHeader title="Ny AB Test" intro="" />
			<Form
				loading={loading}
				onSubmit={async (abTest) => {
					const percentageNumber = parseFloat(percentage);

					const aPercentage = percentageNumber;
					const bPercentage = 100 - percentageNumber;

					const { data, errors } = await upsertABTest({
						variables: {
							abTest: {
								id: uuid(),
								name: abTest.name,
								enabled: abTest.enabled,
								groupConnections: [
									{
										groupId: abTest.groupA,
										percentage: aPercentage,
									},
									{
										groupId: abTest.groupB,
										percentage: bPercentage,
									},
								],
							},
						},
					});

					!errors && navigate("/ab-tests");
				}}
				schema={ABTestSchema}
			>
				<Flex direction={Direction.column} gap="var(--grid-2)">
					<TextField label="Navn" name="name" placeholder="Ny AB Test" />
					<ToggleField label="Aktivert?" name="enabled" />

					<Flex direction={Direction.column}>
						<RangeField
							name="percentage"
							min="1"
							max="99"
							value={percentage}
							onChange={(e) => {
								const t = e.target as any;

								setPercentage(t.value);
							}}
						/>
						<Flex gap="var(--grid-2)">
							<Flex direction={Direction.column} style={{ width: "50%" }}>
								<span style={{ width: "100%", textAlign: "center" }}>{aPercentage}%</span>
								<SelectField
									label="A Gruppe"
									name="groupA"
									key={groupsData?.groups?.length}
									options={
										groupsData?.groups?.map((g) => ({
											value: g.id || "",
											label: `${g.displayName} (${g.usersCount})`,
										})) || []
									}
								/>
							</Flex>
							<Flex direction={Direction.column} style={{ width: "50%" }}>
								<span style={{ width: "100%", textAlign: "center" }}>{bPercentage}%</span>
								<SelectField
									label="B Gruppe"
									name="groupB"
									key={groupsData?.groups?.length}
									options={
										groupsData?.groups?.map((g) => ({
											value: g.id || "",
											label: `${g.displayName} (${g.usersCount})`,
										})) || []
									}
								/>
							</Flex>
						</Flex>
					</Flex>

					{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
					<Button>Legg til ab test</Button>
				</Flex>
			</Form>
		</Flex>
	);
}
