import { useNavigate } from "react-router-dom";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoRow, InfoColumn, InfoColumnValue } from "components/atoms/InfoRow";
import { ErrorWrapper } from "components/organisms/form/BaseField";

import { useGetGridOwnerMappingsQuery } from "./GridOwnerMappingsPageMappedTab.graphql-gen";
import Error from "components/molecules/Error";
import Loading from "components/molecules/Loading";

export default function GridOwnerMappingsPageMappedTab() {
	const navigate = useNavigate();
	const { data, error, loading } = useGetGridOwnerMappingsQuery();

	return (
		<Flex direction={Direction.column} style={{borderRadius: "15px", overflow: "hidden" }}>
			{(error && !loading) && <Error errorMessage="Feil ved henting av netteiere" />}
			{(loading && !data) && <Loading />}
			{data?.gridOwnerMappings?.map((mapping) => (
				<InfoRow
					key={mapping.id}
					interactive
					onClick={() => {
						navigate("/grid-templates/" + mapping.id);
					}}
				>
					<InfoColumn>
						<InfoColumnValue>
							<b>{mapping?.name}</b> {mapping?.numberOfUsers ? `(${mapping?.numberOfUsers} brukere)` : ""}
						</InfoColumnValue>
					</InfoColumn>
				</InfoRow>
			))}
		</Flex>
	);
}
