import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function Portal(props: { children: ReactNode }) {
	const domNodeRef = useRef<HTMLDivElement | null>(null);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		domNodeRef.current = document.createElement("div");

		document.body.appendChild(domNodeRef.current);

		setReady(true);

		return () => {
			document.body.removeChild(domNodeRef.current!);

			domNodeRef.current = null;
		};
	}, [false]);

	if (!ready) return null;

	return createPortal(props.children, domNodeRef.current!);
}
