import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useQuery() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}

export default function useParamsState<T>(
	key: string = "tab",
	defaultValue: T
): [T, (newValue: T) => void] {
	const { pathname, search } = useLocation();
	const navigate = useNavigate();
	const url = new URLSearchParams(search);
	const query = useQuery();

	let stringValue = url.get(key);
	let value = undefined;

	if (stringValue) value = JSON.parse(stringValue);

	if (typeof value === "undefined") value = defaultValue;

	return [
		value,
		(newValue: T) => {
			query.set(key, JSON.stringify(newValue));

			navigate({
				pathname: pathname,
				search: query.toString(),
			});
		},
	];
}
