// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupAndReturnUserFromUcMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String'];
  birthDate: Types.Scalars['String'];
}>;


export type SetupAndReturnUserFromUcMutation = { setupAndReturnUserFromUc?: { userId?: string } };


export const SetupAndReturnUserFromUcDocument = gql`
    mutation setupAndReturnUserFromUc($phoneNumber: String!, $birthDate: String!) {
  setupAndReturnUserFromUc(phoneNumber: $phoneNumber, birthDate: $birthDate) {
    userId
  }
}
    `;
export type SetupAndReturnUserFromUcMutationFn = Apollo.MutationFunction<SetupAndReturnUserFromUcMutation, SetupAndReturnUserFromUcMutationVariables>;

/**
 * __useSetupAndReturnUserFromUcMutation__
 *
 * To run a mutation, you first call `useSetupAndReturnUserFromUcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupAndReturnUserFromUcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupAndReturnUserFromUcMutation, { data, loading, error }] = useSetupAndReturnUserFromUcMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      birthDate: // value for 'birthDate'
 *   },
 * });
 */
export function useSetupAndReturnUserFromUcMutation(baseOptions?: Apollo.MutationHookOptions<SetupAndReturnUserFromUcMutation, SetupAndReturnUserFromUcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupAndReturnUserFromUcMutation, SetupAndReturnUserFromUcMutationVariables>(SetupAndReturnUserFromUcDocument, options);
      }
export type SetupAndReturnUserFromUcMutationHookResult = ReturnType<typeof useSetupAndReturnUserFromUcMutation>;
export type SetupAndReturnUserFromUcMutationResult = Apollo.MutationResult<SetupAndReturnUserFromUcMutation>;
export type SetupAndReturnUserFromUcMutationOptions = Apollo.BaseMutationOptions<SetupAndReturnUserFromUcMutation, SetupAndReturnUserFromUcMutationVariables>;