import {subHours, subMonths, subWeeks} from "date-fns";
import {useRef} from "react";
import styled from "styled-components";
import {Direction, Flex, Wrap} from "components/atoms/Flex";
import {Title} from "components/atoms/Typography";
import BoxListItem from "components/molecules/BoxListItem";
import {useStatisticsPageDataQuery} from "./StatisticsPage.graphql-gen";
import {UCMeteringValuesAndCostFileLineChart} from "./UCMeteringValuesAndCostFileLineChart";
import ParentSize from '@visx/responsive/lib/components/ParentSize';

export default function StatisticsPage() {
    const nowRef = useRef(new Date());

    const {data} = useStatisticsPageDataQuery({
        variables: {
            oneMonthAgo: subMonths(nowRef.current, 1).toISOString(),
            oneWeekAgo: subWeeks(nowRef.current, 1).toISOString(),
            oneHourAgo: subHours(nowRef.current, 1).toISOString(),
        },
    });

    return (
        <Flex direction={Direction.column} gap="var(--grid-2)">
            <BoxWrapper>
                <Flex grow="1" gap="var(--grid-2)" wrap={Wrap.wrap}>
                    <BoxListItem col="4">
                        <div style={{textAlign: "left"}}>
                            <Title style={{margin: 0, padding: 0}}>Varsler og innboks</Title>
                            <p>Antall brukere: {data?.users?.total}</p>
                            <table width="100%">
                                <thead>
                                <tr>
                                    <td>Aktive</td>
                                    <td>siste måned</td>
                                    <td>siste uke</td>
                                    <td>siste time</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Brukere</td>
                                    <td>{data?.activeLastMonth?.total}</td>
                                    <td>{data?.activeLastWeek?.total}</td>
                                    <td>{data?.activeLastHour?.total}</td>
                                </tr>
                                <tr>
                                    <td>Android</td>
                                    <td>{data?.androidsActiveLastMonth?.total}</td>
                                    <td>{data?.androidsActiveLastWeek?.total}</td>
                                    <td>{data?.androidsActiveLastHour?.total}</td>
                                </tr>
                                <tr>
                                    <td>iOS</td>
                                    <td>{data?.iphonesActiveLastMonth?.total}</td>
                                    <td>{data?.iphonesActiveLastWeek?.total}</td>
                                    <td>{data?.iphonesActiveLastHour?.total}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </BoxListItem>
                    <BoxListItem col="4">
                        <div style={{textAlign: "left"}}>
                            <Title style={{margin: 0, padding: 0}}>Varsler og innboks</Title>
                            <p>Antall sendte varsel: {data?.notifications?.total}</p>
                            <p>Antall uhåndterte feil: {data?.notificationErrors?.total}</p>
                            <p>Antall håndterte feil: {data?.notificationErrorsHandled?.total}</p>
                        </div>
                    </BoxListItem>
                    <BoxListItem col="4">
                        <div style={{textAlign: "left"}}>
                            <Title style={{margin: 0, padding: 0}}>Varsel: Timeforbruk</Title>
                            <p>Antall målere med varsel: {data?.statistics?.totalVarsel}</p>
                            <p>Antall brukere med varsel: {data?.statistics?.hasVarsel}</p>
                            <p>Antall brukere uten varsel: {data?.statistics?.withoutVarsel}</p>
                            <p>Antall brukere som har hatt varsel: {data?.statistics?.haveHadVarsel}</p>
                        </div>
                    </BoxListItem>
                    <BoxListItem col={"12"} newLine>
                        <div style={{textAlign: "left"}}>
                            <Title style={{margin: 0, padding: 0}}>UC: Måleverdier og Kostnadsfiler</Title>
                            <div style={{margin: 0, padding: 0, maxWidth: "100%", height: 300}}>
                            <ParentSize>
                                {({ width, height }) => <UCMeteringValuesAndCostFileLineChart width={width} height={height} />}
                            </ParentSize>
                            </div>
                        </div>
                    </BoxListItem>
                </Flex>
            </BoxWrapper>
        </Flex>
    );
}

const BoxWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    
`;
