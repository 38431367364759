// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { AbTestDetailsFragmentDoc } from './ABTestsPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAbTestMutationVariables = Types.Exact<{
  abTestId: Types.Scalars['String'];
}>;


export type DeleteAbTestMutation = { deleteABTest?: { id?: string, name: string, enabled?: boolean, groupConnections?: Array<{ groupId?: string, percentage?: number, group?: { id?: string, displayName: string, usersCount?: any } }> } };

export type GetAbTestQueryVariables = Types.Exact<{
  abTestId: Types.Scalars['String'];
}>;


export type GetAbTestQuery = { aBTest?: { id?: string, name: string, enabled?: boolean, groupConnections?: Array<{ groupId?: string, percentage?: number, group?: { id?: string, displayName: string, usersCount?: any } }> } };


export const DeleteAbTestDocument = gql`
    mutation DeleteABTest($abTestId: String!) {
  deleteABTest(abTestId: $abTestId) {
    ...ABTestDetails
  }
}
    ${AbTestDetailsFragmentDoc}`;
export type DeleteAbTestMutationFn = Apollo.MutationFunction<DeleteAbTestMutation, DeleteAbTestMutationVariables>;

/**
 * __useDeleteAbTestMutation__
 *
 * To run a mutation, you first call `useDeleteAbTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAbTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAbTestMutation, { data, loading, error }] = useDeleteAbTestMutation({
 *   variables: {
 *      abTestId: // value for 'abTestId'
 *   },
 * });
 */
export function useDeleteAbTestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAbTestMutation, DeleteAbTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAbTestMutation, DeleteAbTestMutationVariables>(DeleteAbTestDocument, options);
      }
export type DeleteAbTestMutationHookResult = ReturnType<typeof useDeleteAbTestMutation>;
export type DeleteAbTestMutationResult = Apollo.MutationResult<DeleteAbTestMutation>;
export type DeleteAbTestMutationOptions = Apollo.BaseMutationOptions<DeleteAbTestMutation, DeleteAbTestMutationVariables>;
export const GetAbTestDocument = gql`
    query GetABTest($abTestId: String!) {
  aBTest(id: $abTestId) {
    ...ABTestDetails
  }
}
    ${AbTestDetailsFragmentDoc}`;

/**
 * __useGetAbTestQuery__
 *
 * To run a query within a React component, call `useGetAbTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAbTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAbTestQuery({
 *   variables: {
 *      abTestId: // value for 'abTestId'
 *   },
 * });
 */
export function useGetAbTestQuery(baseOptions: Apollo.QueryHookOptions<GetAbTestQuery, GetAbTestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAbTestQuery, GetAbTestQueryVariables>(GetAbTestDocument, options);
      }
export function useGetAbTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAbTestQuery, GetAbTestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAbTestQuery, GetAbTestQueryVariables>(GetAbTestDocument, options);
        }
export type GetAbTestQueryHookResult = ReturnType<typeof useGetAbTestQuery>;
export type GetAbTestLazyQueryHookResult = ReturnType<typeof useGetAbTestLazyQuery>;
export type GetAbTestQueryResult = Apollo.QueryResult<GetAbTestQuery, GetAbTestQueryVariables>;