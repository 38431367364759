import React, { HTMLProps } from "react";

import { BaseField } from "components/organisms/form/BaseField";
import { FormElementError, useForm } from "components/organisms/form/Form";
import { useRefState } from "hooks/useRefState";

type SelectOption = {
	label?: string;
	value?: string;
	group?: string;
};

type MultiSelectFieldProps = Omit<HTMLProps<HTMLSelectElement>, "onChange"> & {
	options?: SelectOption[];
	onChange?: (value: string[]) => void;
};

type OptionGroup = {
	misc: SelectOption[];
	no: SelectOption[];
	klassifisering: SelectOption[];
	gruppe: SelectOption[];
	lagetMitt: SelectOption[];
	produkt: SelectOption[];
};

export default function MultiSelectField({ name, value, defaultValue, onChange, disabled, options, ...props }: MultiSelectFieldProps) {
	const {
		errors,
		disabled: disabledState,
		defaultValue: formDefaultValue,
	} = useForm(name, {
		getValue: () => value || valueRef.current,
	});
	const [valueRef, setValue] = useRefState<string[]>((defaultValue as string[]) || formDefaultValue || []);

	const map = new Map<string, SelectOption[]>();

	options?.forEach((o) => {
		const groupName = o.group || "Ukategorisert";
		if (!map.has(groupName)) {
			map.set(groupName, []);
		}

		map.get(groupName)?.push(o);
	});

	const capitalizeFirstLetter = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	return (
		<BaseField errors={errors as FormElementError<unknown>}>
			<label>
				{props.label}
				<select
					{...props}
					disabled={disabled || disabledState}
					value={value}
					defaultValue={defaultValue || formDefaultValue}
					name={name}
					multiple
					onChange={(e) => {
						const value = Array.from(e.target.options)
							.filter((option) => option.selected)
							.map((option) => option.value);
						setValue(value);
						onChange?.(value);
					}}
				>
					{
						<>
							{Array.from(map.keys()).map((key, index) => {
								return (
									<optgroup key={index} label={capitalizeFirstLetter(key.replace("_", " "))}>
										{map.get(key)?.map((o, index) => (
											<option key={index + "a"} label={o.label} value={o.value}>
												{o.value}
											</option>
										))}
									</optgroup>
								);
							})}
						</>
					}
				</select>
			</label>
		</BaseField>
	);
}
