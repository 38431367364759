import { useNavigate } from "react-router-dom";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoColumn, InfoRow } from "components/atoms/InfoRow";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import { SelectFieldClean } from "components/organisms/form/SelectField";
import { useGetGridOwnerMappingsQuery } from "pages/GridOwnerMapping/GridOwnerMappingsPage/GridOwnerMappingsPageMappedTab.graphql-gen";
import { InfoColumnLabel } from "pages/Users/EditUserPage";

import { useGetGridOwnersQuery } from "./GridOwnerMappingsPageUnmappedTab.graphql-gen";
import Error from "components/molecules/Error";
import Loading from "components/molecules/Loading";

export default function GridOwnerMappingsPageUnmappedTab() {
	const navigate = useNavigate();
	const { data: gridOwners, error: gridOwnersError, loading } = useGetGridOwnersQuery();
	const { data: gridOwnerMappings, error: gridOwnerMappingsError, loading: loadingMapping } = useGetGridOwnerMappingsQuery();

	return (
		<Flex direction={Direction.column} style={{borderRadius: "15px", overflow: "hidden" }}>
			{(gridOwnersError || gridOwnerMappingsError) && (
				<Error errorMessage="Feil ved henting av netteiere" />
			)}
			{((loading || loadingMapping) && !gridOwners) && <Loading />}
			{gridOwners?.gridOwners
				?.filter((gridOwner) => !gridOwner.ecomonId)
				.sort((a, b) => (a.count < b.count ? 1 : -1))
				.map((gridOwner, i) => (
					<InfoRow key={i}>
						<InfoColumn>
							<InfoColumnLabel>
								{gridOwner?.fraktalName || <u>UKJENT</u>} {gridOwner?.count && `(${gridOwner.count} brukere)`}
							</InfoColumnLabel>
						</InfoColumn>

						<InfoColumn style={{ marginLeft: "auto", marginRight: "8.75rem" }}>
							<SelectFieldClean
								value=""
								label="Slå sammen med "
								onChange={(value) => {
									if (!value) {
										return;
									}
									navigate("/grid-templates/" + value + "?newGridName=" + gridOwner?.fraktalName);
								}}
								options={gridOwnerMappings?.gridOwnerMappings
									?.filter((mapping) => mapping.ecomonId)
									?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
									?.map((mapping) => ({
										value: mapping.id,
										label: mapping.name,
										disabled: false,
										hidden: false,
									}))
									.concat([
										{
											value: "",
											label: "Velg netteier",
											disabled: true,
											hidden: true,
										},
									])}
							/>
						</InfoColumn>
					</InfoRow>
				))}
		</Flex>
	);
}
