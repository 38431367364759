import dot from "dot-object";
import z, { ZodRawShape } from "zod";

import { FormElementError } from "components/organisms/form/Form";

export function getDataFromSubmit<T extends ZodRawShape>(form: EventTarget | HTMLFormElement, schema: z.ZodObject<T>) {
	const formData = new FormData(form as HTMLFormElement);
	const flattenedData: { [key: string]: any } = {};

	const keys = Array.from(formData.keys()) as string[];

	keys.forEach((key) => {
		const type = (form as HTMLFormElement).querySelector(`[name="${key}"]`)?.getAttribute("type") || "unknown";

		if (key.indexOf("[]") > -1) {
			flattenedData[key.replace("[]", "")] = formData.getAll(key);
		} else {
			flattenedData[key] = formData.get(key);
		}

		if (type === "number") {
			flattenedData[key] = flattenedData[key] && Number(flattenedData[key]);
		}

		if (type === "checkbox") {
			flattenedData[key] = formData.get(key) === "on" ? true : false;
		}
	});

	return schema.safeParse(dot.object(flattenedData));
}

export function getErrorForField(formErrors: FormElementError<unknown>, name?: string, index?: number) {
	// Todo: move this logic to Base Field when useForm is dead
	const currentError = formErrors?.[name?.replace("[]", "")!] as any;
	if (currentError) {
		if (typeof index === "number" && currentError?.[index]) {
			return currentError?.[index];
		} else {
			return currentError;
		}
	}
	return null;
}
