import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Direction, Flex } from "components/atoms/Flex";
import { useModalButtonContext } from "components/organisms/ModalButton";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import TextField from "components/organisms/form/TextField";
import { useSetupAndReturnUserFromUcMutation } from "pages/Users/AddUserFromUcModal.graphql-gen";

const AddUsersFromUcFormSchema = z.object({
	phoneNumber: z.string().min(1),
	birthDate: z.string().min(6),
});

export default function AddUserFromUcModal() {
	const navigate = useNavigate();
	const [setupAndReturnUserFromUc, { loading }] = useSetupAndReturnUserFromUcMutation();
	const { close } = useModalButtonContext();

	return (
		<Flex
			direction={Direction.column}
			style={{
				width: "400px",
				padding: "16px",
			}}
		>
			<Form
				loading={loading}
				schema={AddUsersFromUcFormSchema}
				onSubmit={async (variables) => {
					const response = await setupAndReturnUserFromUc({
						variables,
					});

					close();
					navigate(`/users/${response.data?.setupAndReturnUserFromUc?.userId}`);
				}}
			>
				<TextField label="Telefonnummer" name="phoneNumber" />
				<TextField label="Fødselsdato/nummer" name="birthDate" />
				<Button>Legg til kunde</Button>
			</Form>
		</Flex>
	);
}
