import React from "react";

import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import Form from "components/organisms/form/Form";
import TextField from "components/organisms/form/TextField";
import Button from "components/organisms/form/Button";
import { Direction, Flex } from "components/atoms/Flex";
import { z } from "zod";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { useUpsertFeatureFlagMutation } from "./CreateFeatureFlags.graphql-gen";
import ToggleField from "../../components/organisms/form/ToggleField";

export const FeatureFlagSchema = z.object({
	displayName: z.string().min(1),
	key: z.string().min(1),
	global: z.boolean(),
	disabledMessage: z.string().optional(),
	enabled: z.boolean(),
	from: z.string().optional(),
	propertiesJson: z.string().optional(),
	to: z.string().optional(),
	version: z.number().optional(),
});

export default function CreateFeatureFlagPage() {
	const navigate = useNavigate();
	const [upsertFeatureFlag, { loading, error }] = useUpsertFeatureFlagMutation();

	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<MainLayoutPageHeader title="Ny feature" intro="" />
			<Form
				loading={loading}
				onSubmit={async (featureFlag) => {
					const { data, errors } = await upsertFeatureFlag({
						variables: {
							featureFlag: {
								id: uuid(),
								...featureFlag,
							},
						},
					});

					!errors && navigate("/features");
				}}
				schema={FeatureFlagSchema}
			>
				<Flex direction={Direction.column} gap="var(--grid-2)">
					<TextField label="Navn" name="displayName" placeholder="Ny feature" />
					<TextField label="Key" name="key" placeholder="nyFeature" />
					<ToggleField label="Global?" name="global" />
					<ToggleField label="Aktivert?" name="enabled" />

					{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
					<Button>Legg til feature</Button>
				</Flex>
			</Form>
		</Flex>
	);
}
