// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ServiceMessagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ServiceMessagesQuery = { paginateServiceMessages?: { total?: any, items?: Array<{ id?: string, title?: string, content?: string, published: boolean, publishDate?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, location?: Array<Types.ServiceMessageLocation> }> } };

export type ServiceMessageDetailsFragment = { id?: string, title?: string, content?: string, published: boolean, publishDate?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string>, location?: Array<Types.ServiceMessageLocation> };

export const ServiceMessageDetailsFragmentDoc = gql`
    fragment ServiceMessageDetails on ServiceMessage {
  id
  title
  content
  published
  publishDate
  segmentIn
  segmentNotIn
  location
}
    `;
export const ServiceMessagesDocument = gql`
    query ServiceMessages {
  paginateServiceMessages {
    total
    items {
      ...ServiceMessageDetails
    }
  }
}
    ${ServiceMessageDetailsFragmentDoc}`;

/**
 * __useServiceMessagesQuery__
 *
 * To run a query within a React component, call `useServiceMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceMessagesQuery(baseOptions?: Apollo.QueryHookOptions<ServiceMessagesQuery, ServiceMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceMessagesQuery, ServiceMessagesQueryVariables>(ServiceMessagesDocument, options);
      }
export function useServiceMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceMessagesQuery, ServiceMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceMessagesQuery, ServiceMessagesQueryVariables>(ServiceMessagesDocument, options);
        }
export type ServiceMessagesQueryHookResult = ReturnType<typeof useServiceMessagesQuery>;
export type ServiceMessagesLazyQueryHookResult = ReturnType<typeof useServiceMessagesLazyQuery>;
export type ServiceMessagesQueryResult = Apollo.QueryResult<ServiceMessagesQuery, ServiceMessagesQueryVariables>;