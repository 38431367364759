// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFeaturesFlagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFeaturesFlagsQuery = { featureFlags?: Array<{ id?: string, key: string, displayName: string, global?: boolean, usersCount?: any }> };

export type FeatureFlagListFragment = { id?: string, key: string, displayName: string, global?: boolean, usersCount?: any };

export const FeatureFlagListFragmentDoc = gql`
    fragment FeatureFlagList on FeatureFlag {
  id
  key
  displayName
  global
  usersCount
}
    `;
export const GetFeaturesFlagsDocument = gql`
    query GetFeaturesFlags {
  featureFlags {
    ...FeatureFlagList
  }
}
    ${FeatureFlagListFragmentDoc}`;

/**
 * __useGetFeaturesFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeaturesFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturesFlagsQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturesFlagsQuery, GetFeaturesFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturesFlagsQuery, GetFeaturesFlagsQueryVariables>(GetFeaturesFlagsDocument, options);
      }
export function useGetFeaturesFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturesFlagsQuery, GetFeaturesFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturesFlagsQuery, GetFeaturesFlagsQueryVariables>(GetFeaturesFlagsDocument, options);
        }
export type GetFeaturesFlagsQueryHookResult = ReturnType<typeof useGetFeaturesFlagsQuery>;
export type GetFeaturesFlagsLazyQueryHookResult = ReturnType<typeof useGetFeaturesFlagsLazyQuery>;
export type GetFeaturesFlagsQueryResult = Apollo.QueryResult<GetFeaturesFlagsQuery, GetFeaturesFlagsQueryVariables>;