// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIntercomSegmentQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetIntercomSegmentQuery = { singleLabelSegment?: { id?: string, label?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string> } };

export type UpsertIntercomSegmentMutationVariables = Types.Exact<{
  labelSegment: Types.LabelSegmentInput;
}>;


export type UpsertIntercomSegmentMutation = { upsertLabelSegment?: { id?: string, label?: string, segmentIn?: Array<string>, segmentNotIn?: Array<string> } };


export const GetIntercomSegmentDocument = gql`
    query GetIntercomSegment($id: String!) {
  singleLabelSegment(labelSegmentId: $id) {
    id
    label
    segmentIn
    segmentNotIn
  }
}
    `;

/**
 * __useGetIntercomSegmentQuery__
 *
 * To run a query within a React component, call `useGetIntercomSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntercomSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntercomSegmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIntercomSegmentQuery(baseOptions: Apollo.QueryHookOptions<GetIntercomSegmentQuery, GetIntercomSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntercomSegmentQuery, GetIntercomSegmentQueryVariables>(GetIntercomSegmentDocument, options);
      }
export function useGetIntercomSegmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntercomSegmentQuery, GetIntercomSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntercomSegmentQuery, GetIntercomSegmentQueryVariables>(GetIntercomSegmentDocument, options);
        }
export type GetIntercomSegmentQueryHookResult = ReturnType<typeof useGetIntercomSegmentQuery>;
export type GetIntercomSegmentLazyQueryHookResult = ReturnType<typeof useGetIntercomSegmentLazyQuery>;
export type GetIntercomSegmentQueryResult = Apollo.QueryResult<GetIntercomSegmentQuery, GetIntercomSegmentQueryVariables>;
export const UpsertIntercomSegmentDocument = gql`
    mutation upsertIntercomSegment($labelSegment: LabelSegmentInput!) {
  upsertLabelSegment(labelSegment: $labelSegment) {
    id
    label
    segmentIn
    segmentNotIn
  }
}
    `;
export type UpsertIntercomSegmentMutationFn = Apollo.MutationFunction<UpsertIntercomSegmentMutation, UpsertIntercomSegmentMutationVariables>;

/**
 * __useUpsertIntercomSegmentMutation__
 *
 * To run a mutation, you first call `useUpsertIntercomSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertIntercomSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertIntercomSegmentMutation, { data, loading, error }] = useUpsertIntercomSegmentMutation({
 *   variables: {
 *      labelSegment: // value for 'labelSegment'
 *   },
 * });
 */
export function useUpsertIntercomSegmentMutation(baseOptions?: Apollo.MutationHookOptions<UpsertIntercomSegmentMutation, UpsertIntercomSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertIntercomSegmentMutation, UpsertIntercomSegmentMutationVariables>(UpsertIntercomSegmentDocument, options);
      }
export type UpsertIntercomSegmentMutationHookResult = ReturnType<typeof useUpsertIntercomSegmentMutation>;
export type UpsertIntercomSegmentMutationResult = Apollo.MutationResult<UpsertIntercomSegmentMutation>;
export type UpsertIntercomSegmentMutationOptions = Apollo.BaseMutationOptions<UpsertIntercomSegmentMutation, UpsertIntercomSegmentMutationVariables>;