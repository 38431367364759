import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import useStromApi, { OrderConfirmation, Product } from "hooks/useStromApi";
import EditOrderConfirmationPage from "pages/Products/EditOrderConfirmationPage";
import EditProductPage from "pages/Products/EditProductPage";
import ProductsPage from "pages/Products/ProductsPage";
import LoadingAnimation from "pages/generic/LoadingAnimation";

const InfoWrapper = styled.div`
	height: calc(100vh - 100px);
	display: flex;
	align-items: center;
	justify-content: center;
`;

export function FullPageLoading() {
	return (
		<InfoWrapper>
			<LoadingAnimation />
		</InfoWrapper>
	);
}
export function FullPageErrorMessage(error?: string) {
	return (
		<InfoWrapper>
			<span>{error ? error : "Fant ikke nødvendig data, prøv igjen senere"}</span>
		</InfoWrapper>
	);
}

export default function ProductsTopPage() {
	const {
		data: products,
		isLoading: productsLoading,
		updateData: refetchProducts,
		isUpdating: productsAreUpdating,
	} = useStromApi<Product[]>("/produkter/list-alle");
	const { data: orderConfirmations, isLoading: orderConfirmationsLoading } = useStromApi<OrderConfirmation[]>("/order-confirmations/templates");

	return (
		<Routes>
			<Route
				index
				element={
					productsLoading || orderConfirmationsLoading ? (
						FullPageLoading()
					) : !products?.length || !orderConfirmations?.length ? (
						FullPageErrorMessage()
					) : (
						<ProductsPage products={products} orderConfirmations={orderConfirmations} />
					)
				}
			/>
			<Route
				path=":ucProductId"
				element={
					(productsLoading || orderConfirmationsLoading) && !productsAreUpdating ? (
						FullPageLoading()
					) : !products?.length || !orderConfirmations?.length ? (
						FullPageErrorMessage()
					) : (
						<EditProductPage products={products} orderConfirmations={orderConfirmations} updateProducts={refetchProducts} />
					)
				}
			/>
			<Route
				path="order-confirmations/:orderConfirmationId"
				element={
					productsLoading || orderConfirmationsLoading ? (
						FullPageLoading()
					) : !products?.length || !orderConfirmations?.length ? (
						FullPageErrorMessage()
					) : (
						<EditOrderConfirmationPage products={products} orderConfirmations={orderConfirmations} />
					)
				}
			/>
		</Routes>
	);
}
