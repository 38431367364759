import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

type TypographyProps = {
	children: ReactNode;
	loading?: boolean;
	className?: string;
	style?: any;
};

const SkeletonWrapper = styled.span`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const StyledSkeleton = styled(Skeleton)`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const StyledTitle = styled.h1`
	position: relative;
	font-size: 2rem;
	line-height: 1.4;
	margin: 0px;
	padding: 0;

	display: flex;
    vertical-align: middle;
    align-items: center;
`;

const StyledTitleH3 = styled.h3`
	position: relative;
	margin: 0px;
	padding: 0;
`;

export function Title({ children, ...props }: TypographyProps) {
	return (
		<StyledTitle {...props}>
			{props.loading && (
				<SkeletonWrapper>
					<StyledSkeleton />
				</SkeletonWrapper>
			)}
			{children}
		</StyledTitle>
	);
}

export function TitleH3({ children, ...props }: TypographyProps) {
	return (
		<StyledTitleH3 {...props}>
			{props.loading && (
				<SkeletonWrapper>
					<StyledSkeleton />
				</SkeletonWrapper>
			)}
			{children}
		</StyledTitleH3>
	);
}

export const StyledSecondTitle = styled.h2`
	position: relative;
	font-size: 20px;
	line-height: 1.2;
	margin: 0;
	padding: 0;
`;

export function SecondTitle({ children, ...props }: TypographyProps) {
	return (
		<StyledSecondTitle {...props}>
			{props.loading && (
				<SkeletonWrapper>
					<StyledSkeleton />
				</SkeletonWrapper>
			)}
			{children}
		</StyledSecondTitle>
	);
}
