import { Link, useParams } from "react-router-dom";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import Tabs from "components/organisms/Tabs";
import { useGetGroupQuery } from "pages/Groups/SingleGroupPage.graphql-gen";

import SingleGroupsPageFeaturesTab from "./SingleGroupsPage/SingleGroupsPageFeaturesTab";
import SingleGroupsPageUsersTab from "./SingleGroupsPage/SingleGroupsPageUsersTab";

export default function SingleGroupPage() {
	const { groupId } = useParams<{ groupId: string }>();
	const { data, loading } = useGetGroupQuery({ variables: { groupId: groupId || "" } });

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<MainLayoutPageHeader
				loading={loading}
				title={
					<Flex gap="var(--grid-2)">
						{data?.group?.displayName || "Gruppe"}
						<Link to="edit" title={`Rediger ${data?.group?.displayName || "Gruppe"}`}>
							<FontAwesomeIcon icon={faPencil} />
						</Link>
					</Flex>
				}
				intro={`${data?.group?.usersCount ?? 0} brukere i gruppen`}
			/>
			<Tabs
				paramName="tab"
				tabs={[
					{
						name: "features",
						label: "Features",
						renderer: () => <SingleGroupsPageFeaturesTab features={data?.group?.featureFlags || []} />,
					},
					{
						name: "users",
						label: "Brukere",
						renderer: () => <SingleGroupsPageUsersTab groupId={groupId || ""} />,
					},
				]}
			/>
		</Flex>
	);
}
