import React from "react"
import {MainLayoutPageHeader} from "../../components/organisms/MainLayout";
import {Flex} from "../../components/atoms/Flex";
import Form from "../../components/organisms/form/Form";
import {useCreateContentTextMutation} from "./CreateContentTextPage.graphql-gen";
import Button from "components/organisms/form/Button";
import TextField from "components/organisms/form/TextField";
import TextareaField from "components/organisms/form/TextareaField";
import {ContentTextInput} from "../../generated/graphql-types";
import {useNavigate} from "react-router-dom";
import {ContentTextSchema} from "./CreateContentTextPage";
import {useSearchContentTextQuery, useUpdateContentTextMutation} from "./EditContentTextPage.graphql-gen";
import ToggleField from "../../components/organisms/form/ToggleField";
import {ErrorWrapper} from "../../components/organisms/form/BaseField";

export function EditContentTextPage(){

    const {data, loading, error} = useSearchContentTextQuery({
        variables: {
            id: document.location.href.split("/")[5]
        }
    });
    const id = data?.searchContentText?.id;
    const [upsertContent] = useUpdateContentTextMutation();
    const navigate = useNavigate();

    return(
        <div>
            <MainLayoutPageHeader title="Rediger tekst" />
            <Flex gap="16px" style={{marginTop: "2rem"}}>
                {!loading &&
                <Form
                    schema={ContentTextSchema}
                    value={data?.searchContentText}
                    onSubmit={async (data: ContentTextInput) =>{
                        const {errors} = await upsertContent({
                            variables:{
                                contentText:{
                                    ...data,
                                    id: id
                                }
                            }
                        })

                        !errors && navigate("/content")
                    }}
                >

                    <ToggleField name="published" label="Publisert?" />
                    <TextField name="handle" label="Handle" />
                    <TextField name="title" label="Tittel" />
                    <TextareaField name="text" label="Tekst"></TextareaField>


                    {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
                    <Button>Lagre innhold</Button>
                </Form>
                }
            </Flex>
        </div>
    )
}