import { HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

import { useForm } from "components/organisms/form/Form";

const ButtonElement = styled.button`
	position: relative;
`;
const ButtonChildren = styled.span<{ $loading?: boolean }>`
	opacity: ${(props) => (props.$loading ? 0 : 1)};
`;
const LoadingOverlay = styled.span`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

export default function Button({
	children,
	loadingElement,
	loading: rootLoading,
	...props
}: HTMLAttributes<HTMLButtonElement> & { loading?: boolean; loadingElement?: ReactNode }) {
	const { loading, disabled } = useForm();

	const isLoading = loading || rootLoading;

	return (
		<ButtonElement type="submit" disabled={disabled} {...props}>
			{isLoading && loadingElement && <LoadingOverlay>{loadingElement}</LoadingOverlay>}
			<ButtonChildren $loading={(loadingElement && isLoading) || false}>{children}</ButtonChildren>
		</ButtonElement>
	);
}
