// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAbTestsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAbTestsQuery = { aBTests?: Array<{ id?: string, name: string, enabled?: boolean, groupConnections?: Array<{ groupId?: string, percentage?: number, group?: { id?: string, displayName: string, usersCount?: any } }> }> };

export type AbTestDetailsFragment = { id?: string, name: string, enabled?: boolean, groupConnections?: Array<{ groupId?: string, percentage?: number, group?: { id?: string, displayName: string, usersCount?: any } }> };

export const AbTestDetailsFragmentDoc = gql`
    fragment ABTestDetails on ABTest {
  id
  name
  enabled
  groupConnections {
    groupId
    percentage
    group {
      id
      displayName
      usersCount
    }
  }
}
    `;
export const GetAbTestsDocument = gql`
    query GetABTests {
  aBTests {
    ...ABTestDetails
  }
}
    ${AbTestDetailsFragmentDoc}`;

/**
 * __useGetAbTestsQuery__
 *
 * To run a query within a React component, call `useGetAbTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAbTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAbTestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAbTestsQuery(baseOptions?: Apollo.QueryHookOptions<GetAbTestsQuery, GetAbTestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAbTestsQuery, GetAbTestsQueryVariables>(GetAbTestsDocument, options);
      }
export function useGetAbTestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAbTestsQuery, GetAbTestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAbTestsQuery, GetAbTestsQueryVariables>(GetAbTestsDocument, options);
        }
export type GetAbTestsQueryHookResult = ReturnType<typeof useGetAbTestsQuery>;
export type GetAbTestsLazyQueryHookResult = ReturnType<typeof useGetAbTestsLazyQuery>;
export type GetAbTestsQueryResult = Apollo.QueryResult<GetAbTestsQuery, GetAbTestsQueryVariables>;