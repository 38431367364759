import { HTMLProps } from "react";
import styled from "styled-components";

import { BaseField } from "components/organisms/form/BaseField";
import { FormElementError, useForm } from "components/organisms/form/Form";
import { useRefState } from "hooks/useRefState";
import { getErrorForField } from "utils/FormValidator";

const StyledLabel = styled.label`
	display: flex;
`;

interface ToggleFieldProps extends HTMLProps<HTMLInputElement> {
	setValue?: (value: boolean) => void;
	formattedErrors?: { _errors: string[] }; // formErrors is the future, formattedErrors is legacy from useForm
	formErrors?: FormElementError<unknown>;
}

export function ToggleFieldClean({ name, checked, defaultChecked, onChange, disabled, setValue, formattedErrors, formErrors, ...props }: ToggleFieldProps) {
	const errorsForField = formErrors ? getErrorForField(formErrors, name) : null;

	return (
		<BaseField errors={(formattedErrors as FormElementError<unknown>) || (errorsForField as FormElementError<unknown>)}>
			<StyledLabel>
				{props.label}
				<input
					{...props}
					type="checkbox"
					disabled={disabled}
					checked={checked}
					defaultChecked={defaultChecked}
					name={name}
					onChange={(e) => {
						setValue?.(e.target.checked);
						onChange?.(e);
					}}
				/>
			</StyledLabel>
		</BaseField>
	);
}

export default function ToggleField({ ...props }: ToggleFieldProps) {
	const {
		errors,
		disabled: disabledState,
		defaultValue: formDefaultValue,
	} = useForm(props.name, {
		getValue: () => props.checked || valueRef.current,
	});
	const [valueRef, setValue] = useRefState<boolean>(props.defaultChecked || formDefaultValue || false);

	return (
		<ToggleFieldClean
			{...props}
			setValue={setValue}
			disabled={props.disabled || disabledState}
			defaultChecked={props.defaultChecked || formDefaultValue}
			formattedErrors={errors}
		/>
	);
}
