import { Direction, Flex } from "components/atoms/Flex";
import useParamsState from "hooks/useParamsState";
import React, { ReactNode, useMemo, useState } from "react";
import styled, { css } from "styled-components";

const TabButton = styled.button<{ active?: boolean }>`
	border: none;
	border-radius: 3rem;
	background: var(--nte-blaa-10);
	color: var(--nte-blaa-uu);
	transition: background-color 250ms ease;
	transition: color 250ms ease;
	padding: 0.4rem 1.5rem;
	font-weight: 600;

	${(props) =>
		props.active &&
		css`
			background: var(--nte-blaa-uu);
			color: var(--white);
			font-weight: bold;
		`}
`;

const TabsWrapper = styled(Flex)`
	display: inline-flex;
	border-radius: 3rem;
	background: var(--nte-blaa-10);
`;

const TabContentWrapper = styled.div``;

type Tab = {
	label: ReactNode;
	name: string;
	renderer: () => ReactNode;
};
export interface TabsProps {
	tabs: Tab[];
	defaultTab?: string;
	paramName?: string;
}

export default function Tabs(props: TabsProps) {
	const [currentTabName, setCurrentTabName] = useParamsState(
		props.paramName,
		props.defaultTab || props.tabs?.[0]?.name || ""
	);

	const tabContent = props.tabs.find((tab) => tab.name === currentTabName)?.renderer() || (
		<span />
	);

	return (
		<Flex gap="var(--grid-2)" direction={Direction.column}>
			<div>
				<TabsWrapper>
					{props.tabs.map((tab) => (
						<TabButton
							active={currentTabName === tab.name}
							onClick={() => setCurrentTabName(tab.name)}
							key={tab.name}
						>
							{tab.label}
						</TabButton>
					))}
				</TabsWrapper>
			</div>
			<TabContentWrapper>{tabContent}</TabContentWrapper>
		</Flex>
	);
}
