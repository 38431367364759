import { Link, useNavigate } from "react-router-dom";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoRow, InfoColumn } from "components/atoms/InfoRow";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import { InfoColumnValue } from "pages/Users/EditUserPage";

import { useGetAbTestsQuery } from "./ABTestsPage.graphql-gen";
import Loading from "components/molecules/Loading";
import Error from "components/molecules/Error";

export default function ABTestsPage() {
	const navigate = useNavigate();
	const { data, error, loading } = useGetAbTestsQuery();

	return (
		<div>
			<MainLayoutPageHeader
				title={
					<Flex gap="var(--grid-2)">
						AB Tester
						<Link to="create" title="Ny AB Test">
							<FontAwesomeIcon icon={faPlusCircle} />
						</Link>
					</Flex>
				}
			/>
			<Flex style={{ marginTop: "40px", borderRadius: "15px", overflow: "hidden" }} direction={Direction.column}>
				{(error && !loading) && <Error errorMessage="Feil ved henting av AB tester" />}
				{(loading && !data) && <Loading />}
				{data?.aBTests?.map((abTest) => (
					<InfoRow
						key={abTest.id}
						interactive
						onClick={() => {
							navigate("/ab-tests/" + abTest.id);
						}}
					>
						<InfoColumn width="25%">
							<InfoColumnValue>
								<b>{abTest?.name}</b>
							</InfoColumnValue>
						</InfoColumn>
						<InfoColumn width="75%">
							<InfoColumnValue>
								<ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
									{abTest.groupConnections?.map?.((c) => (
										<li>
											{c.group?.displayName}: {c.group?.usersCount}
										</li>
									))}
								</ul>
							</InfoColumnValue>
						</InfoColumn>
					</InfoRow>
				))}
			</Flex>
		</div>
	);
}
