import { Direction, Flex } from "components/atoms/Flex";
import { Title } from "components/atoms/Typography";
import Button from "components/organisms/form/Button";
import { useManualTriggerSporpticeNotificationsMutation, useSyncContractsForListOfAccountingPointsMutation } from "pages/Tools/ToolsPage.graphql-gen";

export default function ToolsPage() {
	const [manualTriggerSporpticeNotifications, { loading: manualTriggerSporpticeNotificationsLoading }] = useManualTriggerSporpticeNotificationsMutation();
	const [syncContractsForListOfAccountingPoints, { loading: syncContractsForListOfAccountingPointsLoading }] =
		useSyncContractsForListOfAccountingPointsMutation();
	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<Title style={{ margin: 0, padding: 0 }}>Tools</Title>

			<Button
				loading={manualTriggerSporpticeNotificationsLoading}
				onClick={async () => {
					if (!window.confirm("Helt sikker på at du ønsker å kjøre spotpris varsler?")) return;
					if (manualTriggerSporpticeNotificationsLoading) return;

					const { errors } = await manualTriggerSporpticeNotifications();
					if (errors?.length) {
						alert(JSON.stringify(errors));
					}
				}}
			>
				Trigger spotprice notifications.
			</Button>
			<form
				onSubmit={(e) => {
					e.preventDefault();

					if (syncContractsForListOfAccountingPointsLoading) return;

					const meters = (e.target as any).meters.value;
					if (!meters) return;

					syncContractsForListOfAccountingPoints({
						variables: {
							accountingPointsCSV: meters,
						},
					}).then((response) => {
						console.log(response);
					});
				}}
			>
				<h2>Sykroniser kontrakter knyttet til målere (komma sepparert liste med målere):</h2>
				<textarea name="meters" placeholder="707057500065184449,707057500064612110..."></textarea>
				<Button loading={syncContractsForListOfAccountingPointsLoading}>Synkroniser</Button>
			</form>
		</Flex>
	);
}
