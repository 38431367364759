import { Link } from "react-router-dom";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import NotificationTemplatesPageErrorTab from "pages/NotificationTemplates/NotificationTemplatesPage/NotificationTemplatesPageErrorTab";

import Tabs from "../../components/organisms/Tabs";
import NotificationTemplatesPageSentTab from "./NotificationTemplatesPage/NotificationTemplatesPageSentTab";
import NotificationTemplatesPageTemplatesTab from "./NotificationTemplatesPage/NotificationTemplatesPageTemplatesTab";

export default function NotificationTemplatesPage() {
	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<MainLayoutPageHeader
				title={
					<Flex gap="var(--grid-2)">
						Varsler
						<Link to="create" title="Nytt varsel">
							<FontAwesomeIcon icon={faPlusCircle} />
						</Link>
					</Flex>
				}
			/>

			<Tabs
				tabs={[
					{
						label: "Feil",
						name: "errors",
						renderer: () => <NotificationTemplatesPageErrorTab />,
					},
				]}
			/>
		</Flex>
	);
}
