// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateContentTextMutationVariables = Types.Exact<{
  contentText?: Types.InputMaybe<Types.ContentTextInput>;
}>;


export type CreateContentTextMutation = { createContentText?: { id?: string, handle?: string, title?: string, text?: string } };


export const CreateContentTextDocument = gql`
    mutation CreateContentText($contentText: ContentTextInput) {
  createContentText(content: $contentText) {
    id
    handle
    title
    text
  }
}
    `;
export type CreateContentTextMutationFn = Apollo.MutationFunction<CreateContentTextMutation, CreateContentTextMutationVariables>;

/**
 * __useCreateContentTextMutation__
 *
 * To run a mutation, you first call `useCreateContentTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentTextMutation, { data, loading, error }] = useCreateContentTextMutation({
 *   variables: {
 *      contentText: // value for 'contentText'
 *   },
 * });
 */
export function useCreateContentTextMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentTextMutation, CreateContentTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentTextMutation, CreateContentTextMutationVariables>(CreateContentTextDocument, options);
      }
export type CreateContentTextMutationHookResult = ReturnType<typeof useCreateContentTextMutation>;
export type CreateContentTextMutationResult = Apollo.MutationResult<CreateContentTextMutation>;
export type CreateContentTextMutationOptions = Apollo.BaseMutationOptions<CreateContentTextMutation, CreateContentTextMutationVariables>;