import React from "react";

import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import Form from "components/organisms/form/Form";
import TextField from "components/organisms/form/TextField";
import Button from "components/organisms/form/Button";
import { Direction, Flex } from "components/atoms/Flex";
import { z } from "zod";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import { useNavigate, useParams } from "react-router-dom";
import { useUpsertFeatureFlagMutation } from "./CreateFeatureFlags.graphql-gen";
import { useGetFeatureFlagQuery } from "./SingleFeatureFlagsPage.graphql-gen";
import { DangerButton } from "components/atoms/Button";
import { useDeleteFeatureFlagMutation } from "./EditFeatureFlag.graphql-gen";
import { FeatureFlagSchema } from "./CreateFeatureFlags";
import ToggleField from "../../components/organisms/form/ToggleField";
import TextareaField from "../../components/organisms/form/TextareaField";

export default function EditFeatureFlagPage() {
	const navigate = useNavigate();
	const { featureId } = useParams<{ featureId: string }>();
	const [upsertFeatureFlag, { loading: upsertLoading, error }] = useUpsertFeatureFlagMutation();
	const [deleteFeatureFlag, { loading: deleteLoading }] = useDeleteFeatureFlagMutation();
	const { data, loading } = useGetFeatureFlagQuery({
		variables: { featureFlagId: featureId || "" },
	});

	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<MainLayoutPageHeader
				loading={loading || upsertLoading || deleteLoading}
				title={`Rediger ${data?.featureFlag?.displayName || "Feature"}`}
				intro={`${data?.featureFlag?.usersCount ?? 0} brukere har denne featuren`}
			/>
			<Form
				loading={loading}
				key={data?.featureFlag?.id}
				value={data?.featureFlag}
				onSubmit={async (featureFlag) => {
					const { data, errors } = await upsertFeatureFlag({
						variables: {
							featureFlag: {
								id: featureId,
								...featureFlag,
							},
						},
					});

					!errors && navigate("/features/" + featureId);
				}}
				schema={FeatureFlagSchema}
			>
				<Flex direction={Direction.column} gap="var(--grid-2)">
					<TextField label="Navn" name="displayName" placeholder="Ny feature" />
					<TextField label="Key" name="key" placeholder="nyFeature" />

					<ToggleField label="Global?" name="global" />
					<ToggleField label="Aktivert?" name="enabled" />

					{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
					<Button>Lagre feature</Button>
					<DangerButton
						type="button"
						onClick={async () => {
							if (
								!window.confirm(
									`Sikker på at du ønsker å slette featuren?
${data?.featureFlag?.usersCount} brukere har tilgang.`
								)
							) {
								return;
							}

							const { errors } = await deleteFeatureFlag({
								variables: { featureFlagId: featureId || "" },
							});

							if (errors) {
								return console.error(errors);
							}

							navigate("/features");
						}}
					>
						Slett feature
					</DangerButton>
				</Flex>
			</Form>
		</Flex>
	);
}
