import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { z } from "zod";

import { Direction, Flex } from "components/atoms/Flex";
import ServiceMessageHeader from "components/atoms/ServiceMessageHeader";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import MultiSelectField from "components/organisms/form/MultiSelectField";
import TextField from "components/organisms/form/TextField";
import TextareaField from "components/organisms/form/TextareaField";
import ToggleField from "components/organisms/form/ToggleField";
import { ContentSegmentsEditor } from "pages/Content/CreateContentPage";

import { ServiceMessageLocation } from "generated/graphql-types";

import { useUpsertServiceMessageMutation } from "./CreateServiceMessagePage.graphql-gen";
import { SingleServiceMessageQuery } from "./EditServiceMessagePage.graphql-gen";

export const ServiceMessageSchema = z.object({
	title: z.string().min(1),
	content: z.string().min(1),
	published: z.boolean(),
	segmentIn: z.array(z.string()),
	location: z.array(z.nativeEnum(ServiceMessageLocation)),
	segmentNotIn: z.array(z.string()),
	publishDate: z.string().min(1),
});

export default function CreateServiceMessagePage() {
	const navigate = useNavigate();
	const [upsertServiceMessage, { loading, error }] = useUpsertServiceMessageMutation();
	const [previewState, setPreviewState] = useState<Partial<SingleServiceMessageQuery["singleServiceMessage"]>>({});

	return (
		<div>
			<MainLayoutPageHeader title="Ny driftsmelding" />
			<Flex gap="16px">
				<Form
					loading={loading}
					value={{}}
					onSubmit={async (data) => {
						const id = uuid();

						const { errors } = await upsertServiceMessage({
							variables: {
								serviceMessage: {
									id,
									...data,
								},
							},
						});

						!errors && navigate(`/service-messages/${id}`);
					}}
					schema={ServiceMessageSchema}
				>
					<Flex direction={Direction.column} gap="16px">
						<TextField
							name="title"
							label="Tittel"
							onChange={(e) => {
								const target = e.target as any;
								setPreviewState((oldState) => {
									return {
										...oldState,
										title: target.value || "",
									};
								});
							}}
						/>
						<TextareaField
							name="content"
							label="Innhold"
							onChange={(e) => {
								const target = e.target as any;
								setPreviewState((oldState) => {
									return {
										...oldState,
										content: target.value || "",
									};
								});
							}}
						/>
						<ToggleField name="published" label="Publisert?" />
						<TextField type="datetime-local" name="publishDate" label="Publiseringsdato" />

						<MultiSelectField
							style={{ height: "150px" }}
							name="location"
							label="Plassering i appen"
							options={Object.keys(ServiceMessageLocation).map((l) => {
								return { label: l, value: l };
							})}
						/>

						<ContentSegmentsEditor />

						{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
						<Button>Lag ny driftsmelding</Button>
					</Flex>
				</Form>
				<Flex direction={Direction.column} gap="16px" style={{ width: "390px" }}>
					<ServiceMessageHeader serviceMessage={previewState} />
					<div
						style={{
							display: "inline-block",
							width: "390px",
						}}
					/>
				</Flex>
			</Flex>
		</div>
	);
}
