import styled from "styled-components";

export const DangerButton = styled.button`
	background: var(--varsel-roed);
	border: 1px solid var(--nte-korall-moerkes);
`;

export const ClearButton = styled.button`
	background: none;
	border: none;
	color: var(--nte-blaa-uu);
	padding: 12px;
	height: auto;
	width: auto;
	line-height: 1;
`;

export const AddNewButton = styled.button`
	width: max-content;
	color: var(--nte-blaa-uu);
	background: none;
	padding: 0.2rem 1rem;
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	border: none;
	align-items: center;
	svg {
		font-size: 1.3rem;
	}
	:hover {
		color: var(--nte-blaa-uu);
		background: none;
		opacity: 0.9;
	}
`;
