import React, {useState} from "react"
import { z } from "zod";
import {MainLayoutPageHeader} from "../../components/organisms/MainLayout";
import {Flex} from "../../components/atoms/Flex";
import Form from "../../components/organisms/form/Form";
import {useCreateContentTextMutation} from "./CreateContentTextPage.graphql-gen";
import Button from "components/organisms/form/Button";
import TextField from "components/organisms/form/TextField";
import TextareaField from "components/organisms/form/TextareaField";
import {ContentTextInput} from "../../generated/graphql-types";
import {useNavigate} from "react-router-dom";
import {ErrorWrapper} from "../../components/organisms/form/BaseField";
import ToggleField from "../../components/organisms/form/ToggleField";

export const ContentTextSchema = z.object({
    handle: z.string().min(1),
    title: z.string().min(1),
    text: z.string().min(1),
    published: z.boolean()
});

export function CreateContentTextPage(){

    const [upsertContent, {loading, error}] = useCreateContentTextMutation();
    const [stateError, setError] = useState<any>();
    const navigate = useNavigate();

    return(
        <div>
            <MainLayoutPageHeader title="Ny tekst" />
            <Flex gap="16px" style={{marginTop: "2rem"}}>
                <Form
                    loading={loading}
                    schema={ContentTextSchema}
                    onSubmit={async (data: ContentTextInput) =>{
                        const {errors} = await upsertContent({
                            variables:{
                                contentText:{
                                    ...data,
                                    published: !!data.published ? data.published : false
                                }
                            }
                        })

                        setError(errors)
                        !errors && navigate("/content")
                    }}
                >

                    <ToggleField name="published" label="Publisert?" />
                    <TextField name="handle" label="Handle"></TextField>
                    <TextField name="title" label="Tittel"></TextField>
                    <TextareaField name="text" label="Tekst"></TextareaField>


                    {error && <ErrorWrapper>{error?.graphQLErrors[0].extensions.message as string}</ErrorWrapper>}
                    <Button>Opprett nytt innhold</Button>
                </Form>
            </Flex>
        </div>
    )
}