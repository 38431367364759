import styled from "styled-components";

export const Badge = styled.span`
	background: var(--nte-graa-lys);
	border-radius: var(--border-radius);
	padding: 0.1em 0.5em;
`;

export const SuccessBadge = styled(Badge)`
	background: var(--nte-groenn);
	color: var(--white);
`;

export const WarningBadge = styled(Badge)`
	background: #ffcc00;
`;

export const DangerBadge = styled(Badge)`
	background: var(--nte-korall-morkest);
	color: var(--white);
`;
