import { HTMLProps, useEffect, useState } from "react";
import styled from "styled-components";

import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RequiredAsterisk } from "components/atoms/Required";
import { SelectOption } from "components/organisms/SelectListWithModalSearch";
import { BaseField } from "components/organisms/form/BaseField";
import { FormElementError } from "components/organisms/form/Form";
import { TextInputContentWrapper } from "components/organisms/form/TextField";
import { getErrorForField } from "utils/FormValidator";

interface SearchSelectFieldProps extends HTMLProps<HTMLInputElement> {
	name: string;
	inputRef?: React.MutableRefObject<any>;
	selectedValue?: string;
	setSelectedValue?: (searchedValue: string | null) => void;
	options?: SelectOption[];
	formErrors?: FormElementError<unknown>;
}
export default function SearchSelectField({
	name,
	inputRef,
	selectedValue,
	setSelectedValue,
	options,
	formErrors,
	disabled,
	readOnly,
	...props
}: SearchSelectFieldProps) {
	const [availableOptions, setAvailableOptions] = useState(options);
	const [isOpen, setIsOpen] = useState(false);
	const [a11yMessage, setA11yMessage] = useState("");

	const errorsForField = formErrors ? getErrorForField(formErrors, name) : null;

	useEffect(() => {
		function handleKeyPress(e: KeyboardEvent) {
			e = e || window.Event;
			let selectedIndex = 0;
			const resultsElement = document.getElementById("alternativer");
			const inputElement = inputRef?.current;

			if (!resultsElement || resultsElement?.children?.length === 0 || availableOptions?.length === 0) {
				inputElement?.setAttribute("aria-activedescendant", "");
				return;
			}

			resultsElement?.childNodes.forEach((result, i) => {
				if (result instanceof HTMLElement && (result.ariaSelected === "true" || result.className.indexOf("selected") !== -1)) {
					selectedIndex = i;
				}
				return result;
			});

			if (e.key === "ArrowUp") {
				e.preventDefault();
				let newSelectedIndex = selectedIndex === 0 ? resultsElement?.children?.length - 1 : selectedIndex - 1;
				changeSelectedOption(inputElement, resultsElement, newSelectedIndex, selectedIndex);
			} else if (e.key === "ArrowDown") {
				e.preventDefault();
				const newSelectedIndex = selectedIndex === resultsElement?.children?.length - 1 ? 0 : selectedIndex + 1;
				changeSelectedOption(inputElement, resultsElement, newSelectedIndex, selectedIndex);
			} else if (e.key === "Enter") {
				const element = resultsElement?.children[selectedIndex] as HTMLElement;
				element?.click();
				inputRef?.current.blur();
			} else if (e.key === "Escape") {
				inputRef?.current.blur();
			}
			if (resultsElement?.children?.length === 1) {
				resultsElement?.children[0]?.setAttribute("aria-selected", "true");
				resultsElement.children[0].className += " selected";
				inputElement?.setAttribute("aria-activedescendant", "alternativ-0");
			}
		}
		function changeSelectedOption(inputElement: HTMLElement, resultsElement: HTMLElement, newSelectedIndex: number, selectedIndex: number) {
			inputElement.setAttribute("aria-activedescendant", `alternativ-${newSelectedIndex || 0}`);
			resultsElement?.children[newSelectedIndex || 0]?.setAttribute("aria-selected", "true");
			resultsElement.children[newSelectedIndex || 0].className += " selected";
			resultsElement?.children[selectedIndex] instanceof HTMLElement && resultsElement?.children[selectedIndex]?.setAttribute("aria-selected", "false");
			if (!isNaN(selectedIndex)) {
				resultsElement.children[selectedIndex].className = resultsElement?.children[selectedIndex].className.split(" selected")[0];
			}
		}

		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (inputRef && selectedValue) {
			inputRef.current.value = selectedValue;
		}
	}, [selectedValue, inputRef]);

	useEffect(() => {
		if (!!availableOptions?.length) {
			setA11yMessage(
				`Listen viser ${availableOptions?.length} treff på søket, bruk opp eller ned pil for navigasjon. Trykk eller bruk Enter tasten for å velge.`
			);
		} else if (inputRef?.current?.value !== "" && !selectedValue) {
			setA11yMessage(`Fant ingen alternativer som tilsvarer søket ditt, ${inputRef?.current.value}. Skriv adressen på nytt eller gå videre.`);
		}
	}, [availableOptions, selectedValue, setA11yMessage, inputRef]);

	return (
		<BaseField errors={errorsForField as FormElementError<unknown>}>
			<Label>
				{props.label}
				{props.required && <RequiredAsterisk />}
				<InputAndButtonWrapper hasButton={true}>
					<input
						id={"text-field-input"}
						{...props}
						name={name}
						ref={inputRef}
						disabled={disabled}
						readOnly={readOnly}
						type="search"
						autoComplete="off"
						role="combobox"
						aria-expanded={!!availableOptions?.length && isOpen}
						aria-controls="alternativer"
						aria-haspopup="true"
						aria-activedescendant=""
						onBlur={() => {
							setIsOpen(false);
						}}
						onFocus={() => {
							if (availableOptions?.length === 1) {
								inputRef?.current?.setAttribute("aria-activedescendant", "alternativ-0");
							}
							setIsOpen(true);
						}}
						onChange={(e) => {
							setIsOpen(true);
							const searchValue = e.target.value.trim();
							if (searchValue) {
								const newAvailableOptions = options?.filter((o) => o.label.includes(searchValue));
								setAvailableOptions(newAvailableOptions || []);
							}
						}}
					/>
					<button
						title="Vis alternativer"
						className="noStyle"
						type="button"
						disabled={disabled || readOnly}
						onClick={() => {
							setAvailableOptions(options);
							setIsOpen(!isOpen);
						}}
					>
						{isOpen ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
					</button>
				</InputAndButtonWrapper>
				{isOpen && !!availableOptions?.length && !disabled && !readOnly && (
					<OptionsList role="listbox" id="alternativer" aria-label="alternativer">
						{availableOptions.map((option, index) => (
							<Option
								role="option"
								key={index}
								aria-selected={availableOptions?.length === 1}
								className={availableOptions?.length === 1 ? " selected" : ""}
								id={`alternativ-${index}`}
								onClick={() => {
									setSelectedValue?.(option?.value);
									setAvailableOptions([]);
									setA11yMessage(`Valgte ${option.label}.`);
									setIsOpen(false);
								}}
								onMouseDown={() => {
									setSelectedValue?.(option?.value);
									setAvailableOptions([]);
									setA11yMessage(`Valgte ${option.label}.`);
									setIsOpen(false);
								}}
								onTouchStart={() => {
									setSelectedValue?.(option?.value);
									setAvailableOptions([]);
									setA11yMessage(`Valgte ${option.label}.`);
									setIsOpen(false);
								}}
							>
								{option.label}
							</Option>
						))}
					</OptionsList>
				)}
				{a11yMessage?.length > 0 && (
					<div aria-hidden={!(a11yMessage?.length > 0)} aria-live="assertive" className="sr-only">
						{a11yMessage}
					</div>
				)}
			</Label>
		</BaseField>
	);
}

const Label = styled.label`
	max-width: 100%;
	position: relative;
`;
const InputAndButtonWrapper = styled(TextInputContentWrapper)`
	button {
		color: ${(props) => props.theme.colors.text};
	}
`;
const OptionsList = styled.ul`
	position: absolute;
	z-index: 1;
	width: 100%;
	margin-top: -2px;
	background-color: white;
	padding: 0;
	box-sizing: border-box;
	overflow: auto;
	border-radius: 0.5rem;
	border: 1px solid grey;
	list-style-type: none;
	max-height: 600px;
`;
const Option = styled.li`
	cursor: pointer;
	transition: all 0.2s;
	padding: 8px 16px;
	border: solid 2px transparent;
	font-size: 0.875rem;
	transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 10ms;
	font-weight: 400;

	&[aria-selected="true"],
	&:hover {
		color: white;
		background-color: var(--nte-blaa-mork);
	}
	& :active {
		color: white;
		background-color: var(--nte-blaa-mork);
	}
`;
