import { format } from "date-fns";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { faChevronDown, faChevronUp, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { OrderConfirmation, Product, noneOrderConfirmation } from "hooks/useStromApi";

enum sortingTypes {
	"ordrebekreftelse" = "ordrebekreftelse",
	"tariffId" = "tariffId",
	"navn" = "navn",
	"sistOppdatert" = "sistOppdatert",
}
export const Table = styled.table`
	border-collapse: collapse;
	tr {
		border: 1px solid #707c9740;
		border-width: 1px 0;
	}
	tr:last-child {
		border-top: none;
	}
	th {
		text-align: left;
		padding: 0.5rem 1rem;
		font-weight: bold;
	}
	td {
		padding: 0.5rem 1rem;
	}
`;

const HeadButton = styled.button`
	font-size: 0.875rem;
	font-weight: 700;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	border: none;
	background: none;
	color: inherit;
	padding: 0 0.3rem;

	&:hover {
		background: transparent;
		color: ${(props) => props.theme.colors.text};
		border: 0;
	}
`;

type WithActive = {
	active?: boolean;
};
const StyledIcon = styled(FontAwesomeIcon)<WithActive>`
	font-size: 0.8rem;
	color: ${(props) => (!props.active ? "var(--nte-graa-mork)" : "var(--nte-blaa-logo)")};
`;
interface ProductsPageProps {
	products: Product[];
	orderConfirmations: OrderConfirmation[];
}
export default function ProductsPage({ products, orderConfirmations }: ProductsPageProps) {
	const [sortedProducts, setSortedProducts] = useState(products);
	const [sortingDirections, setSortingDirections] = useState({ navn: 0, ordrebekreftelse: 0, tariffId: 0, sistOppdatert: 0 });

	function sortProductsBy(type: sortingTypes): void {
		const newOrder = sortingDirections[type] + 1 > 1 ? -1 : sortingDirections[type] + 1;
		setSortingDirections({ ...sortingDirections, [type]: newOrder });
		switch (type) {
			case sortingTypes.navn:
				setSortedProducts(
					[...products].sort((a, b) => {
						if (!a?.navn) {
							return newOrder;
						}
						if (!b?.navn) {
							return -newOrder;
						}
						if (a?.navn && b?.navn) {
							return a.navn.toLowerCase() < b.navn.toLowerCase() ? -newOrder : newOrder;
						}
						return 0;
					})
				);
				break;
			case sortingTypes.tariffId:
				setSortedProducts(
					[...products].sort((a, b) => {
						if (!a?.tariffId) {
							return 1;
						}
						if (!b?.tariffId) {
							return -1;
						}
						if (a?.tariffId && b?.tariffId) {
							return a.tariffId.toLowerCase() < b.tariffId.toLowerCase() ? -newOrder : newOrder;
						}
						return 0;
					})
				);
				break;
			case sortingTypes.ordrebekreftelse:
				setSortedProducts(
					[...products].sort((a, b) => {
						if (!a?.ordrebekreftelse) {
							return 1;
						}
						if (!b?.ordrebekreftelse) {
							return -1;
						}
						const orderConfirmationNameA = orderConfirmations.find((c) => c.id === a.ordrebekreftelse)?.navn;
						const orderConfirmationNameB = orderConfirmations.find((c) => c.id === b.ordrebekreftelse)?.navn;
						if (!orderConfirmationNameA) {
							return 1;
						}
						if (!orderConfirmationNameB) {
							return -1;
						}
						return orderConfirmationNameA.toLowerCase() < orderConfirmationNameB.toLowerCase() ? -newOrder : newOrder;
					})
				);
				break;
			case sortingTypes.sistOppdatert:
				setSortedProducts(
					[...products].sort((a, b) => {
						if (!a?.oppdatert?.sistOppdatert) {
							return 1;
						}
						if (!b?.oppdatert?.sistOppdatert) {
							return -1;
						}
						if (a?.tariffId && b?.tariffId) {
							return a.oppdatert?.sistOppdatert < b.oppdatert?.sistOppdatert ? -newOrder : newOrder;
						}
						return 0;
					})
				);
				break;
			default:
				break;
		}
	}

	return (
		<Flex gap="2rem" direction={Direction.column}>
			<MainLayoutPageHeader title="Produktadmin" />
			<h2>Alle produktbeskrivelser for strømavtalene våre</h2>
			<Table width="100%">
				<thead>
					<tr>
						<th>
							<HeadButton onClick={() => sortProductsBy(sortingTypes.navn)}>
								{sortingDirections.navn >= 0 ? (
									<StyledIcon icon={faChevronDown} active={sortingDirections.navn !== 0 ? true : undefined} />
								) : (
									<StyledIcon icon={faChevronUp} active={true} />
								)}
								Produkt
							</HeadButton>
						</th>
						<th>
							<HeadButton onClick={() => sortProductsBy(sortingTypes.tariffId)}>
								{sortingDirections.tariffId >= 0 ? (
									<StyledIcon icon={faChevronDown} active={sortingDirections.tariffId !== 0 ? true : undefined} />
								) : (
									<StyledIcon icon={faChevronUp} active={true} />
								)}
								Tariff-ID
							</HeadButton>
						</th>
						<th>
							<HeadButton onClick={() => sortProductsBy(sortingTypes.ordrebekreftelse)}>
								{sortingDirections.ordrebekreftelse >= 0 ? (
									<StyledIcon icon={faChevronDown} active={sortingDirections.ordrebekreftelse !== 0 ? true : undefined} />
								) : (
									<StyledIcon icon={faChevronUp} active={true} />
								)}
								Ordrebekreftelse
							</HeadButton>
						</th>
						<th>
							<HeadButton onClick={() => sortProductsBy(sortingTypes.sistOppdatert)}>
								{sortingDirections.sistOppdatert >= 0 ? (
									<StyledIcon icon={faChevronDown} active={sortingDirections.sistOppdatert !== 0 ? true : undefined} />
								) : (
									<StyledIcon icon={faChevronUp} active={true} />
								)}
								Sist oppdatert
							</HeadButton>
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{sortedProducts.map((product, i) => {
						return (
							<tr key={i}>
								<td>
									<Link to={`/products/${product.id}`} title="Se eller endre produkt">
										{product.navn}
									</Link>
								</td>
								<td>{product.tariffId}</td>
								<td>
									{(product.ordrebekreftelse === noneOrderConfirmation.id && <>{noneOrderConfirmation.navn}</>) || (
										<Link
											to={`/products/order-confirmations/${product.ordrebekreftelse}?tariffId=${product.tariffId}`}
											title="Se ordrebekreftelse"
										>
											{orderConfirmations.find((c) => c.id === product.ordrebekreftelse)?.navn}
										</Link>
									)}
								</td>
								<td>
									{product.oppdatert?.oppdatertAv &&
										product.oppdatert?.sistOppdatert &&
										`${format(new Date(product.oppdatert?.sistOppdatert), "dd.MM.yyyy")} - ${product.oppdatert?.oppdatertAv}`}
								</td>
								<td>
									<Link to={`/products/${product.id}`} title="Se eller endre produkt">
										<FontAwesomeIcon icon={faPencil} />
									</Link>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</Flex>
	);
}
