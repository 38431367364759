// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { GridOwnerMappingDetailsFragmentDoc } from '../SingleGridOwnerMappingPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGridOwnerMappingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGridOwnerMappingsQuery = { gridOwnerMappings: Array<{ id: string, name: string, ecomonId: string, websiteURL: string, mergedCompanies?: Array<string>, numberOfUsers?: any }> };


export const GetGridOwnerMappingsDocument = gql`
    query GetGridOwnerMappings {
  gridOwnerMappings {
    ...GridOwnerMappingDetails
  }
}
    ${GridOwnerMappingDetailsFragmentDoc}`;

/**
 * __useGetGridOwnerMappingsQuery__
 *
 * To run a query within a React component, call `useGetGridOwnerMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGridOwnerMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGridOwnerMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGridOwnerMappingsQuery(baseOptions?: Apollo.QueryHookOptions<GetGridOwnerMappingsQuery, GetGridOwnerMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGridOwnerMappingsQuery, GetGridOwnerMappingsQueryVariables>(GetGridOwnerMappingsDocument, options);
      }
export function useGetGridOwnerMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGridOwnerMappingsQuery, GetGridOwnerMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGridOwnerMappingsQuery, GetGridOwnerMappingsQueryVariables>(GetGridOwnerMappingsDocument, options);
        }
export type GetGridOwnerMappingsQueryHookResult = ReturnType<typeof useGetGridOwnerMappingsQuery>;
export type GetGridOwnerMappingsLazyQueryHookResult = ReturnType<typeof useGetGridOwnerMappingsLazyQuery>;
export type GetGridOwnerMappingsQueryResult = Apollo.QueryResult<GetGridOwnerMappingsQuery, GetGridOwnerMappingsQueryVariables>;