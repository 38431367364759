// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import { UserListFragmentDoc } from '../Users/UsersPage.graphql-gen';
import { FeatureFlagListFragmentDoc } from '../Features/FeatureFlagsPage.graphql-gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGroupQueryVariables = Types.Exact<{
  groupId: Types.Scalars['String'];
}>;


export type GetGroupQuery = { group?: { id?: string, key: string, displayName: string, usersCount?: any, usersCanOptIn?: boolean, featureFlagKeys?: Array<string>, featureFlags?: Array<{ id?: string, key: string, displayName: string, global?: boolean, usersCount?: any }> } };

export type GetUsersOnGroupQueryVariables = Types.Exact<{
  groupId: Types.Scalars['String'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  startIndex?: Types.InputMaybe<Types.Scalars['Int']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetUsersOnGroupQuery = { group?: { id?: string, users?: { total?: any, items?: Array<{ userId?: string, name?: string, mobileNumber?: string, emailAddress?: string, customerIds?: Array<number>, birthDate?: string, groupIds?: Array<string>, disableSync: boolean }> } } };

export type GroupDetailsFragment = { id?: string, key: string, displayName: string, usersCount?: any, usersCanOptIn?: boolean, featureFlagKeys?: Array<string>, featureFlags?: Array<{ id?: string, key: string, displayName: string, global?: boolean, usersCount?: any }> };

export const GroupDetailsFragmentDoc = gql`
    fragment GroupDetails on Group {
  id
  key
  displayName
  usersCount
  usersCanOptIn
  featureFlagKeys
  featureFlags {
    ...FeatureFlagList
  }
}
    ${FeatureFlagListFragmentDoc}`;
export const GetGroupDocument = gql`
    query GetGroup($groupId: String!) {
  group(groupId: $groupId) {
    ...GroupDetails
  }
}
    ${GroupDetailsFragmentDoc}`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupQuery(baseOptions: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
      }
export function useGetGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupQuery, GetGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
        }
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<typeof useGetGroupLazyQuery>;
export type GetGroupQueryResult = Apollo.QueryResult<GetGroupQuery, GetGroupQueryVariables>;
export const GetUsersOnGroupDocument = gql`
    query GetUsersOnGroup($groupId: String!, $search: String, $startIndex: Int, $count: Int) {
  group(groupId: $groupId) {
    id
    users(search: $search, startIndex: $startIndex, count: $count) {
      items {
        ...UserList
      }
      total
    }
  }
}
    ${UserListFragmentDoc}`;

/**
 * __useGetUsersOnGroupQuery__
 *
 * To run a query within a React component, call `useGetUsersOnGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersOnGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersOnGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      search: // value for 'search'
 *      startIndex: // value for 'startIndex'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetUsersOnGroupQuery(baseOptions: Apollo.QueryHookOptions<GetUsersOnGroupQuery, GetUsersOnGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersOnGroupQuery, GetUsersOnGroupQueryVariables>(GetUsersOnGroupDocument, options);
      }
export function useGetUsersOnGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersOnGroupQuery, GetUsersOnGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersOnGroupQuery, GetUsersOnGroupQueryVariables>(GetUsersOnGroupDocument, options);
        }
export type GetUsersOnGroupQueryHookResult = ReturnType<typeof useGetUsersOnGroupQuery>;
export type GetUsersOnGroupLazyQueryHookResult = ReturnType<typeof useGetUsersOnGroupLazyQuery>;
export type GetUsersOnGroupQueryResult = Apollo.QueryResult<GetUsersOnGroupQuery, GetUsersOnGroupQueryVariables>;