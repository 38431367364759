import { convertToRaw, ContentState, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useRefState } from "hooks/useRefState";
import { debounce } from "lodash";
import React, { HTMLProps, useCallback } from "react";

import { BaseField } from "components/organisms/form/BaseField";
import { FormElementError, useForm } from "components/organisms/form/Form";

import { ContentEditorData } from "../../../generated/graphql-types";
import { ContentEditor } from "../../molecules/ContentEditor";

type ContentEditorValue = {
	json?: string;
	html?: string;
};
type TextFieldProps = { onChange?: (value: ContentEditorValue) => void } & Omit<HTMLProps<HTMLInputElement>, "onChange">;

export default function ContentEditorField({ name, value, defaultValue, onChange, disabled, ...props }: TextFieldProps) {
	const {
		errors,
		disabled: disabledState,
		defaultValue: formDefaultValue,
	} = useForm(name, {
		getValue: () => value || valueRef.current,
	});
	const [valueRef, setValue] = useRefState<ContentEditorData>((defaultValue as ContentEditorValue) || formDefaultValue || "");

	const debouncedOnChange = useCallback(
		debounce((contentState: ContentState) => {
			const raw = convertToRaw(contentState);
			const value = {
				json: JSON.stringify(raw),
				html: draftToHtml(raw),
			};
			setValue(value);
			onChange?.(value);
		}, 300),
		[]
	);

	const json = (defaultValue || formDefaultValue)?.json || undefined;

	return (
		<BaseField errors={errors as FormElementError<unknown>}>
			<label>
				{props.label}
				<ContentEditor defaultValue={json && convertFromRaw(JSON.parse(json))} onChange={debouncedOnChange} />
			</label>
		</BaseField>
	);
}
