import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AddNewButton } from "components/atoms/Button";
import { Direction, Flex, Justify } from "components/atoms/Flex";
import { TitleH3 } from "components/atoms/Typography";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import Form from "components/organisms/form/Form";
import TextField from "components/organisms/form/TextField";
import ToggleField from "components/organisms/form/ToggleField";
import useAccess from "hooks/useAccess";

import { StyledInfo, StyledInfoColumn, StyledInfoValue } from "./CreateGridOwnerMappingPage";
import { useGetDistributionModelSupportQuery } from "./CreateGridOwnerMappingPage.graphql-gen";
import { useGetGridOwnerMappingQuery, useUpdateGridOwnerMappingMutation } from "./SingleGridOwnerMappingPage.graphql-gen";

export default function SingleGridOwnerMappingPage() {
	const navigate = useNavigate();
	const access = useAccess();
	const { templateId } = useParams<{ templateId: string }>();
	const { newGridName } = Object.fromEntries(new URLSearchParams(window?.location?.search));

	const { data, loading, error: getError } = useGetGridOwnerMappingQuery({ variables: { id: templateId || "" } });
	const [upsertGridOwnerMapping, { loading: upsertLoading, error: upsertError }] = useUpdateGridOwnerMappingMutation();
	const { data: distributionModelSupportData } = useGetDistributionModelSupportQuery({
		skip: !data?.gridOwnerMapping?.ecomonId,
		variables: {
			ecomonId: data?.gridOwnerMapping?.ecomonId || "",
		},
	});

	const error = getError || upsertError;
	const ecomonItem = distributionModelSupportData?.distributionModelSupport;

	const [mergedCompanies, setMergedCompanies] = useState<string[]>([]);
	useEffect(() => {
		const newMergedCompanies = data?.gridOwnerMapping?.mergedCompanies || [];
		setMergedCompanies(newGridName ? [...newMergedCompanies, newGridName] : newMergedCompanies);
	}, [loading, newGridName]);

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<MainLayoutPageHeader
				loading={loading}
				title={
					<Flex gap="var(--grid-2)">
						{data?.gridOwnerMapping?.name} ({data?.gridOwnerMapping?.numberOfUsers} brukere)
					</Flex>
				}
			/>
			{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
			<Form
				loading={loading || upsertLoading}
				key={data?.gridOwnerMapping?.id}
				value={data?.gridOwnerMapping}
				disabled={!access.includes("Admin")}
				schema={z.object({
					name: z.string(),
					ecomonId: z.string(),
					websiteURL: z.string(),
					syncronizeNow: z.boolean(),
				})}
				onSubmit={async ({ syncronizeNow, ...data }) => {
					const mapping = {
						id: templateId || "",
						mergedCompanies: mergedCompanies,
						...data,
					};

					const response = await upsertGridOwnerMapping({
						variables: {
							mapping,
							syncronizeNow,
						},
					});

					if (response.data?.updateGridOwnerMapping) {
						navigate("/grid-templates/");
					}
				}}
			>
				<TextField name="ecomonId" disabled label="Ecomon ID" />
				<StyledInfo gap="12px" alignItems={Justify.center}>
					<StyledInfoColumn>
						gln <br />
						<StyledInfoValue>{ecomonItem?.gln || "..."}</StyledInfoValue>
					</StyledInfoColumn>
					<StyledInfoColumn>
						Kapasitetsmodell <br />
						<StyledInfoValue>{ecomonItem?.capacityModelType || "..."}</StyledInfoValue>
					</StyledInfoColumn>
					<StyledInfoColumn>
						Energimodell <br />
						<StyledInfoValue>{ecomonItem?.energyModelType || "..."}</StyledInfoValue>
					</StyledInfoColumn>
				</StyledInfo>

				<TextField name="name" label="Navn" />

				<TextField name="websiteURL" label="Hjemmeside" />
				<ToggleField name="syncronizeNow" label="Synkroniser mot ecomon?" />

				<div style={{ marginTop: "1em", marginBottom: "2em" }}>
					<TitleH3>Sammenslått med:</TitleH3>
					{mergedCompanies.map((company, i) => (
						<TextField
							required
							name={`mergedCompany-${i}`}
							value={company}
							onChange={(e) => {
								let m = mergedCompanies.slice();
								m[i] = e.currentTarget.value;
								setMergedCompanies(m);
							}}
							onDelete={() => {
								let m = mergedCompanies.slice();
								m.splice(i, 1);
								setMergedCompanies(m);
							}}
						/>
					))}
					<AddNewButton
						type="button"
						title="Legg til"
						onClick={() => {
							let m = mergedCompanies.slice();
							m.push("");
							setMergedCompanies(m);
						}}
					>
						<FontAwesomeIcon icon={faPlusCircle} />
						Legg til nytt alias
					</AddNewButton>
				</div>

				{access.includes("Admin") && <Button>Lagre</Button>}
			</Form>
		</Flex>
	);
}
