import React, { createContext, HTMLProps, ReactNode, useContext, useState } from "react";

import Modal from "../molecules/Modal";
import Portal from "./Portal";

export const ModalButtonContext = createContext({
	close: () => {},
});

export const useModalButtonContext = () => {
	return useContext(ModalButtonContext);
};

type ModalButtonProps = Omit<HTMLProps<HTMLButtonElement>, "type" | "onClick" | "label"> & {
	children: ReactNode;
	label: ReactNode;
};
export default function ModalButton(props: ModalButtonProps) {
	const [isOpen, setIsOpen] = useState(false);
	const { children, label, ...rest } = props;

	return (
		<>
			<button onClick={() => setIsOpen(!isOpen)} {...rest}>
				{label}
			</button>
			<Portal>
				{isOpen && (
					<ModalButtonContext.Provider
						value={{
							close: () => {
								setIsOpen(false);
							},
						}}
					>
						<Modal onClose={() => setIsOpen(false)}>{children}</Modal>
					</ModalButtonContext.Provider>
				)}
			</Portal>
		</>
	);
}
