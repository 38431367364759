// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContentQuery = { paginateContent?: { total?: any, items?: Array<{ id?: string, title?: string, content?: string, published: boolean, publishDate?: string, existingUsersOnly: boolean, viewOnInfoScreen: boolean, segmentIn?: Array<string>, segmentNotIn?: Array<string>, uniqueImpressions: any, pageTitle?: string, image?: { url?: string, reference?: string, provider?: string }, link?: { url?: string, type?: Types.ContentLinkType, view?: string, viewType?: string }, pageImage?: { url?: string, reference?: string, provider?: string }, pageContent?: { json?: string, html?: string } }> } };

export type ContentDetailsFragment = { id?: string, title?: string, content?: string, published: boolean, publishDate?: string, existingUsersOnly: boolean, viewOnInfoScreen: boolean, segmentIn?: Array<string>, segmentNotIn?: Array<string>, uniqueImpressions: any, pageTitle?: string, image?: { url?: string, reference?: string, provider?: string }, link?: { url?: string, type?: Types.ContentLinkType, view?: string, viewType?: string }, pageImage?: { url?: string, reference?: string, provider?: string }, pageContent?: { json?: string, html?: string } };

export type ContentTextQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContentTextQuery = { allContentTexts?: Array<{ id?: string, handle?: string, published: boolean, lastUpdated?: string, title?: string, text?: string }> };

export const ContentDetailsFragmentDoc = gql`
    fragment ContentDetails on Content {
  id
  title
  content
  published
  publishDate
  existingUsersOnly
  viewOnInfoScreen
  segmentIn
  segmentNotIn
  uniqueImpressions
  image {
    url
    reference
    provider
  }
  link {
    url
    type
    view
    viewType
  }
  pageTitle
  pageImage {
    url
    reference
    provider
  }
  pageContent {
    json
    html
  }
}
    `;
export const ContentDocument = gql`
    query Content {
  paginateContent {
    total
    items {
      ...ContentDetails
    }
  }
}
    ${ContentDetailsFragmentDoc}`;

/**
 * __useContentQuery__
 *
 * To run a query within a React component, call `useContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useContentQuery(baseOptions?: Apollo.QueryHookOptions<ContentQuery, ContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentQuery, ContentQueryVariables>(ContentDocument, options);
      }
export function useContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentQuery, ContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentQuery, ContentQueryVariables>(ContentDocument, options);
        }
export type ContentQueryHookResult = ReturnType<typeof useContentQuery>;
export type ContentLazyQueryHookResult = ReturnType<typeof useContentLazyQuery>;
export type ContentQueryResult = Apollo.QueryResult<ContentQuery, ContentQueryVariables>;
export const ContentTextDocument = gql`
    query ContentText {
  allContentTexts {
    id
    handle
    published
    lastUpdated
    title
    text
  }
}
    `;

/**
 * __useContentTextQuery__
 *
 * To run a query within a React component, call `useContentTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useContentTextQuery(baseOptions?: Apollo.QueryHookOptions<ContentTextQuery, ContentTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentTextQuery, ContentTextQueryVariables>(ContentTextDocument, options);
      }
export function useContentTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentTextQuery, ContentTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentTextQuery, ContentTextQueryVariables>(ContentTextDocument, options);
        }
export type ContentTextQueryHookResult = ReturnType<typeof useContentTextQuery>;
export type ContentTextLazyQueryHookResult = ReturnType<typeof useContentTextLazyQuery>;
export type ContentTextQueryResult = Apollo.QueryResult<ContentTextQuery, ContentTextQueryVariables>;