import React from "react";

import { Direction, Flex, Justify } from "components/atoms/Flex";
import { InfoRow, InfoColumn } from "components/atoms/InfoRow";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import Button from "components/organisms/form/Button";
import { InfoColumnLabel, InfoColumnValue } from "pages/Users/EditUserPage";

import { NotificationStatus, User } from "generated/graphql-types";

import {
	useBulkFixPushNotificationTokensMutation,
	useFixPushNotificationTokenMutation,
	useGetPushNotificationErrorsQuery,
} from "./NotificationTemplatesPageErrorTab.graphql-gen";

export default function NotificationTemplatesPageErrorTab() {
	const { data, error, loading, refetch } = useGetPushNotificationErrorsQuery();
	const [fixPushNotificationToken, { loading: fixLoading }] = useFixPushNotificationTokenMutation();
	const [bulkFixPushNotificationTokens, { loading: bulkFixLoading }] = useBulkFixPushNotificationTokensMutation();

	function getUserDeviceFromToken(user: Omit<User, "hasActiveContract">, token: string) {
		return user.devices?.find((d) => d.pushNotificationToken === token);
	}

	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<h1>Feil ({data?.notifications?.total ?? 0})</h1>
			<div style={{ textAlign: "right" }}>
				<Button
					onClick={async () => {
						const tokens = new Set<string>();
						data?.notifications?.items?.forEach((notification) => {
							notification?.pushNotificationReferences
								?.filter((ref) => ref.status === NotificationStatus.SENDING_ERROR)
								?.forEach((ref) => {
									if (!ref.referende) return;
									tokens.add(ref.referende);
								});
						});

						if (tokens.size === 0) return;

						// eslint-disable-next-line no-restricted-globals
						if (!confirm(`Sikker på at du ønsker å fjerne ${tokens.size} devicer?`)) {
							return;
						}

						await bulkFixPushNotificationTokens({
							variables: {
								pushNotificationTokens: Array.from(tokens),
							},
						});

						await refetch();
					}}
				>
					Fix the first ({data?.notifications?.items?.length ?? 0})
				</Button>
			</div>
			{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
			{data?.notifications?.items?.map((notification) => (
				<InfoRow key={notification.notificationId}>
					<InfoColumn width="25%">
						<InfoColumnLabel>Tittel</InfoColumnLabel>
						<InfoColumnValue>{notification?.title}</InfoColumnValue>
					</InfoColumn>
					<InfoColumn width="75%">
						<InfoColumnLabel>Sendt</InfoColumnLabel>
						<InfoColumnValue>{notification?.sentAt}</InfoColumnValue>
					</InfoColumn>
					<InfoColumn width="100%">
						<InfoColumnLabel>Tokens med feil</InfoColumnLabel>
						<InfoColumnValue>
							<ul>
								{notification.pushNotificationReferences
									?.filter((ref) => ref.status === NotificationStatus.SENDING_ERROR)
									.map((ref) => {
										const device = notification.user && getUserDeviceFromToken(notification.user, ref.referende ?? "");
										return (
											<li>
												<input disabled value={ref.referende} />
												<ul>
													<li style={{ marginBottom: "16px" }}>
														<Flex alignItems={Justify.center}>
															<div
																style={{
																	flexGrow: "1",
																}}
															>
																Device: {device?.model || "Ukjent"} - {device?.deviceId}
															</div>
															<Button
																loading={loading || fixLoading}
																loadingElement={<span>...</span>}
																onClick={async () => {
																	if (loading || fixLoading) return;
																	if (!ref?.referende) return;

																	if (
																		!window.confirm(
																			"Sikker på at du ønsker å slette denne devicen og oppdatere status på notifikasjonene?"
																		)
																	)
																		return;

																	try {
																		await fixPushNotificationToken({
																			variables: {
																				pushNotificationToken: ref?.referende?.replace("failed:", ""),
																			},
																		});
																		await refetch();
																	} catch (e) {
																		console.error(e);
																		alert("En feil oppstod");
																	}
																}}
															>
																Fiks
															</Button>
														</Flex>
													</li>
												</ul>
											</li>
										);
									})}
							</ul>
						</InfoColumnValue>
					</InfoColumn>
				</InfoRow>
			))}
		</Flex>
	);
}
