import {faCopy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function TextCopy({text}: { text: string }) {
    return (
        <span
            title="Kopier verdi"
            onClick={() => {
                navigator.clipboard.writeText(text);
            }}
            style={{cursor: "pointer"}}
        >
			<FontAwesomeIcon icon={faCopy}/>
		</span>
    );
}
