import { ElectricityPriceZone, NordpoolArea } from "generated/graphql-types";

/**
 * Returns the display name of a Nordpool area
 * @param a Nordpool Area
 * @returns Display name
 */
export function spotpriceAreaDisplayName(a: NordpoolArea | ElectricityPriceZone | undefined) {
	switch (a) {
		case "NO1": {
			return "Sørøst-Norge";
		}
		case "NO2": {
			return "Sørvest-Norge";
		}
		case "NO3": {
			return "Midt-Norge";
		}
		case "NO4": {
			return "Nord-Norge";
		}
		case "NO5": {
			return "Vest-Norge";
		}
		default: {
			return "ukjent";
		}
	}
}
