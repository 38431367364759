// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchContentTextQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SearchContentTextQuery = { searchContentText?: { id?: string, published: boolean, handle?: string, title?: string, text?: string } };

export type UpdateContentTextMutationVariables = Types.Exact<{
  contentText?: Types.InputMaybe<Types.ContentTextInput>;
}>;


export type UpdateContentTextMutation = { updateContentText?: { published: boolean, handle?: string, title?: string, text?: string } };


export const SearchContentTextDocument = gql`
    query SearchContentText($id: String) {
  searchContentText(id: $id) {
    id
    published
    handle
    title
    text
  }
}
    `;

/**
 * __useSearchContentTextQuery__
 *
 * To run a query within a React component, call `useSearchContentTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentTextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchContentTextQuery(baseOptions?: Apollo.QueryHookOptions<SearchContentTextQuery, SearchContentTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchContentTextQuery, SearchContentTextQueryVariables>(SearchContentTextDocument, options);
      }
export function useSearchContentTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContentTextQuery, SearchContentTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchContentTextQuery, SearchContentTextQueryVariables>(SearchContentTextDocument, options);
        }
export type SearchContentTextQueryHookResult = ReturnType<typeof useSearchContentTextQuery>;
export type SearchContentTextLazyQueryHookResult = ReturnType<typeof useSearchContentTextLazyQuery>;
export type SearchContentTextQueryResult = Apollo.QueryResult<SearchContentTextQuery, SearchContentTextQueryVariables>;
export const UpdateContentTextDocument = gql`
    mutation UpdateContentText($contentText: ContentTextInput) {
  updateContentText(contentText: $contentText) {
    published
    handle
    title
    text
  }
}
    `;
export type UpdateContentTextMutationFn = Apollo.MutationFunction<UpdateContentTextMutation, UpdateContentTextMutationVariables>;

/**
 * __useUpdateContentTextMutation__
 *
 * To run a mutation, you first call `useUpdateContentTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentTextMutation, { data, loading, error }] = useUpdateContentTextMutation({
 *   variables: {
 *      contentText: // value for 'contentText'
 *   },
 * });
 */
export function useUpdateContentTextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentTextMutation, UpdateContentTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentTextMutation, UpdateContentTextMutationVariables>(UpdateContentTextDocument, options);
      }
export type UpdateContentTextMutationHookResult = ReturnType<typeof useUpdateContentTextMutation>;
export type UpdateContentTextMutationResult = Apollo.MutationResult<UpdateContentTextMutation>;
export type UpdateContentTextMutationOptions = Apollo.BaseMutationOptions<UpdateContentTextMutation, UpdateContentTextMutationVariables>;